import React, { Component } from "react";
import {
  getLeaderboardFilter,
  getIndividualLeaderboard,
  getEventDetailsWithDistkey,
  fetchCohortLongPeriodData,
} from "../../../../../../apis/eventService";
import {
  updateUserSelectedProgram,
  updateUserSelectedEvent,
} from "../../../../../../redux/user";
import LinearProgressBar from "../../../../../common/LinearProgressBar";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import EmojiEventsTwoToneIcon from "@material-ui/icons/EmojiEventsTwoTone";
import MultiSelect from "../../../../../common/MultiSelect";
import Properties from "../../../../../../properties/Properties";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import { Paper, Grid, Typography, NativeSelect } from "@material-ui/core";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
import BoardList from "./BoardList";
import { connect } from "react-redux";
import TeamBoard from "./TeamBoard";
import NewTeamBoard from "./NewTeamBoard";
import { loading } from "../../../../../common/Spinner";
import Ticker from "react-ticker";

var moment = require("moment");
const styles = (theme) => ({
  individualBoard: {
    marginTop: "20px",
  },
  myDropdown_select: {
    fontSize: "20px !important",

    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    },
  },
  myDropdown_option: {
    fontSize: "16px", // Default option font size
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for small devices
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px !important", // Font size for extra small devices
    },
  },
  label_category: {
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  leaderboard_top: {
    width: "90%",
    margin: "auto",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  container_top: {
    /* width: 20%; */
    display: "flex",
    alignItems: "center",
    //  justifyContent:'space-around'
  },
  leaderboard_maleFemale: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "20px 10px",

    [theme.breakpoints.down("sm")]: {
      //  width: '100%',
      //     display: "flex",
      //     justifyContent: "center",
      //     alignItems:'center',
      //    flexWrap: "wrap",
      //    marginTop: "20px"
      //    ,margin:"0px",
      flexDirection: "column",
    },
  },
  container_title: {
    display: "flex",
    alignItems: "center",
    //  justifyContent: 'space-between',
    /* border: 3px solid green, */
    textAlign: "center",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  leaderboard_container: {
    flex: 0.48,
    //width: "45%",
    margin: "0 10px",
    // marginTop: "20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      width: "100%",
      margin: "0px",
    },
  },
  graphs: {
    width: "90%",
    display: "flex",
    marginLeft: "5%",

    [theme.breakpoints.down("sm")]: {
      margin: "0",
      padding: 0,
      width: "100%",
    },
  },
});

class NewLeaderboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tickerText: "",
      type: "",
      ageValue: "",
      ageOptions: [],
      runCategoryOptions: [],
      cycleCategoryOptions: [],
      stepsCategoryOptions: [],
      categoryOptions: [],
      customDatesOptions: [],
      selectedCustomDate: null,
      completedProgress: null,
      categoryValue: "",
      eventDates: "",
      dateRangeOptions: [],
      dateRange: "",
      activityOptions: [],
      data: null,
      leaderboardData: null,
      overallMale: null,
      femaleWinners: null,
      overallWinners: null,
      overallParticipants: null,
      maleWinners: null,
      overallFemale: null,
      challengeParameter: "",
      categoryLabel: "",
      completeEventCategories: null,
      programEventList: [],
      selectedProgramEvent: {},
      cohortData: [],

      //
      dropDownMenuCategoryList: [],
      selectedDropdownMenu: null,
      selectedDate: null,
    };
  }

  componentDidMount() {
    this.initialDataLoad();
    this.autoRefresh();
  }

  autoRefresh = () => {
    let timer = 0;
    this.boardInterval = setInterval(() => {
      this.autoRefreshData();
      timer = 1;
    }, 200000);
    if (timer === 1) {
      clearInterval(this.boardInterval);
      timer = 0;
    }
  };

  autoRefreshData = () => {
    let runnerEvent = ApplicationUtil.getRunnersEventsDetails();
    let supportEvent = ApplicationUtil.getSupportsEventsDetails();
    let organizerEventId = supportEvent?.id;
    let runnerEventId = runnerEvent?.id;

    let eventId = window.location.href.includes("/organizer")
      ? organizerEventId
      : runnerEventId;
    let options = [];
    var overall;
    getLeaderboardFilter(eventId).then((filterRes) => {
      if (filterRes.data) {
        filterRes.data.map((option) => {
          options.push({
            label: option.displayName,
            value: option.displayName,
            id: option.id,
            view: option.view,
            displayName: option.displayName,
            type: option.type,
          });
        });
        if (options.length > 0) {
          this.setState({ ageOptions: options }, () => {
            overall = options.filter(function (x) {
              return x.type === "OVERALL";
            });

            this.setState({ ageValue: overall[0] });
          });
        }

        if (this.state?.selectedDropdownMenu?.value === "Monthly Challenge") {
          this.getTableData(
            "STEPS",
            // this.state.date
            this.state.categoryValue?.id,
            //
            "PRIMARY",
            "Month"
          );
        } else {
          this.getTableData(
            this.state.type?.type,
            // this.state.date
            this.state.categoryValue?.id,
            //
            this.state.type?.activityPriority,
            overall[0]?.id
          );
        }
      } else {
        if (this.state?.selectedDropdownMenu?.value === "Monthly Challenge") {
          this.getTableData(
            "STEPS",
            // this.state.date
            this.state.categoryValue?.id,
            //
            "PRIMARY",
            "Month"
          );
        } else {
          this.getTableData(
            this.state.type?.type,
            this.state.categoryValue?.id,
            this.state.type?.activityPriority
          );
        }
      }
    });
  };

  dateCaluculation = (eventData, duration) => {
    let dropdownDates = [];

    let todays_date = {
      label: "Today's Leaderboard",
      value: `${moment().format("YYYY-MM-DD")} ${moment().format(
        "YYYY-MM-DD"
      )}`,
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    };

    // Use eventData dates for other durations
    let initialDate = eventData?.eventLocalStartDate
      ? moment(eventData?.eventLocalStartDate, "YYYY-MM-DD")
      : eventData?.localStartDate
      ? moment(eventData?.localStartDate, "YYYY-MM-DD")
      : moment(moment(eventData?.eventDate, "YYYY-MM-DD").format("YYYY-MM-DD"));

    const endDate = eventData?.eventLocalEndDate
      ? moment(eventData?.eventLocalEndDate, "YYYY-MM-DD")
      : eventData?.localEndDate
      ? moment(eventData?.localEndDate, "YYYY-MM-DD")
      : moment(
          moment(eventData?.eventEndDate, "DD-MM-YYYY hh:mm:ss").format(
            "YYYY-MM-DD"
          )
        );

    if (duration === "Month") {
      dropdownDates = [
        {
          label: "17th Oct-16th Nov",
          value: "2024-10-17 2024-11-16",
          fromDate: "2024-10-17",
          toDate: "2024-11-16",
        },
        {
          label: "17th Nov-16th Dec",
          value: "2024-11-17 2024-12-16",
          fromDate: "2024-11-17",
          toDate: "2024-12-16",
        },
        {
          label: "17th Dec-18th Jan",
          value: "2024-12-17 2025-01-18",
          fromDate: "2024-12-17",
          toDate: "2025-01-18",
        },
      ];
      this.setState({
        customDatesOptions: dropdownDates,
        selectedCustomDate: dropdownDates[0],
        selectedDropdownMenu: { label: "One Time", value: "One Time" },
      });
    } else {
      // Existing logic for calculating weeks (if duration is not "Month")
      let days = endDate.diff(initialDate, "days");
      let weeks = Math.ceil(days / 7);
      let weekInitialDate = initialDate.clone();
      let weekEndDate = endDate.clone();
      

      let isLiveEvent = moment().isBetween(
        weekInitialDate.format("YYYY-MM-DD"),
        weekEndDate.format("YYYY-MM-DD")
      );

      if (days > 10) {
        let formatedWeeks = [];
        for (let i = 1; i <= weeks; i++) {
          let weekStartDate = weekInitialDate.format("YYYY-MM-DD");
          let addDays = moment(weekStartDate, "YYYY-MM-DD")
            .add(6, "days")
            .format("YYYY-MM-DD");
          let weekLastDate = moment(addDays, "YYYY-MM-DD").isAfter(
            moment(weekEndDate.format("YYYY-MM-DD"), "YYYY-MM-DD")
          )
            ? weekEndDate.format("YYYY-MM-DD")
            : addDays;
          let liveWeek = moment().isBetween(weekStartDate, weekEndDate);
          let checkDays = moment(weekLastDate.split("-")).diff(
            moment(weekStartDate.split("-")),
            "days"
          );

          // if (checkDays <= 3) {
          //   formatedWeeks[formatedWeeks.length - 1] = {
          //     label: `${moment(
          //       formatedWeeks[formatedWeeks.length - 1].fromDate,
          //       "YYYY-MM-DD"
          //     ).format("Do MMM")}-${moment(weekLastDate, "YYYY-MM-DD").format(
          //       "Do MMM"
          //     )}`,
          //     value: `${
          //       formatedWeeks[formatedWeeks.length - 1].fromDate
          //     } ${weekLastDate}`,
          //     fromDate: formatedWeeks[formatedWeeks.length - 1].fromDate,
          //     toDate: weekLastDate,
          //   };
          // } else {
            let obj = {
              label: `${moment(weekStartDate, "YYYY-MM-DD").format(
                "Do MMM"
              )}-${moment(weekLastDate, "YYYY-MM-DD").format("Do MMM")}`,
              value: `${weekStartDate} ${weekLastDate}`,
              fromDate: `${weekStartDate}`,
              toDate: `${weekLastDate}`,
            };

            if (isLiveEvent) {
              if (!liveWeek) break;
              formatedWeeks.push(obj);
            } else {
              formatedWeeks.push(obj);
            }
            weekInitialDate = moment(weekLastDate, "YYYY-MM-DD").add(1, "days");
          }
        // }

        //removed current week because we have to show winner of completed week.


        let currentWeekIndex = formatedWeeks?.findIndex(week => {
          let weekStartDate = moment(week.fromDate, "YYYY-MM-DD");
          let weekEndDate = moment(week.toDate, "YYYY-MM-DD");
          return moment().isBetween(weekStartDate, weekEndDate, null, "[]");  // Include current date
        });

        
        if (currentWeekIndex !== -1) {
          formatedWeeks.reverse().shift();
        }else{
          formatedWeeks.reverse();
        }
        
        // formatedWeeks.reverse().shift();
        dropdownDates.push(...formatedWeeks);
        if (
          endDate.isSameOrAfter(moment().format("YYYY-MM-DD")) &&
          eventData?.activities &&
          eventData?.activities[0]?.challengeParams !== "STEPS"
        ) {
          dropdownDates.unshift(todays_date);
        }
      }

      this.setState({
        customDatesOptions: dropdownDates,
        selectedCustomDate: dropdownDates[0],
        selectedDropdownMenu: { label: "One Time", value: "One Time" },
      });
    }
  };

  initialDataLoad = async (data) => {
    const { userSelectedEvent } = this.props;
    this.setState({ selectedDropdownMenu: null });
    let options = [];
    let actOptions = [];
    let secOptions = [];
    var overall;
    let runnerEvent;
    let search = window.location.href;
    let distKey = search.split("distKey=")[1];

    if (this.props?.radioValue === "Cohort") {
      this.setState({
        dropDownMenuCategoryList: [
          { label: "One Time", value: "One Time" },
          { label: "Weekly Challenge", value: "Weekly Challenge" },
          { label: "Monthly Challenge", value: "Monthly Challenge" },
        ],
        selectedDropdownMenu: { label: "One Time", value: "One Time" },
      });
    } else if (this.props?.radioValue === "individual") {
      this.setState({
        dropDownMenuCategoryList: [
          { label: "One Time", value: "One Time" },
          { label: "Weekly Challenge", value: "Weekly Challenge" },
          { label: "Overall", value: "Overall" },
        ],
        selectedDropdownMenu: { label: "One Time", value: "One Time" },
      });
    }
    if (distKey) {
      try {
        let res = await getEventDetailsWithDistkey(distKey);
        if (res.status === 200) {
          document.title = res.data.name;
          let eventData = res.data;
          eventData.distKey = distKey;

          this.props.updateUserSelectedEvent(eventData);
          runnerEvent = eventData;
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      runnerEvent = ApplicationUtil?.getRunnersEventsDetails();
    }

    let runnerEventId = data?.value || runnerEvent?.id;

    let supportEvent = ApplicationUtil.getSupportsEventsDetails();
    let organizerEventId = supportEvent?.id;
    let eventId = window.location.href.includes("/organizer")
      ? organizerEventId
      : runnerEventId;
    let eventData = window.location.href.includes("/organizer")
      ? supportEvent
      : runnerEvent;

    if (this.props?.isProgram) {
      let programDetails = ApplicationUtil.getRunnersProgramDetails();

      if (programDetails?.id) {
        if (programDetails?.eventDTO) {
          let eventDTO = [...programDetails?.eventDTO];
          let summeryGeneratedEvents = eventDTO
            .map((x) => {
              if (x.summaryGenerated) {
                return x;
              }
            })
            .filter(function (element) {
              return element !== undefined;
            });

          // const defaultSelection = this.isEventOngoing(summeryGeneratedEvents);
          // summeryGeneratedEvents = summeryGeneratedEvents.filter(
          //   (event) => event.id !== defaultSelection.id
          // );
          // summeryGeneratedEvents = [defaultSelection].concat(
          //   summeryGeneratedEvents
          // );
          let list = summeryGeneratedEvents
            .map((x) => {
              return {
                label: x.name,
                value: x.id,
              };
            })
            .filter(function (element) {
              return element !== undefined;
            });

          let newData = programDetails?.eventDTO?.filter((item) => {
            return item.id === eventData?.id;
          });

          if (
            newData?.length > 0 &&
            !window.location.href.includes("/organizer")
          ) {
            eventData = newData[0];
            eventId = newData[0]?.id;
            runnerEvent = newData[0];
            runnerEventId = newData[0]?.id;
            this.props.updateUserSelectedEvent(newData[0]);
          } else if (!data) {
            eventData = summeryGeneratedEvents[0];
            eventId = summeryGeneratedEvents[0]?.id;
            runnerEvent = summeryGeneratedEvents[0];
            runnerEventId = summeryGeneratedEvents[0]?.id;
            this.props.updateUserSelectedEvent(summeryGeneratedEvents[0]);
          } else {
            eventData = summeryGeneratedEvents.find((x) => x.id === data.value);
            eventId = summeryGeneratedEvents.find(
              (x) => x.id === data.value
            )?.id;
            runnerEvent = summeryGeneratedEvents.find(
              (x) => x.id === data.value
            );
            runnerEventId = summeryGeneratedEvents.find(
              (x) => x.id === data.value
            )?.id;
            this.props.updateUserSelectedEvent(
              summeryGeneratedEvents.find((x) => x.id === data.value)
            );
          }

          this.setState({
            programEventDTO: summeryGeneratedEvents,
            programEventList: list,
            selectedProgramEvent:
              data || list.find((x) => x.value === eventId) || list[0],
          });
        }
      }
    }
    this.setState({
      eventData: eventData,
      skipTimeOnUploadActivity: eventData?.skipTimeOnUploadActivity,
    });

    this.dateCaluculation(eventData);

    if (eventData) {
      this.setState({
        data: eventData,
        completeEventCategories: eventData.eventRunCategories,
        eventLocalStartDate: eventData?.eventLocalStartDate,
        eventLocalEndDate: eventData?.eventLocalEndDate,
      });

      if (eventData?.activities) {
        eventData.activities.map((activity) => {
          if (activity?.type !== "DUATHLON" || activity?.id !== 3) {
            actOptions.push({
              label: activity?.displayName,
              value: activity?.displayName,
              type: activity?.type,
              activityPriority: activity?.activityPriority,
              eventSupportedActivityTypeId:
                activity?.eventSupportedActivityTypeId,
            });
          }
        });
      }

      if (eventData?.secondaryActivities) {
        eventData.secondaryActivities.map((secActivity) => {
          secOptions.push({
            label: secActivity?.displayName,
            value: secActivity?.displayName,
            type: secActivity?.type,
            activityPriority: secActivity?.activityPriority,
            id: secActivity?.id,
          });
        });
      }

      actOptions = [...actOptions, ...secOptions];

      let defaultActivityType = JSON.parse(
        localStorage?.getItem("defaultRunnerActivityType")
      );
      let defaultCategory = JSON.parse(
        localStorage?.getItem("defaultRunnerCategoryName")
      );
      let actIndex = actOptions.findIndex(
        (x) => x?.label === defaultActivityType?.displayName
      );

      let catOptions = [];
      if (eventData?.eventRunCategories) {
        eventData.eventRunCategories.forEach((element) => {
          let obj = {
            label: element.categoryName,
            value: element.categoryName,
            id: element.id,
            type: element.activityType?.type,
          };
          if (actIndex === -1) {
            actIndex = 0;
          }
          //having supportedActivityType
          if (element?.eventSupportedActivityType?.id) {
            if (
              actOptions[actIndex]?.eventSupportedActivityTypeId &&
              // actOptions[actIndex].eventSupportedActivityTypeId ===
              element.eventSupportedActivityType.id
            ) {
              if (!element.hidden) {
                catOptions.push(obj);
              } else {
                return;
              }
            }
          } else {
            //not having supportedActivityType
            if (actOptions[actIndex].type === element.activityType?.type) {
              catOptions.push(obj);
            }
          }
        });
      }

      let catIndex = catOptions.findIndex(
        (x) => x.label === defaultCategory?.categoryName
      );

      this.setState(
        {
          activityOptions: actOptions,
          secOptions: secOptions,
          type: actIndex !== -1 ? actOptions[actIndex] : actOptions[0],
          categoryOptions: catOptions,
          categoryValue: catIndex !== -1 ? catOptions[catIndex] : catOptions[0],
        },
        () => {
          this.handleChange(this.state?.type);
          this.categoryHandleChange(catOptions[catIndex]);
        }
      );
    }

    getLeaderboardFilter(eventId).then((filterRes) => {
      if (filterRes.data) {
        filterRes.data.map((option) => {
          options.push({
            label: option.displayName,
            value: option.displayName,
            id: option.id,
            view: option.view,
            displayName: option.displayName,
            type: option.type,
          });
        });

        if (options.length > 0) {
          this.setState({ ageOptions: options }, () => {
            overall = options.filter(function (x) {
              return x.type === "OVERALL";
            });

            this.setState({ ageValue: overall[0] });
          });
        }

        this.getTableData(
          this.state.type.type,
          this.state.categoryValue?.id,

          this.state.type?.activityPriority,
          overall[0]?.id
        );
      } else {
        this.getTableData(
          this.state.type?.type,
          this.state.categoryValue?.id,
          this.state.type?.activityPriority || "PRIMARY"
        );
      }
    });
  };
  isEventOngoing = (events) => {
    //if event is ongoing
    const onGoingEvents = events?.filter((event) =>
      moment().isBetween(
        event?.eventLocalStartDate,
        moment(event?.eventLocalEndDate).add(1, "day")
      )
    );
    if (onGoingEvents.length === 1) {
      return onGoingEvents[0];
    } else if (onGoingEvents.length == 0) {
      //if there's no ongoing event & events are ended then return latest end date.
      var mostRecentDate = new Date(
        Math.max.apply(
          null,
          events.map((e) => {
            return new Date(e?.eventLocalEndDate);
          })
        )
      );
      var mostRecentObject = events.filter((e) => {
        var d = new Date(e?.eventLocalEndDate);
        return d.getTime() == mostRecentDate.getTime();
      })[0];
      return mostRecentObject;
    } else {
      return events[0];
    }
  };
  componentDidUpdate = (previousProps) => {
    if (this.props.radioValue !== previousProps.radioValue) {
      this.initialDataLoad();
    }
  };

  onHandleDateChange = (event) => {
    const { customDatesOptions } = this.state;

    let dateObj = customDatesOptions.find(
      (x) => x.value === event.target.value
    );
    if (this.props?.radioValue === "Cohort") {
      this.setState({
        selectedCustomDate: event.target.value ? dateObj : null,
      });
    } else {
      this.setState({
        selectedCustomDate: event.target.value ? dateObj : null,
      });
    }
  };

  onHandleDropDownSelectMenu = (event) => {
    const { dropDownMenuCategoryList } = this.state;

    let SelectedObj = dropDownMenuCategoryList?.find(
      (x) => x.value === event.target.value
    );

    if (SelectedObj?.value === "Overall") {
      this.setState({
        selectedCustomDate: null,
      });
      this.getTableData(
        "STEPS",
        this.state?.categoryValue?.id,
        "PRIMARY",
        "Overall"
      );
    } else if (SelectedObj?.value === "Monthly Challenge") {
      let monthDates = this.dateCaluculation(this.state?.eventData, "Month");
      this.getTableData(
        "STEPS",
        this.state?.categoryValue?.id,
        "PRIMARY",
        "Month"
      );
    } else {
      let monthDates = this.dateCaluculation(this.state?.eventData);

      this.initialDataLoad();
    }

    this.setState({
      selectedDropdownMenu: event.target.value ? SelectedObj : null,
    });
  };
  // dateHandleChange(data) {
  //   let subDates = [];
  //   for (let i = 0; i < data?.length; i += 7) {
  //     let sub = data.slice(i, i + 7);
  //     if (
  //       subDates?.length > 0 &&
  //       subDates[subDates?.length - 1].length + sub?.length <= 10
  //     ) {
  //       subDates[subDates?.length - 1] =
  //         subDates[subDates?.length - 1].concat(sub);
  //     } else {
  //       subDates.push(sub);
  //     }
  //   }
  //   return subDates;
  // };
  getTableData = (activityType, categoryId, activityPriority, type) => {
    const { eventData, selectedProgramEvent } = this.state;
    const { challengeType, isProgram } = this.props;
    let femaleArr = [];
    let overallArr = [];
    let maleArr = [];
    let femaleWinnerArr = [];
    let overallWinnerArr = [];
    let maleWinnerArr = [];
    const { index, dateRangeOptions, selectedCustomDate } = this.state;
    let dataParam = {
      activity: activityType,
      categoryId: activityPriority === "SECONDARY" ? "" : categoryId,
      activityPriority: activityPriority,
    };

    if (!isProgram && type !== "Overall" && selectedCustomDate?.value) {
      dataParam.fromDate = `${selectedCustomDate?.fromDate} 00:00:00`;
      dataParam.toDate = `${selectedCustomDate?.toDate} 23:59:59`;
    }

    let runnerEventId = ApplicationUtil.getRunnersEventID();

    let organizerEventId = ApplicationUtil.getSupportsEventID();
    let eventId = window.location.href.includes("/organizer")
      ? organizerEventId
      : runnerEventId;

    if (this.props.radioValue === "Cohort" && type === "Month") {
      this.setState({
        CohortLocationWiseData: [
          {
            label: "Max steps - Stores",
            payload: "Store",
            type: "LocationWiseCohorts",
          },
          {
            label: "Max steps - DC",
            payload: "Distribution Centre",
            type: "LocationWiseCohorts",
          },
          {
            label: "Max steps - Office",
            payload: "Office",
            type: "LocationWiseCohorts",
          },
          {
            label: "Max steps across locations",
            payload: "all",
            type: "LocationWiseCohorts",
          },
        ],
      });
    } else if (this.props.radioValue === "Cohort" && type !== "Month") {
      fetchCohortLongPeriodData(dataParam, eventId).then((res) => {
        this.setState({
          cohortData: res.data,
          leaderboardData: "",
          CohortLocationWiseData: [],
        });
      });
    } else if (dataParam?.activity) {
      getIndividualLeaderboard(dataParam, eventId).then((res) => {
        if (res.data) {
          this.setState({
            leaderboardData: res.data,
            cohortData: [],
            CohortLocationWiseData: [],
            challengeParameter: res?.data?.challengeParameter,
            challengeParameter: res?.data?.challengeParameter,
          });
          let totalEventTarget =
            res.data?.challengeParameter == "STEPS"
              ? parseFloat(res.data?.eventTarget).toFixed(0)
              : res.data?.challengeParameter == "DISTANCE" &&
                res.data?.eventTarget % 1000 === 0
              ? parseFloat(res.data?.eventTarget / 1000).toFixed(0)
              : parseFloat(res.data?.eventTarget / 1000).toFixed(2);
          let selectedChallengeValue =
            res.data?.challengeParameter == "STEPS"
              ? parseFloat(res.data?.eventTotalSteps).toFixed(0)
              : res.data?.challengeParameter == "DISTANCE" &&
                res.data.eventTotalDistance % 1000 === 0
              ? parseFloat(res.data.eventTotalDistance / 1000).toFixed(0)
              : parseFloat(res.data.eventTotalDistance / 1000).toFixed(2);
          let completedChallengeTarget = (
            (Number(selectedChallengeValue) / Number(totalEventTarget)) *
            100
          ).toFixed(2);
          this.setState({
            tickerText: res.data?.teamTickerText,
            challengeParameter: res.data?.challengeParameter,
            completedChallengeTarget: completedChallengeTarget,
            kmsCompleted: selectedChallengeValue,
            totalEventTarget: totalEventTarget,
            isProgressMaxThanTarget:
              completedChallengeTarget > 100 ? true : false,
            // selectedChallengeValue > totalEventTarget ? true : false,
            completedProgressActivity: selectedChallengeValue,
          });
          if (res?.data?.particiapants) {
            if (res.data.particiapants[0]?.femaleActivities) {
              res.data.particiapants[0].femaleActivities.map(
                (participant, index) => {
                  participant.rank = index + 1;
                  if (index + 1 > 3) {
                    femaleArr.push(participant);
                  } else if (index + 1 <= 3) {
                    femaleWinnerArr.push(participant);
                  }
                }
              );
              if (femaleArr.length > 0) {
                this.setState({ overallFemale: femaleArr });
              }
              if (femaleWinnerArr.length > 0) {
                this.setState({ femaleWinners: femaleWinnerArr });
              }
            } else {
              this.setState({ overallFemale: null, femaleWinners: null });
            }
            if (res.data.particiapants[0]?.maleActivities) {
              res.data.particiapants[0].maleActivities.map(
                (participant, index) => {
                  participant.rank = index + 1;
                  if (index + 1 > 3) {
                    maleArr.push(participant);
                  } else if (index + 1 <= 3) {
                    maleWinnerArr.push(participant);
                  }
                }
              );
              if (maleArr.length > 0) {
                this.setState({ overallMale: maleArr });
              }
              if (maleWinnerArr.length > 0) {
                this.setState({ maleWinners: maleWinnerArr });
              }
            } else {
              this.setState({ overallMale: null, maleWinners: null });
            }
          }

          if (res?.data?.overall) {
            res.data.overall.map((participant, index) => {
              participant.rank = index + 1;
              if (index + 1 > 3) {
                overallArr.push(participant);
              } else if (index + 1 <= 3) {
                overallWinnerArr.push(participant);
              }
            });
            if (overallArr.length > 0) {
              this.setState({ overallParticipants: overallArr });
            }
            if (overallWinnerArr.length > 0) {
              this.setState({ overallWinners: overallWinnerArr });
            }
          } else {
            this.setState({ overallParticipants: null, overallWinners: null });
          }
        } else {
          this.setState({
            leaderboardData: "",
          });
        }
      });
    } else {
      this.setState({
        leaderboardData: "",
        cohortData: [],
        CohortLocationWiseData: [],
      });
    }
  };

  ageHandleChange = (value) => {
    if (value) {
      this.setState({ ageValue: value });
    }
  };
  handleChange = (selectedValues) => {
    //   if (value.type === "RUN") {
    //     this.setState({
    //       categoryOptions: this.state.runCategoryOptions,
    //       categoryValue: this.state.runCategoryOptions[0],
    //     });
    //   }
    //   if (selectedValues.type === "CYCLE") {
    //     this.setState({
    //       categoryOptions: this.state.cycleCategoryOptions,
    //       categoryValue: this.state.cycleCategoryOptions[0],
    //     });
    //   }

    //   if (selectedValues.type === "STEPS") {
    //     this.setState({
    //       categoryOptions: this.state.stepsCategoryOptions,
    //       categoryValue: this.state.stepsCategoryOptions[0],
    //     });
    //   }
    // }

    const { completeEventCategories } = this.state;

    var arr = [];
    if (!selectedValues) {
      arr.push(selectedValues?.value);

      let catList = [];
      completeEventCategories &&
        completeEventCategories.forEach((element) => {
          let obj = {
            label: element.categoryName,
            value: element.categoryName,
            id: element.id,
            type: element.activityType?.type,
          };

          //having supportedActivityType
          if (element?.eventSupportedActivityType?.id) {
            if (
              selectedValues?.eventSupportedActivityTypeId &&
              selectedValues?.eventSupportedActivityTypeId ===
                element?.eventSupportedActivityType?.id
            ) {
              if (!element.hidden) {
                catList.push(obj);
              } else {
                return;
              }
            }
          } else {
            //not having supportedActivityType
            if (selectedValues?.type === element.activityType.type) {
              catList.push(obj);
            }
          }
        });

      this.setState({
        type: selectedValues,
        categoryOptions: catList,
        categoryValue: catList[0] ? catList[0] : "",
      });
    } else {
      this.setState({
        type: selectedValues,
        eventType: selectedValues,
        categoryValue: selectedValues,
      });
    }
    // this.handleClick();
  };

  categoryHandleChange = (value) => {
    if (value) {
      this.setState({ categoryValue: value });
    }
  };

  handleClick = (e) => {
    // e.preventDefault();

    const { isProgram, reload } = this.props;
    const {
      ageValue,
      ageOptions,
      categoryValue,
      type,
      data,
      overallFemale,
      a,

      overallMale,
      femaleWinners,
      maleWinners,
      selectedProgramEvent,
      selectedDate,
    } = this.state;

    // if(selectedDate){
    //   this.setState({ selectedCustomDate: selectedDate });
    // }

    if (isProgram && selectedProgramEvent?.value) {
      this.initialDataLoad(selectedProgramEvent);
      reload(selectedProgramEvent);
    } else {
      let eventFilter,
        categoryId = categoryValue?.id,
        // eventId = data.id,
        activity = type?.type,
        activityPriority = type?.activityPriority || "PRIMARY";
      ageOptions.map((opt) => {
        if (opt.id === ageValue.id) {
          eventFilter = ageValue;
        }
      });

      if (categoryValue?.label) {
        this.setState({ categoryLabel: categoryValue.label });
      }

      if (femaleWinners !== null) {
        this.setState({ femaleWinners: null });
      }
      if (maleWinners !== null) {
        this.setState({ maleWinners: null });
      }
      if (overallFemale !== null) {
        this.setState({ overallFemale: null });
      }
      if (overallMale !== null) {
        this.setState({ overallMale: null });
      }

      if (this?.state?.selectedDropdownMenu?.value === "Monthly Challenge") {
        loading(true);
        setTimeout(() => {
          this.getTableData(
            "STEPS",
            this.state?.categoryValue?.id,
            "PRIMARY",
            "Month"
          );
          loading(false);
        }, 300);
      } else {
        this.getTableData(activity, categoryId, activityPriority, ageValue.id);
      }

      if (this.props && this.props.getSearchedMapResults) {
        this.props.getSearchedMapResults("CATEGORY", categoryId);
      }
    }
  };

  componentWillUnmount() {
    clearInterval(this.boardInterval);
  }

  handleOnProgramEventChange = (val) => {
    const { programEventDTO } = this.state;

    let event = programEventDTO?.find((x) => x.id === val?.value);
    this.props.updateUserSelectedEvent(event);
    this.setState({ selectedProgramEvent: val });
  };

  render() {
    const {
      dropDownMenuCategoryList,
      selectedDropdownMenu,
      CohortLocationWiseData,
      type,
      ageValue,
      ageOptions,
      categoryValue,
      dateRange,
      activityOptions,
      categoryOptions,
      secOptions,
      dateRangeOptions,
      overallFemale,
      overallMale,
      maleWinners,
      femaleWinners,
      categoryLabel,
      leaderboardData,
      challengeParameter,
      completedChallengeTarget,
      totalEventTarget,
      isProgressMaxThanTarget,
      completedProgressActivity,
      completeEventCategories,
      eventLocalEndDate,
      eventLocalStartDate,
      customDatesOptions,
      skipTimeOnUploadActivity,
      kmsCompleted,
      programEventList,
      selectedProgramEvent,
      overallParticipants,
      overallWinners,
      selectedCustomDate,
      cohortData,
      tickerText,
      selectedDate,
    } = this.state;
    const { classes, challengeType, radioValue, isOk, isProgram, ageWiseData } =
      this.props;

    return (
      <div className={classes.individualBoard}>
        <Grid
          spacing={1}
          container
          direction="row"
          alignItems="center"
          wrap="wrap"
          justifyContent="center"
          // md={12}
          className={classes.dropdownStyling}
          style={{ marginBottom: "10px", margin: "0 5px" }}
        >
          {/* select category */}
          {!isProgram && customDatesOptions.length > 0 && (
            <Grid
              item
              xs={4}
              sm={4}
              md={2}
              justifyContent="center"
              alignItems="center"
            >
              <Typography className={classes?.label_category} gutterBottom>
                <span style={{ color: "#727272" }}>{"Select Category"}</span>
                <span style={{ color: "red" }}>*</span>
              </Typography>

              <NativeSelect
                id="myDropdown"
                value={selectedDropdownMenu?.value}
                onChange={this.onHandleDropDownSelectMenu}
                className={classes.myDropdown_select}
              >
                {dropDownMenuCategoryList?.map((value, index) => (
                  <option
                    value={value.value}
                    key={index}
                    className={classes?.myDropdown_option}
                    style={{ padding: "0 2px" }}
                  >
                    {value.label}
                  </option>
                ))}
              </NativeSelect>
            </Grid>
          )}

          {/* dependent on others */}
          {(selectedDropdownMenu?.value === "Weekly Challenge" ||
            selectedDropdownMenu?.value === "Monthly Challenge") &&
            customDatesOptions.length > 0 && (
              <Grid
                item
                xs={4}
                sm={4}
                md={2}
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  className={classes?.label_category}
                  gutterBottom
                >
                  <span style={{ color: "#727272" }}>{"Duration"}</span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <NativeSelect
                  id="myDropdown"
                  value={selectedDate?.value || selectedCustomDate?.value }
                  onChange={this.onHandleDateChange}
                  className={classes?.myDropdown_select}
                >
                  {customDatesOptions?.map((value, index) => (
                    <option
                      value={value.value}
                      key={index}
                      className={classes?.myDropdown_option}
                      style={{ padding: "0 2px" }}
                    >
                      {value.label}
                    </option>
                  ))}
                </NativeSelect>
              </Grid>
            )}

          {!isProgram && (
            <Grid item xs={2} sm={4} md={2} style={{ textAlign: "center" }}>
              <SubmitCancelBtn
                type="submit"
                label={"Go"}
                onClick={this.handleClick}
                style={{ width: "60%", margin: "auto", marginTop: "20px" }}
              />
            </Grid>
          )}
        </Grid>

        {cohortData &&
          radioValue === "Cohort" &&
          selectedDropdownMenu?.value !== "One Time" && (
            <NewTeamBoard
              radioValue={radioValue}
              cohortData={cohortData}
              CohortLocationWiseData={CohortLocationWiseData}
              selectedCustomDate={selectedCustomDate}
            />
          )}

        {selectedDropdownMenu?.value === "One Time" &&
        (radioValue === "individual" || radioValue === "Cohort") ? (
          <>
            <NewTeamBoard radioValue={radioValue} />
          </>
        ) : (
          <>
            <div
              style={{
                width: "92%",
                margin: "auto",
              }}
            >
              {tickerText && radioValue === "individual" && (
                <Ticker>
                  {({ index }) => (
                    <p
                      style={{
                        fontSize: "25px",
                        fontWeight: 600,
                        marginLeft: "75px",
                        whiteSpace: "nowrap",
                        color: "#780000",
                      }}
                    >
                      {tickerText + "."}
                    </p>
                  )}
                </Ticker>
              )}
            </div>
            {leaderboardData && radioValue === "individual" ? (
              <>
                {leaderboardData?.genderWise || !leaderboardData?.genderWise ? (
                  <>
                    {totalEventTarget > 0 &&
                      (challengeParameter == "DISTANCE" ||
                        challengeParameter == "STEPS") && (
                        <Grid item className={classes.graphs}>
                          <LinearProgressBar
                            completedValue={Number(completedChallengeTarget)}
                            targetedValue={totalEventTarget}
                            isMaxThanTargeted={isProgressMaxThanTarget}
                            maximumValue={
                              isProgressMaxThanTarget
                                ? Number(
                                    (completedChallengeTarget - 100).toFixed(2)
                                  )
                                : null
                            }
                            kmsCompleted={kmsCompleted}
                            showKm
                            challengeParameter={challengeParameter}
                            completedActivity={completedProgressActivity}
                          />
                        </Grid>
                      )}
                    {leaderboardData?.particiapants ? (
                      <>
                        {/* single table */}
                        {(overallFemale !== null || femaleWinners !== null) &&
                        (overallMale !== null || maleWinners !== null) &&
                        challengeType !== "TEAM" ? (
                          <></>
                        ) : overallFemale || femaleWinners ? (
                          <div className={classes.leaderboard_top}>
                            <div className={classes.container_top}>
                              <EmojiEventsTwoToneIcon
                                style={{ color: "rgb(40, 131, 181)" }}
                              />
                              <span
                                style={{
                                  color: "rgb(40, 131, 181)",
                                  fontSize: "20px",
                                }}
                              >
                                {" Female"}
                              </span>
                            </div>
                            <BoardList
                              challangeType={
                                leaderboardData?.challengeType || ""
                              }
                              rank={!isProgram}
                              center
                              distance={
                                challengeParameter === "DISTANCE" ? true : false
                              }
                              time={
                                challengeParameter === "DISTANCE" ? true : false
                              }
                              winners={femaleWinners}
                              data={overallFemale}
                              gender="FEMALE"
                              challengeParameter={challengeParameter}
                              style={{ backgroundColor: "#df4f9d" }}
                              skipTimeOnUploadActivity={
                                skipTimeOnUploadActivity
                              }
                              showTopThree={
                                selectedDropdownMenu?.value !== "Overall"
                              }
                            />
                          </div>
                        ) : overallMale ||
                          (maleWinners && challengeType !== "TEAM") ? (
                          <div className={classes.leaderboard_top}>
                            <div className={classes.container_top}>
                              <EmojiEventsTwoToneIcon
                                style={{ color: "rgb(40, 131, 181)" }}
                              />
                              <span
                                style={{
                                  color: "rgb(40, 131, 181)",
                                  fontSize: "20px",
                                }}
                              >
                                {" Male"}
                              </span>
                            </div>
                            <BoardList
                              challangeType={
                                leaderboardData?.challengeType || ""
                              }
                              rank={!isProgram}
                              center
                              data={overallMale}
                              distance={
                                challengeParameter === "DISTANCE" ? true : false
                              }
                              time={
                                challengeParameter === "DISTANCE" ? true : false
                              }
                              winners={maleWinners}
                              gender="MALE"
                              challengeParameter={challengeParameter}
                              style={{ backgroundColor: "#df4f9d" }}
                              skipTimeOnUploadActivity={
                                skipTimeOnUploadActivity
                              }
                              showTopThree={
                                selectedDropdownMenu?.value !== "Overall"
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* male female table */}

                        {(overallFemale !== null ||
                          (femaleWinners !== null &&
                            challengeType !== "TEAM")) &&
                          (overallMale !== null ||
                            (maleWinners !== null &&
                              challengeType !== "TEAM")) && (
                            <>
                              <div className={classes.leaderboard_maleFemale}>
                                <div className={classes.leaderboard_container}>
                                  <div className={classes.container_title}>
                                    <EmojiEventsTwoToneIcon
                                      style={{
                                        color: "rgb(40, 131, 181)",
                                        marginBottom: "10px",
                                      }}
                                    />
                                    <span
                                      style={{
                                        color: "rgb(40, 131, 181)",
                                        fontSize: "20px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      {"Male"}
                                    </span>
                                  </div>

                                  <BoardList
                                    challangeType={
                                      leaderboardData?.challengeType || ""
                                    }
                                    rank={!isProgram}
                                    center
                                    data={overallMale}
                                    distance={
                                      challengeParameter === "DISTANCE"
                                        ? true
                                        : false
                                    }
                                    time={
                                      challengeParameter === "DISTANCE"
                                        ? true
                                        : false
                                    }
                                    winners={maleWinners}
                                    categoryLabel={this.state.categoryLabel}
                                    challengeParameter={challengeParameter}
                                    skipTimeOnUploadActivity={
                                      skipTimeOnUploadActivity
                                    }
                                    gender="MALE"
                                    showTopThree={
                                      selectedDropdownMenu?.value !== "Overall"
                                    }
                                  />
                                </div>
                                <div className={classes.leaderboard_container}>
                                  <div className={classes.container_title}>
                                    <EmojiEventsTwoToneIcon
                                      style={{
                                        color: "rgb(40, 131, 181)",
                                        marginBottom: "10px",
                                      }}
                                    />
                                    <span
                                      style={{
                                        color: "rgb(40, 131, 181)",
                                        fontSize: "20px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      {" Female"}
                                    </span>
                                  </div>

                                  <BoardList
                                    challangeType={
                                      leaderboardData?.challengeType || ""
                                    }
                                    // style={{ backgroundColor: "#df4f9d" }}
                                    rank={!isProgram}
                                    center
                                    data={overallFemale}
                                    winners={femaleWinners}
                                    distance={
                                      challengeParameter === "DISTANCE"
                                        ? true
                                        : false
                                    }
                                    time={
                                      challengeParameter === "DISTANCE"
                                        ? true
                                        : false
                                    }
                                    categoryLabel={this.state.categoryLabel}
                                    challengeParameter={challengeParameter}
                                    skipTimeOnUploadActivity={
                                      skipTimeOnUploadActivity
                                    }
                                    gender="FEMALE"
                                    showTopThree={
                                      selectedDropdownMenu?.value !== "Overall"
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                      </>
                    ) : (
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                      >
                        <Grid item>
                          <p style={{ fontSize: "20px", color: "gray" }}>
                            No participants are available for this category
                            please select another category
                          </p>
                        </Grid>
                      </Grid>
                    )}
                  </>
                ) : (
                  //No gender specific leaderboard
                  <>
                    {leaderboardData?.overall ? (
                      <>
                        <div className={classes.leaderboard_top}>
                          <div className={classes.container_top}>
                            <EmojiEventsTwoToneIcon
                              style={{ color: "rgb(40, 131, 181)" }}
                            />
                            <span
                              style={{
                                color: "rgb(40, 131, 181)",
                                fontSize: "20px",
                              }}
                            >
                              {/* {categoryLabel ? categoryLabel : categoryValue?.label} */}
                              {"Participants"}
                            </span>
                          </div>
                          <BoardList
                            challangeType={leaderboardData?.challengeType || ""}
                            rank={!isProgram}
                            center
                            distance={
                              challengeParameter === "DISTANCE" ? true : false
                            }
                            time={
                              challengeParameter === "DISTANCE" ? true : false
                            }
                            winners={overallWinners}
                            data={overallParticipants}
                            gender=""
                            challengeParameter={challengeParameter}
                            style={{ backgroundColor: "#df4f9d" }}
                            skipTimeOnUploadActivity={skipTimeOnUploadActivity}
                          />
                        </div>
                      </>
                    ) : (
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                      >
                        <Grid item>
                          <p style={{ fontSize: "20px", color: "gray" }}>
                            No participants are available for this category
                            please select another category
                          </p>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </>
            ) : (
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >

                
                <Grid item>
                  {leaderboardData &&
                  !ageWiseData?.length &&
                  !cohortData?.length ? (
                    <p style={{ fontSize: "20px", color: "gray" }}>
                      {!challengeType
                        ? "Result is not available"
                        : "No participants are available for this category please select another category"}
                    </p>
                  ) : (cohortData?.length < 0 || !cohortData?.length) && selectedDropdownMenu?.value !== "Monthly Challenge" ? (
                    <p style={{ fontSize: "20px", color: "gray" }}>
                      Result is not available
                    </p>
                  ) : null}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    userSelectedProgram: state.user.userSelectedProgram,
    userSelectedEvent: state.user.userSelectedEvent,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserSelectedProgram,
    updateUserSelectedEvent,
  })
)(NewLeaderboard);

import React from "react";
import ApplicationUtil from "./../../utils/ApplicationUtil";
import Properties from "./../../properties/Properties";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Card } from "@material-ui/core";
import run from "./../../images/Run.png";
import group from "./../../images/Group.png";
import {
  ArrowDropDownCircleOutlined,
  DriveEtaRounded,
} from "@material-ui/icons";
import { Menu, MenuItem } from "@material-ui/core";
import { LeaderboardIcon } from "./SvgIcon";
import DefaultLeaderboard from "../../images/default-leaderboard.png";
import httpService from "../../apis/httpService";
const styles = (theme) => ({
  rowContainer: {
    paddingBottom: "10px",
    borderRadius: "10px",
    cursor: "pointer",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  imageCont: {
    position: "relative",
    margin: "0 auto",

    justifyContent: "center",
    display: "flex",
    position: "relative",
  },
  reviewImgSmall: {
    width: "60%",
    maxWidth: "60%",
    minWidth: "100%",
  },
  dataContainer: {
    padding: "5px 10px",
  },
  main: {},
  tab: {},
  selectedTab: {},
  actionLabelCss: {
    width: "180px",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

function EventListTiles(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const API_URL = httpService.API_URL();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const {
    row,
    index,
    classes,
    history,
    navigate,
    onClick,
    onUpdateClick,
    onPublishClick,
    showMenu,
    showPublish,
    showDelete,
    onDeleteClick,
    displayRegistration,
    hideResultsForAdmin,
    showModify,
    showImport,
    handleImportClick,
    navigateToRegistration,
    navigateToDetails,
    isProgram,
    onCreateEventClick,
    onProgramCreateChallengeChallenge,
    onOpenModifiedProgram,
    onCreateSessionClick,
  } = props;

  return (
    <Grid
      key={index}
      item
      xs={12}
      sm={6}
      md={4}
      lg={4}
      xl={3}
      style={{ marginBottom: "8px" }}
    >
      <Card
        className={classes.rowContainer}
        // onClick={(e) => {
        //   history.push(navigate);
        //   if (onClick) {
        //     onClick();
        //   }
        // }}
      >
        <div
          onClick={() => {
            if (navigateToRegistration) {
              navigateToRegistration();
            } else if (navigateToDetails) {
              navigateToDetails();
            } else if (navigate) {
              navigate();
            } else if (onClick) {
              onClick();
            }
          }}
        >
          {row.image ? (
            <div className={classes.imageCont}>
              <img
                src={API_URL + row.image.url}
                className={classes.reviewImgSmall}
                alt="rev-itm-img"
              />
            </div>
          ) : row.type === "CHALLENGE" ? (
            <div className={classes.imageCont}>
              <img
                src={group}
                className={classes.reviewImgSmall}
                alt="rev-itm-img"
              />
            </div>
          ) : (
            <div className={classes.imageCont}>
              <img
                src={run}
                className={classes.reviewImgSmall}
                alt="rev-itm-img"
              />
            </div>
          )}
        </div>
        <div className={classes.dataContainer}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              onClick={(e) => {
                if (navigate) {
                  navigate();
                }
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#2883B5",
                }}
              >
                {row.name}
              </div>
              {row?.type !== "CHALLENGE" && (
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: " #424242",
                    marginTop: "10px",
                  }}
                >
                  {row?.activityTypes?.map((e) => (
                    <> {e}</>
                  ))}
                </div>
              )}

              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: " #424242",
                }}
              >
                {row?.type === "SINGLE" ? (
                  <>
                    {row.localStartDate && row.localEndDate ? (
                      row.localStartDate === row.localEndDate ? (
                        <span>
                          {ApplicationUtil.changeDateFormat(row.localStartDate)}
                        </span>
                      ) : (
                        <>
                          <div style={{ marginBottom: "10px" }}>
                            <span>
                              {Properties.Label_From}{" "}
                              {ApplicationUtil.changeDateFormat(
                                row.localStartDate
                              )}
                            </span>
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            <span>{Properties.Label_To}</span>{" "}
                            {ApplicationUtil.changeDateFormat(row.localEndDate)}
                          </div>
                        </>
                      )
                    ) : (
                      <span>
                        {ApplicationUtil.FormatDateToUTC(
                          row.eventDate,
                          row.eventTime
                        )}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {row.localStartDate && row.localEndDate ? (
                      <>
                        <div style={{ marginBottom: "10px" }}>
                          <span>
                            {Properties.Label_From}{" "}
                            {ApplicationUtil.changeDateFormat(
                              row.localStartDate
                            )}
                          </span>
                        </div>
                        <div>
                          <span>{Properties.Label_To}</span>{" "}
                          {ApplicationUtil.changeDateFormat(row.localEndDate)}
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ marginBottom: "10px" }}>
                          <span>
                            {Properties.Label_From}{" "}
                            {ApplicationUtil.FormatDateToUTC(
                              row.eventDate,
                              row.eventTime
                            )}
                          </span>
                        </div>
                        <div>
                          <span>{Properties.Label_To}</span> {" " + row.endDate}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            {displayRegistration && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {row?.isRegistrationOpen && (
                  <div
                    style={{
                      borderRadius: "25px",
                      border: "1px solid lightgray",
                      padding: "3px",
                    }}
                    onClick={() => {
                      history.push(`/login?distKey=${row.distKey}`);
                    }}
                  >
                    <span
                      style={{
                        borderRadius: "50%",
                        display: "inline-block",
                        backgroundColor: "green",
                        height: "10px",
                        width: "10px",
                      }}
                    ></span>{" "}
                    {hideResultsForAdmin ? (
                      <span>Registrations Open</span>
                    ) : (
                      <span>Register Now</span>
                    )}
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  {row?.eventStatus === "PAST" && (
                    <div
                      style={{
                        borderRadius: "25px",
                        border: "1px solid lightgray",
                        maxHeight: "20px",
                        padding: "4px",
                        cursor: "default",
                        minWidth: "75px",
                      }}
                    >
                      <span
                        style={{
                          borderRadius: "50%",
                          display: "inline-block",
                          backgroundColor: "red",
                          height: "10px",
                          width: "10px",
                        }}
                      ></span>{" "}
                      <span>Closed</span>
                    </div>
                  )}                 
                  {!hideResultsForAdmin && row.summaryGenerated && (
                    <div
                      onClick={() => {
                        history.push(`/results?distKey=${row.distKey}`);
                      }}
                      style={{
                        borderRadius: "25px",
                        border: "1px solid lightgray",
                        maxHeight: "28px",
                        padding: "2px 8px",
                        cursor: "cursor",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <LeaderboardIcon
                        style={{ fontSize: "40px", color: "#F79824" }}
                      />
                      {/* <img
                        src={DefaultLeaderboard}
                        alt="leaderboard-icon"
                        style={{ height: "20px", width: "20px" }}
                      /> */}
                      <span style={{ paddingLeft: "4px" }}> Results</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {showMenu && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
              }}
            >
              <ArrowDropDownCircleOutlined onClick={handleClick} />
            </div>
          </div>
        )}
      </Card>
      {/* </div> */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {isProgram ? (
          <>
            <MenuItem
              onClick={() => {
                onCreateEventClick();
              }}
            >
              Add Event
            </MenuItem>
            <MenuItem
              onClick={() => {
                onCreateSessionClick();
              }}
            >
              Add Session
            </MenuItem>
            <MenuItem
              onClick={() => {
                onProgramCreateChallengeChallenge();
                history.push("/organizer/createChallenge");
                localStorage.setItem("createChallenge", "createChallenge");
                localStorage.setItem("sidebarSelected", "Summary");
              }}
            >
              Add Challenge
            </MenuItem>
            <MenuItem
              onClick={() => {
                onOpenModifiedProgram();
                localStorage.setItem("sidebarSelected", "Setup");
                localStorage.setItem("editView", true);
                localStorage.setItem("isCreateOrDraft", true);
                localStorage.setItem("selectedProgramCreateOrEdit", true);
                history.push("/organizer/list");
                // localStorage.setItem("createChallenge", "createChallenge");
                // localStorage.setItem("sidebarSelected", "Summary");
              }}
            >
              Modify Program
            </MenuItem>
          </>
        ) : (
          <>
            {showModify && (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  onUpdateClick();
                }}
              >
                Modify Event
              </MenuItem>
            )}

            {showPublish && (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  onPublishClick();
                }}
              >
                Publish Event
              </MenuItem>
            )}
            {showDelete && (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  onDeleteClick();
                }}
              >
                Delete Event
              </MenuItem>
            )}

            {showImport && (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleImportClick();
                }}
              >
                Import
              </MenuItem>
            )}
          </>
        )}
      </Menu>
    </Grid>
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(EventListTiles);

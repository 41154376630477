import { withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useEffect } from "react";
import { HorizontalBar } from "@reactchartjs/react-chart.js";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  getProgramCompanyDashboardData,
  getProgramEventList,
} from "../../../../../apis/eventService";
import Breadcrumbs from "../../../../common/Breadcrumbs";
import httpService from "../../../../../apis/httpService";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { CircularProgressbar } from "react-circular-progressbar";
import ApplicationConstants from "../../../../../constants/ApplicationConstants";
import ProgressBar from "../../../../common/ProgressBar";
import AppToolBar from "../../../../common/AppToolBar";
import Individual from "../../../registration/events/Individual";
import OrganizerCommonHeader from "../OrganizerCommonHeader";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import Properties from "../../../../../properties/Properties";
import { updateUserSelectedEvent } from "../../../../../redux/user";
import StepperBarGraph from "../../../../common/Graphs/StepperBarGraph";
import GaugeGraph from "../../../../common/Graphs/GaugeGraph";
import NoSugarChallenge from "./NoSugarChallenge";
import PieChartGraph from "../../../../common/Graphs/PieChartGraph";

function OrganizerProgramDashboard({ classes, history, isOrganizer }) {
  const [programDetails, setProgramDetails] = useState();
  const [dateRangeOptions, setDateRangeOptions] = useState();
  const [individualGraphDetails, setIndividualGraphDetails] = useState();
  const [individualValues, setIndividualValues] = useState();
  const [cooperTestData, setCooperTestData] = useState([]);
  const [cooperTestResult, setCooperTestResult] = useState(null);
  const [knowledgeSessionData, setKnowledgeSessionData] = useState();
  const [knowledgeSessions, setKnowledgeSessions] = useState([]);
  const [verticalBarGraphData, setVerticalBarGraphData] = useState();
  const [scrollToGraphView, setScrollToGraphView] = useState();
  const [scrollToGraphViewStair, setScrollToGraphViewStair] = useState();

  // newgraphdata

  const [stepperGraphData, setStepperGraphData] = useState({});
  const [selectWeek, setSelectedWeek] = useState(null); // This should be week number
  const [currentWeekNumber, setCurrentWeekNumber] = useState(
    moment().isoWeek()
  );

  const [challengesGraphData, setChallengesGraphData] = useState([]);
  const [challengesEngagementMeterData, setChallengesEngagementMeterData] =
    useState([]);

  const [
    stairsteperEngagementPercentageData,
    setStairsteperEngagementPercentageData,
  ] = useState([]);

  const [sessionEngagementMeter, setSessionEngagementMeter] = useState();
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    getOrganizerData();
  }, []);

  useEffect(() => {
    const element = document.getElementById("stepChallengeRef");
    if (element) {
      element.scrollIntoView({
        block: "end",
        inline: "end",
        behavior: "instant",
      });
    }
  }, [scrollToGraphView]);

  useEffect(() => {
    const setgraphview = document.getElementById("stairChallengeRef");
    if (setgraphview) {
      setgraphview.scrollIntoView({
        block: "end",
        inline: "end",
        behavior: "instant",
      });
    }
  }, [scrollToGraphViewStair]);

  let isDataPresent =
    !knowledgeSessions?.length > 0 &&
    !cooperTestResult &&
    !individualValues &&
    !stepperGraphData?.weekRange?.length &&
    !Object.keys(stepperGraphData).length > 0 &&
    !challengesGraphData?.length > 0;

  function getOrganizerData() {
    let programDetails = ApplicationUtil.getSupportsProgramDetails();
    setProgramDetails(programDetails);
    if (programDetails) {
      getProgramCompanyDashboardData(programDetails?.id)
        .then((res) => {
          const {
            cooperTestResult,
            graphDTO,
            programSessionResultDTOs,
            programChallengeEngagementMeters,
            programEventEngagementMeters,
            programSessionEngagementMeter,
            pieChartDtos,
          } = res?.data;

          if (programChallengeEngagementMeters?.length > 0) {
            // setChallengesEngagementMeterData
            const programChallengeEngagementMetersData =
              programChallengeEngagementMeters?.filter(
                (graph) =>
                  graph?.programChallengeType ===
                    ApplicationConstants.challengeTypeList[7].value ||
                  graph?.programChallengeType ===
                    ApplicationConstants.challengeTypeList[8].value || graph?.programChallengeType ===
                    ApplicationConstants.challengeTypeList[9].value
              );
            setChallengesEngagementMeterData(
              programChallengeEngagementMetersData || []
            );

            // laxman Change
            setChallengesGraphData(
              pieChartDtos?.filter(
                (graph) =>
                  graph?.programChallengeType ===
                    ApplicationConstants.challengeTypeList[7].value ||
                  graph?.programChallengeType ===
                    ApplicationConstants.challengeTypeList[8].value || graph?.programChallengeType ===
                    ApplicationConstants.challengeTypeList[9].value
              )
            );
          }

          if (graphDTO?.length > 0) {
            const graphData = graphDTO.filter(
              (graph) =>
                graph?.challengeType !==
                ApplicationConstants.challengeTypeList[6].value
            );
            let stairStepperData = graphDTO?.filter(
              (graph) =>
                graph?.challengeType ===
                ApplicationConstants.challengeTypeList[6].value
            );
            const engagementMeter = programEventEngagementMeters?.find(
              (item) =>
                item?.challengeType ===
                ApplicationConstants.challengeTypeList[6].value
            )?.engagementMeter;
            setStairsteperEngagementPercentageData(engagementMeter || []);

            setStepperGraphData(() => {
              const dateSorted =
                stairStepperData?.[0]?.runnerActivityDetails.sort((a, b) => {
                  const dateA = moment(a.startDateLocal, "DD-MM-YYYY HH:mm:ss");
                  const dateB = moment(b.startDateLocal, "DD-MM-YYYY HH:mm:ss");
                  return dateA - dateB;
                });
              let weekWise = [];
              for (let i = 0; i < dateSorted?.length; i += 7) {
                weekWise.push(dateSorted?.slice(i, i + 7));
              }

              function formattedDate(date) {
                return moment(date, "DD-MM-YYYY HH:mm:ss").format("Do MMM");
              }

              let currentDate = moment().format("DD-MM-YYYY");
              let graphValues = {
                title: stairStepperData?.[0]?.eventName,
                eventId: stairStepperData?.[0]?.eventId,
                eventStartDate: stairStepperData?.[0]?.eventStartDate,
                weekRange: [],
                currentWeek: 0,
              };

              for (let i = 0; i < weekWise?.length; i++) {
                let weekData =
                  weekWise[i]?.length > 3
                    ? weekWise[i]
                    : weekWise[i - 1]?.concat(weekWise[i]) || [];

                let startDate =
                  weekData?.length > 0 ? weekData[0]?.startDateLocal : null;
                let endDate =
                  weekData?.length > 0
                    ? weekData[weekData?.length - 1]?.startDateLocal
                    : null;
                if (weekData !== weekWise[i]) {
                  graphValues.weekRange[
                    graphValues.weekRange.length - 1
                  ] = `${formattedDate(startDate)}-${formattedDate(endDate)}`;

                  graphValues[`week${i}`] = weekData;
                } else {
                  graphValues.weekRange.push(
                    startDate && endDate
                      ? `${formattedDate(startDate)}-${formattedDate(endDate)}`
                      : null
                  );
                  graphValues[`week${i + 1}`] = weekData;

                  let calculatedDates = graphValues[`week${i + 1}`].filter(
                    (item, index, array) => {
                      if (
                        moment(item.startDateLocal, "DD-MM-YYYY HH:mm:ss")
                          .format("DD-MM-YYYY")
                          .includes(currentDate)
                      ) {
                        graphValues.currentWeek = i;

                        return `week${i}`;
                      }
                    }
                  );
                }
              }

              return graphValues;
            });

            setVerticalBarGraphData(graphData[0]);
            const {
              runnerActivityDetails,
              yAxis,
              xAxis,
              totalSteps,
              eventTarget,
            } = graphData[0];
            getProgramEventList({ programId: programDetails?.id }).then(
              ({ data }) => {
                let eventDTO = data?.eventDTO;
                let sessions = data?.programSessions;
                const getStepsChallenge = eventDTO?.find(
                  (event) =>
                    event?.eventRunCategories[0]?.activityType?.type ===
                      "STEPS" && event.challengeType !== "STAIR_STEPPER"
                );
                let startD = getStepsChallenge?.localStartDate;
                let endD = getStepsChallenge?.localEndDate;
                let diff =
                  moment(endD, "YYYY-MM-DD").diff(
                    moment(startD, "YYYY-MM-DD"),
                    "day"
                  ) + 1;
                const stepsChallengeGraphData = prepareIndividualValues({
                  runnerActivityDetails,
                  yAxis,
                  xAxis,
                  diff,
                  startD,
                  totalSteps,
                  event: getStepsChallenge,
                  target: eventTarget,
                });

                const engagementMeter = programEventEngagementMeters?.find(
                  (item) => item?.eventId === getStepsChallenge?.id
                )?.engagementMeter;

                setIndividualValues(
                  {
                    ...stepsChallengeGraphData?.values,
                    engagementMeter,
                  } || {}
                );
                setDateRangeOptions(stepsChallengeGraphData?.list || []);
              }
            );
          } else {
            setIndividualValues(null);
          }
          if (cooperTestResult && cooperTestResult?.length) {
            const cooperTestResultWithEngagementMeter = cooperTestResult?.map(
              (test) => {
                const engagementMeter = programEventEngagementMeters?.find(
                  (item) => item?.eventId === test?.eventId
                );
                return { ...test, ...engagementMeter };
              }
            );
            setCooperTestResult(cooperTestResultWithEngagementMeter);
            prepareCooperTestDataForParticipant({ cooperTestResult });
          } else {
            setCooperTestResult(null);
          }

          const knowledgeSession = prepareKnowledgeSessionData({
            programSessionResultDTOs,
          });

          setSessionEngagementMeter(programSessionEngagementMeter);
          setKnowledgeSessions(knowledgeSession?.programSessionResultDTOs);
          setKnowledgeSessionData(knowledgeSession?.sessionData);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }

  function getDatesInWeekRange(startDate, endDate) {
    const dates = [];
    const currentDate = startDate;
    while (currentDate <= endDate) {
      dates.push(currentDate.clone());
      currentDate.add(1, "day");
    }
    return dates;
  }

  function prepareIndividualValues({
    runnerActivityDetails,
    xAxis,
    yAxis,
    registration,
    result,
    diff,
    event,
    startD,
    totalSteps,
    target,
  }) {
    let datesArray = [];
    for (let index = 0; index < diff; index++) {
      let date = moment(startD).add(index, "day").format("DD-MM-YYYY");
      datesArray.push({
        startDateLocal: date,
        averagePace: 0,
        averageSpeed: 0,
      });
    }

    if (runnerActivityDetails !== undefined) {
      for (let j = 0; j < datesArray?.length; j++) {
        for (let i = 0; i < runnerActivityDetails?.length; i++) {
          if (runnerActivityDetails[i].startDateLocal) {
            let d = runnerActivityDetails[i].startDateLocal.split(" ");
            if (datesArray[j].startDateLocal === d[0]) {
              datesArray[j] = runnerActivityDetails[i];
              datesArray[j].distance = runnerActivityDetails[i].distance;
              datesArray[j].wellnessPoints =
                runnerActivityDetails[i].wellnessPoints;
              datesArray[j].completionTime =
                runnerActivityDetails[i].completionTime; //.split(":");
            }
          }
        }
      }
    }
    let labelName = "";
    let dateList = [];
    let dataY2 = [];
    let dateList1 = [];
    let tooltipData = [];
    let legendLabel = "";

    if (xAxis) {
      if (xAxis == "startDateLocal") {
        labelName = "date";
        dateList = datesArray.map(function (e) {
          let d = e.startDateLocal.split("-");
          d = d[0] + "-" + d[1];
          return d; //e.startDateLocal;
        });
      } else if (xAxis === "wellnessPoint") {
        labelName = "WellnessPoint";
        dateList = datesArray.map(function (e) {
          return e.wellnessPoints;
        });
      } else {
        labelName = xAxis;
        dateList = datesArray.map(function (e) {
          let d = e[xAxis];
          return d;
        });
      }
    } else {
      labelName = "date";
      dateList = datesArray.map(function (e) {
        let d = e.startDateLocal.split("-");
        d = d[1] + "-" + d[0];
        return d; //e.startDateLocal;
      });
    }
    if (xAxis) {
      if (xAxis == "startDateLocal") {
        labelName = "date";
        dateList1 = datesArray.map(function (e) {
          let d = e.startDateLocal.split("-");
          d = d[1] + "-" + d[0];
          return d; //e.startDateLocal;
        });
      } else if (xAxis === "wellnessPoint") {
        labelName = "WellnessPoint";
        dateList1 = datesArray.map(function (e) {
          return e.wellnessPoints;
        });
      } else {
        labelName = xAxis;
        dateList1 = datesArray.map(function (e) {
          let d = e[xAxis];
          return d;
        });
      }
    } else {
      labelName = "date";
      dateList1 = datesArray.map(function (e) {
        let d = e.startDateLocal.split("-");
        d = d[1] + "-" + d[0];
        return d; //e.startDateLocal;
      });
    }
    if (yAxis) {
      if (yAxis == "distance") {
        dataY2 = datesArray.map(function (e) {
          let dis = e.distance;
          if (dis % 1000 === 0) {
            return parseFloat(dis / 1000).toFixed(0);
          } else {
            return parseFloat(dis / 1000).toFixed(2);
          }
        });
        legendLabel = Properties.Distance_label + " (Km)";
      } else if (yAxis === "wellnessPoint") {
        dataY2 = datesArray.map(function (e) {
          return e.wellnessPoints;
        });
        legendLabel = Properties.Label_WellnessPoints;
      } else if (yAxis === "steps") {
        dataY2 = datesArray.map(function (e) {
          return e.steps;
        });
        legendLabel = Properties.Label_Steps;
      } else {
        dataY2 = datesArray.map(function (e) {
          return e[yAxis];
        });

        legendLabel = yAxis?.charAt(0).toUpperCase() + yAxis.slice(1);
      }
    } else {
      dataY2 = datesArray.map(function (e) {
        return parseFloat(e.distance / 1000).toFixed(2);
      });
      legendLabel = Properties.Distance_label + " (Km)";
    }
    tooltipData = datesArray.map(function (e) {
      return e[yAxis];
    });

    let list = [];

    if (dateList?.length >= 10 && runnerActivityDetails?.length > 0) {
      for (let i = 0; i < dateList.length; i += 6) {
        if (
          dateList?.length > 6 &&
          dateList?.length >= i + 6 &&
          dateList?.length - i >= 11
        ) {
          list.push({
            displayDate:
              moment(dateList1[i], "MM-DD").format("Do MMM") +
              "-" +
              moment(dateList1[i + 6], "MM-DD").format("Do MMM"),
            from: dateList1[i],
            to: dateList1[i + 6],
            value: {
              trackX:
                moment(dateList1[i], "MM-DD").format("Do MMM") +
                "-" +
                moment(dateList1[i + 6], "MM-DD").format("Do MMM"),
              trackingEstimationByDate: dateHandleChange(dateList),
              selectedTrackingDates: dateHandleChange(dataY2),
              selectedTooltip: dateHandleChange(tooltipData),
            },
          });
          i++;
        } else {
          list.push({
            displayDate:
              moment(dateList1[i], "MM-DD").format("Do MMM") +
              "-" +
              moment(dateList1[dateList1?.length - 1], "MM-DD").format(
                "Do MMM"
              ),
            from: dateList1[i],
            to: dateList1[dateList1?.length - 1],
            value: {
              trackX:
                moment(dateList1[i], "MM-DD").format("Do MMM") +
                "-" +
                moment(dateList1[dateList1?.length - 1], "MM-DD").format(
                  "Do MMM"
                ),
              trackingEstimationByDate: dateHandleChange(dateList),
              selectedTrackingDates: dateHandleChange(dataY2),
              selectedTooltip: dateHandleChange(tooltipData),
            },
          });
          i = dateList?.length;
        }
      }
    } else {
      list.push({
        value: {
          trackingEstimationByDate: dateHandleChange(dateList),
          selectedTrackingDates: dateHandleChange(dataY2),
          selectedTooltip: dateHandleChange(tooltipData),
        },
      });
    }
    let calculateCompletedTarget;
    let totalProgressTarget;
    let selectedCategoryValue;

    totalProgressTarget =
      yAxis === "distance" && target % 1000 === 0
        ? parseFloat(target / 1000).toFixed(0)
        : yAxis === "distance" && target % 1000 !== 0
        ? parseFloat(target / 1000).toFixed(2)
        : target;
    selectedCategoryValue =
      yAxis === "steps"
        ? totalSteps
        : yAxis === "calories"
        ? result?.totalCalories || 0
        : yAxis === "elevation"
        ? result?.totalElevationGain || 0
        : yAxis === "wellnessPoint"
        ? result?.wellnessDetails?.totalWellnessPoint || 0
        : result?.totalActivityDistance || 0 % 1000 === 0
        ? parseFloat(result?.totalActivityDistance || 0 / 1000).toFixed(0)
        : parseFloat(result?.totalActivityDistance || 0 / 1000).toFixed(2);

    calculateCompletedTarget = (
      (Number(selectedCategoryValue) / Number(totalProgressTarget)) *
      100
    ).toFixed(2);

    let values = {
      showGraph: true,
      eventName: event?.name || "",
      showTable: true,
      eventType: event?.type || "CHALLENGE",
      individualGraphRunnerActivities: runnerActivityDetails,
      graphToShow: "INDIVIDUAL",
      graphYAxisIndi: yAxis,
      graphXAxisIndi: xAxis,
      individualGraphData: datesArray,
      dateRangeOptions: list,
      dateListState: dateList,
      dateListY2State: dataY2,
      registration: true,
      totalTarget: target,
      challengeGoal: event?.eventRunCategories[0]?.challengeGoal,
      result: { totalSteps: totalSteps },
      completedProgressIndividual: calculateCompletedTarget,
      targetedProgressIndividual: totalProgressTarget,
      isProgressMaxThanTargetIndividual:
        calculateCompletedTarget > 100 ? true : false,
      completedProgressActivityIndividual: selectedCategoryValue,
    };
    return { list, values };
  }

  function prepareKnowledgeSessionData({ programSessionResultDTOs }) {
    let labels = [];
    let totalSessionData = [];
    let attendedSessionData = [];
    if (
      programSessionResultDTOs &&
      programSessionResultDTOs[0] &&
      Object.keys(programSessionResultDTOs[0]).length > 0
    ) {
      programSessionResultDTOs.forEach((session) => {
        if (Object.keys(session).length > 0) {
          const name =
            session?.sessionType?.charAt(0).toUpperCase() +
            session?.sessionType?.slice(1).toLowerCase();
          labels.push(name?.replaceAll("_", " "));
        }
      });
      programSessionResultDTOs.forEach((session) => {
        if (Object.keys(session).length > 0) {
          totalSessionData.push(session?.totalProgramSession);
          attendedSessionData.push(session?.totalAttendedProgramSession);
        }
      });
    } else {
      labels = ["Yoga", "Nutrition", "Mental Wellness"];
    }

    let datasets = [
      {
        label: "Total Session",
        data: totalSessionData,
        backgroundColor: "#9396E3",
        barThickness: 4,
      },
      {
        label: "Attended Session",
        data: attendedSessionData,
        backgroundColor: "#FE7235",
        barThickness: 10,
      },
    ];
    // setKnowledgeSessionData({ labels, datasets });
    return { programSessionResultDTOs, sessionData: { labels, datasets } };
  }

  function prepareCooperTestDataForParticipant({ cooperTestResult }) {
    let graphData = [];

    if (cooperTestResult && cooperTestResult?.length > 0) {
      cooperTestResult.forEach((test) => {
        graphData.push({
          labels: ["Company Score", ""],
          datasets: [
            {
              label: "Company Score",
              data: [
                Number(
                  parseFloat(test?.cooperTestCurrentScore || 0).toFixed(2)
                ),
                (100 - parseFloat(test?.cooperTestCurrentScore || 0)).toFixed(
                  2
                ),
              ],
              backgroundColor: ["#4472c4", "#f29e66"],
              borderWidth: 3,
            },
          ],
          activityDate: test?.activityDate,
          eventStartDate: test?.eventStartDate,
          cooperTestCurrentScore: Number(test?.cooperTestCurrentScore || 0),
          cooperTestRemark: test?.cooperTestRemark,
        });
      });
    } else {
      graphData = [
        {
          labels: ["Company Score", ""],
          datasets: [
            {
              label: "Company Score",
              data: [0, 100],
              backgroundColor: ["#4472c4", "#f29e66"],
              borderWidth: 3,
            },
          ],
        },
        {
          labels: ["Company Score", ""],
          datasets: [
            {
              label: "Company Score",
              data: [0, 100],
              backgroundColor: ["#4472c4", "#f29e66"],
              borderWidth: 3,
            },
          ],
        },
      ];
    }

    if (graphData.length === 1) {
      graphData.push({
        labels: ["Company Score", ""],
        datasets: [
          {
            label: "Company Score",
            data: [0, 100],
            backgroundColor: ["#4472c4", "#f29e66"],
            borderWidth: 3,
          },
        ],
      });
    }

    setCooperTestData(graphData);
  }

  function navigateToWellnessPointsDetails() {
    let programDetails = ApplicationUtil.getRunnersProgramDetails();
    dispatch(updateUserSelectedEvent(programDetails?.eventDTO[0]));
    history.push("/wellnessDetails");
  }

  function dateHandleChange(data) {
    if (!data || data?.length === 0) return;
    let subDates = [];
    for (let i = 0; i < data.length; i += 7) {
      let sub = data.slice(i, i + 7);
      if (
        subDates.length > 0 &&
        subDates[subDates.length - 1].length + sub.length <= 10
      ) {
        subDates[subDates.length - 1] =
          subDates[subDates.length - 1].concat(sub);
      } else {
        subDates.push(sub);
      }
    }

    return subDates;
  }

  function getIndex(val) {
    let idx = dateRangeOptions.findIndex(
      (x) => x.displayDate == val.target.value
    );
    const X = dateRangeOptions[idx]?.value?.trackingEstimationByDate[idx];
    const Y = dateRangeOptions[idx]?.value?.selectedTrackingDates[idx];
    const Z = dateRangeOptions[idx]?.value?.selectedTooltip[idx];
    setIndividualGraphDetails({ dataX: X, dataY: Y, tooltipDataState: Z });
    setScrollToGraphView(val.target.value);
  }

  const handleValueChange = (e) => {
    setSelectedWeek(parseInt(e.target.value) + 1);
    setScrollToGraphViewStair(e.target.value);
  };

  const HorizontalBarOptions = {
    maintainAspectRatio: false,
    borderRadius: 100,
    legend: {
      display: false,
      position: "bottom",
      align: "end",
      fillStyle: "black",

      labels: {
        fontSize: document.documentElement.clientWidth > 1200 ? 20 : 13,
        fontWeight: "700",
        fontColor: "black",
      },
      onClick: function (event, legendItem) {},
    },

    gridLines: {
      color: "rgba(0, 0, 0, 0)",
    },

    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: document.documentElement.clientWidth > 1200 ? 20 : 13,
            fontWeight: "700",
            fontColor: "black",
          },
          gridLines: {
            display: false,
          },
        },
      ],

      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
          },
          display: false,
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {},
  };

  const getProgressPercentage = () => {
    let percentage =
      (individualValues.result.totalSteps / individualValues?.totalTarget) *
      100;
    return percentage > 100 ? 100 : percentage;
  };
  const getProgressTarget = () => {
    return ApplicationUtil.formatNumberWithCommas(
      individualValues?.totalTarget
    );
  };

  return (
    <div>
      <AppToolBar>
        <OrganizerCommonHeader
          title={
            programDetails?.programName || programDetails?.name
              ? programDetails?.programName || programDetails?.name
              : Properties.Label_ProgramDashboard
          }
        />
        <div style={{ width: 35 }} />
      </AppToolBar>

      <div className={classes.mainContainer} style={{ marginTop: "60px" }}>
        <div className={classes.breadcrumbs_container}>
          <div className="breadcrumbs">
            <Breadcrumbs />
          </div>

          {programDetails?.programDashboardLogo?.url && (
            <img
              className="dashboard_logo"
              src={`${httpService.API_URL()}${
                programDetails?.programDashboardLogo?.url
              }`}
              alt="program-logo"
              height="60"
            />
          )}
        </div>

        <div className={classes.titleContainer}>
          <h2 className={classes.dashBoardTitle}>
            {Properties.program_dashboard.title.corporate}
          </h2>
        </div>

        <div
          className={classes.engagementTitleContainer}
          style={{ justifyContent: cooperTestResult ? "space-between" : "end" }}
        >
          {cooperTestResult && (
            <h2 className={classes.chartTitle}>
              {Properties.program_dashboard.chart_titles.cooper_test}:
            </h2>
          )}
          {cooperTestResult && !isDataPresent && (
            <h2 className={classes.engagementTitle}>
              {Properties.program_dashboard.chart_titles.engagement_meter}
            </h2>
          )}
        </div>
        {cooperTestResult && (
          <>
            <div className={classes.datesContainer}>
              <p className={"scoreTitle"}>
                {Properties.Date_label}:&nbsp;
                {cooperTestData &&
                cooperTestData?.length > 0 &&
                cooperTestData[0]?.eventStartDate
                  ? moment(cooperTestData[0]?.eventStartDate).format(
                      "DD/MM/YYYY"
                    )
                  : "-/-/-"}
              </p>
            </div>
            <div className={classes.doughnutChartContainer}>
              <div className={"doughnutItem"}>
                <div className={"doughnutContainer"}>
                  <CircularProgressbar
                    value={
                      cooperTestResult?.length > 0
                        ? cooperTestResult[0]?.cooperTestCurrentScore || 0
                        : 0
                    }
                    text={`${
                      cooperTestResult?.length > 0
                        ? Number(
                            cooperTestResult[0]?.cooperTestCurrentScore || 0
                          )
                        : 0
                    }/100`}
                    styles={{
                      path: {
                        // Path color
                        stroke: `#FE7235`,
                      },
                      text: {
                        // Text color
                        fill: "#000000",
                        // Text size
                        fontSize: "15px",
                        fontWeight: "bold",
                      },
                    }}
                  />
                </div>
                <div className={"cooperLegendBlock"}>
                  <div className={"legendBlock"}></div>
                  <span className={"legendBlockTitle"}>
                    {Properties.program_dashboard.legends.cooper_test}
                  </span>
                </div>
              </div>
              <Divider
                orientation="vertical"
                flexItem
                style={{ background: "#2C3539" }}
              />

              <div className={"doughnutItem"}>
                <GaugeGraph graphData={cooperTestResult[0]?.engagementMeter} />
              </div>
            </div>
        
            {cooperTestResult?.length > 1 && (
              <div>
                <div className={classes.datesContainer}>
                  <p className={"scoreTitle date2"}>
                    {Properties.Date_label}:&nbsp;
                    {cooperTestData &&
                    cooperTestData?.length > 0 &&
                    cooperTestData[1]?.eventStartDate
                      ? moment(cooperTestData[1]?.eventStartDate).format(
                          "DD/MM/YYYY"
                        )
                      : "-/-/-"}
                  </p>
                </div>

                <div className={classes.doughnutChartContainer}>
                  <div className={"doughnutItem"}>
                    <p className={"cooperDate2"}>
                      {Properties.Date_label}:&nbsp;
                      {cooperTestData &&
                      cooperTestData?.length > 0 &&
                      cooperTestData[1]?.eventStartDate
                        ? moment(cooperTestData[1]?.eventStartDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-/-/-"}
                    </p>
                    <div className={"doughnutContainer"}>
                      <CircularProgressbar
                        value={
                          cooperTestResult?.length > 1
                            ? cooperTestResult[1]?.cooperTestCurrentScore || 0
                            : 0
                        }
                        text={`${
                          cooperTestResult?.length > 1
                            ? cooperTestResult[1]?.cooperTestCurrentScore || 0
                            : 0
                        }/100`}
                        styles={{
                          path: {
                            // Path color
                            stroke: `#FE7235`,
                          },
                          text: {
                            // Text color
                            fill: "#000000",
                            // Text size
                            fontSize: "15px",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </div>
                    <div className={"cooperLegendBlock"}>
                      <div className={"legendBlock"}></div>
                      <span className={"legendBlockTitle"}>
                        {Properties.program_dashboard.legends.cooper_test}
                      </span>
                    </div>
                  </div>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ background: "#2C3539" }}
                  />

                  <div className={"doughnutItem"}>
                    <GaugeGraph
                      graphData={cooperTestResult[1]?.engagementMeter}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {stepperGraphData &&
          stepperGraphData?.weekRange?.length > 0 &&
          Object.keys(stepperGraphData).length > 0 &&
          moment(moment().format("YYYY-MM-DD")).isSameOrAfter(
            stepperGraphData?.eventStartDate
          ) && (
            <Box py={4} id="stairChallengeRef">
              <h2 className={classes.chartTitle}>
                {stepperGraphData?.title} :
              </h2>
              <Box
                display="flex"
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: { xs: "100%", sm: "50%" }, display: "flex" }}>
                  <StepperBarGraph
                    setSelectedWeek={setSelectedWeek}
                    selectWeek={selectWeek}
                    stepperGraphData={stepperGraphData}
                    selectedWeekData={
                      selectWeek
                        ? stepperGraphData?.[`week ${selectWeek}`]
                        : stepperGraphData?.[
                            `week ${stepperGraphData?.firstWeek}`
                          ]
                    }
                    stairStepperValueChange={handleValueChange}
                    isOrganizer={true}
                  />
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{
                    background: "#2C3539",
                    // display: { xs: "none", sm: "block" },
                  }}
                />

                <Box
                  sx={{
                    width: { xs: "100%", sm: "50%" },
                  }}
                >
                  <div className={"doughnutItem"}>
                    <GaugeGraph
                      gapBetweenDateAndGraph={120}
                      graphData={stairsteperEngagementPercentageData}
                    />
                  </div>
                </Box>
              </Box>
            </Box>
          )}
        {/* -----------STeps Challenge---------------- */}
        {individualValues?.individualGraphData?.length > 0 && (
          <div
            id="stepChallengeRef"
            className={classes.stepsChallengeContainer}
          >
            <div className="steps_graph">
              <div
                className={classes.stepsChallengeProgressContainer}
                style={{
                  display:
                    individualValues?.totalTarget !== 0 &&
                    individualValues?.challengeGoal === "TOTAL_TARGET"
                      ? "flex"
                      : "block",
                }}
              >
                <h2 className={classes.chartTitle}>
                  {verticalBarGraphData?.eventName?.toLowerCase() ||
                    "Steps Challenge"}
                  :
                </h2>

                {individualValues?.totalTarget !== 0 &&
                individualValues?.challengeGoal === "TOTAL_TARGET" ? (
                  <div className="progressBarContainer">
                    <ProgressBar
                      percent={getProgressPercentage()}
                      fillingProgressColor={"#FE7235"}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginInline: "20px",
                      }}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span className={classes.totalDetailsProg}>
                          <span className={"stepsTitle"}>
                            {"Total Steps"}:&nbsp;
                            {ApplicationUtil.formatNumberWithCommas(
                              individualValues?.result?.totalSteps
                                ? individualValues?.result?.totalSteps
                                : 0
                            )}
                          </span>
                        </span>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <span className={classes.totalDetailsProg}>
                          <span className={"stepsTitle"}>
                            {"Target"}:&nbsp;
                            {getProgressTarget()}
                          </span>
                        </span>
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                  >
                    <span className={classes.totalDetailsProg}>
                      <span className={"stepsTitle"}>
                        {"Total Steps"}:&nbsp;
                        {ApplicationUtil.formatNumberWithCommas(
                          individualValues?.result?.totalSteps
                            ? individualValues?.result?.totalSteps
                            : 0
                        )}
                      </span>
                    </span>
                  </Typography>
                )}
              </div>
              <div className="steps_graph_container">
                <div className={classes.individualGraphContainer}>
                  {individualValues &&
                    Object.keys(individualValues).length > 0 && (
                      <Individual
                        individualValues={{
                          ...individualValues,
                          tooltipDataState:
                            individualGraphDetails?.tooltipDataState,
                        }}
                        navigateToWellnessPointsDetails={
                          navigateToWellnessPointsDetails
                        }
                        dateHandleChange={dateHandleChange}
                        dataX={individualGraphDetails?.dataX}
                        dataY={individualGraphDetails?.dataY}
                        getIndex={getIndex}
                        barColor={"#FE7235"}
                        isProgram={true}
                        graphData={individualValues?.individualGraphData}
                      />
                    )}
                </div>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{
                    background: "#2C3539",
                    height: "25rem",
                    alignSelf: "center",
                  }}
                />
                <div className="gauge_graph">
                  <GaugeGraph
                    gapBetweenDateAndGraph={120}
                    graphData={individualValues?.engagementMeter}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isDataPresent && (
          <div
            style={{
              margin: "0 auto",
              textAlign: "center",
              marginTop: "10%",
            }}
          >
            {!loading && (
              <Typography style={{ fontSize: "28px", fontWeight: "bold" }}>
                No data found
              </Typography>
            )}
          </div>
        )}


        
        {challengesGraphData &&
          challengesGraphData?.length > 0 &&
          challengesGraphData?.map((item, index) => {
            return (
              <div key={item}>
                <NoSugarChallenge
                  graphData={item}
                  index={index}
                  title={item?.programChallengeName}
                  GaugeGraphData={challengesEngagementMeterData}
                  responseBasedChallenges
                />
              </div>
            );
          })}

        {knowledgeSessionData && knowledgeSessions?.length > 0 && (
          <div className={classes.knowledgeSessionContainer}>
            <div className={classes.knowledgeSessionTitleContainer}>
              <h2 className={"knowledgeSessionTitle"}>
                {Properties.program_dashboard.chart_titles.knowledge_sessions}:
              </h2>
            </div>
            <div className="graphsContainer">
              <div className="session_graph">
                <div className={classes.horizontalBarContainer}>
                  <div style={{ width: "90%" }}>
                    <HorizontalBar
                      height={"100%"}
                      options={HorizontalBarOptions}
                      data={knowledgeSessionData}
                    />
                  </div>
                  <div
                    className={classes.sessionIndicatorContainer}
                    style={{
                      gap:
                        knowledgeSessions.length == 4
                          ? "10px"
                          : knowledgeSessions.length == 3
                          ? "20px"
                          : "32px",
                    }}
                  >
                    {knowledgeSessions?.map((sessionType) => {
                      return (
                        <p className={"sessionText"}>
                          {sessionType?.totalAttendedProgramSession}/
                          {sessionType?.totalProgramSession}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="legends">
                  <div className="attended_session">
                    <span className="legend_block"></span>
                    <span className="text">
                      {Properties.program_dashboard.legends.sessions.attended}
                    </span>
                  </div>
                  <div className="total_session">
                    <span className="legend_block"></span>
                    <span className="text">
                      {Properties.program_dashboard.legends.sessions.total}
                    </span>
                  </div>
                </div>
              </div>
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  background: "#2C3539",
                }}
              />
              <div className="gauge_graph">
                <GaugeGraph
                  gapBetweenDateAndGraph={
                    document.documentElement.clientWidth > 1400 ? 100 : 0
                  }
                  graphData={sessionEngagementMeter}
                  isSession
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const styles = (theme) => ({
  mainContainer: {
    fontFamily: '"Karla", sans-serif',
    margin: "5px",
    marginInline: "20px",
    [theme.breakpoints.down("sm")]: { marginInline: "5px" },
  },
  dashBoardTitle: {
    letterSpacing: "-1px",
    fontWeight: "bold",
    padding: 0,
    fontSize: "40px",
    marginTop: 0,
    marginBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  chartTitle: {
    letterSpacing: "-1px",
    marginBottom: 0,
    color: "#202260",
    fontWeight: "bold",
    fontSize: "35px",
    marginLeft: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginLeft: "0px",
    },
  },
  titleContainer: {
    // border: "1px solid",
    color: "#202260",
    borderColor: "#d1d1d1",
    textAlign: "center",
  },
  engagementTitleContainer: {
    display: "flex",
    marginBottom: 0,
  },
  engagementTitle: {
    letterSpacing: "-1px",
    marginBottom: 0,
    color: "#202260",
    fontWeight: "bold",
    fontSize: "35px",
    marginLeft: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  datesContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginInline: "10px",
    "& .date2": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .scoreTitle": {
      marginTop: "5px",
      fontWeight: "600",
      fontSize: "26px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  },
  doughnutChartContainer: {
    width: "100%",
    display: "flex",

    marginTop: "5px",
    justifyContent: "space-between",

    "& .doughnutItem": {
      // border: "1px solid",
      position: "relative",
      width: "100%",
      paddingBottom: "20px",
      borderColor: "#b3caf6",
      "& .cooperDate2": {
        display: "none",

        [theme.breakpoints.down("sm")]: {
          display: "block",
          fontSize: "18px",
          marginTop: "5px",
          marginLeft: "10px",
          fontWeight: "600",
        },
      },

      "& .doughnutLabel": {
        position: "absolute",
        left: "131px",
        top: "91px",
        width: "50px",
        fontSize: "small",
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
          left: "131px",
          top: "91px",
          width: "50px",
          fontSize: "small",
        },
      },
      "& .doughnutContainer": {
        margin: "0 auto",
        height: "200px",
        width: "200px",
        [theme.breakpoints.down("sm")]: {
          height: "150px",
          width: "150px",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },

    "& .cooperLegendBlock": {
      display: "flex",
      justifyContent: "end",
      marginRight: "44px",
      gap: "10px",
      alignItems: "center",
      "& .legendBlock": {
        width: "40px",
        height: "18px",
        backgroundColor: "#FE7235",
        [theme.breakpoints.down("sm")]: {
          width: "30px",
          height: "12px",
        },
      },
      "& .legendBlockTitle": {
        fontSize: "20px",
        fontWeight: "500",
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          fontWeight: "500",
        },
      },
    },
  },
  stepsChallengeContainer: {
    marginTop: "20px",

    "& .steps_graph_container": {
      width: "100%",
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
      "& .gauge_graph": {
        width: "50%",
      },
    },

    // "& .steps_graph": {
    //   width: "50%",
    // },
  },
  individualGraphContainer: {
    marginTop: "20px",
    width: "50%",
    alignSelf: "center",
  },
  horizontalBarContainer: {
    height: "30vh",
    width: "auto",
    display: "flex",
    // gap: "20px",
    width: "100%",
    // position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "20vh",
      width: "auto",
    },
  },
  knowledgeSessionTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    "& .knowledgeSessionTitle": {
      marginBottom: 0,
      color: "#202260",
      fontWeight: "bold",
      fontSize: "35px",
      marginLeft: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        marginLeft: "0px",
      },
    },
    "& .legendBlock": {
      width: "40px",
      height: "18px",
      backgroundColor: "#FE7235",
      [theme.breakpoints.down("sm")]: {
        width: "30px",
        height: "12px",
      },
    },
    "& .legendBlockTitle": {
      fontSize: "20px",
      fontWeight: "500",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        fontWeight: "500",
      },
    },
    "& .attendanceContainer": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginRight: "120px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px",
      },
    },
  },
  sessionIndicatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    // gap: "32px",

    "& .sessionText": {
      fontSize: "20px",
      fontWeight: "bold",
      padding: 0,
      margin: 0,
      [theme.breakpoints.down(1400)]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
    },
  },
  stepsChallengeProgressContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "baseline",
    gap: "80px",
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
      flexDirection: "column",
    },
    "& .progressBarContainer": {
      width: "60%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  totalDetailsProg: {
    color: "black",
    fontWeight: "bold",
    fontSize: "28px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      fontSize: "20px",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
    },
    "& .stepsTitle": {
      color: "black",
      fontWeight: "bolder",
      fontSize: "26px",
      margin: "0",
      // marginLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  breadcrumbs_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& .breadcrumbs": {
      [theme.breakpoints.down("sm")]: {
        alignSelf: "start",
      },
    },
    "& .dashboard_logo": {
      [theme.breakpoints.down("sm")]: {
        alignSelf: "end",
      },
    },
  },
  knowledgeSessionContainer: {
    "& .legends": {
      display: "flex",
      gap: "20px",
      marginRight: "6%",
      justifyContent: "end",
      alignItems: "center",
      fontSize: "22px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        gap: "10px",
        fontSize: "14px",
        marginRight: "0",
      },
      "& .attended_session": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& .legend_block": {
          height: "18px",
          width: "45px",
          backgroundColor: "#FE7235",
          [theme.breakpoints.down("sm")]: {
            height: "11px",
            width: "37px",
          },
        },
      },
      "& .total_session": {
        display: "flex",
        alignItems: "center",
        gap: "10px",

        "& .legend_block": {
          height: "10px",
          width: "45px",
          backgroundColor: "#9396E3",
          [theme.breakpoints.down("sm")]: {
            height: "5px",
            width: "37px",
          },
        },
      },
    },

    "& .graphsContainer": {
      display: "flex",
      width: "100%",
      alignItems: "end",
      justifyContent: "center",

      "& .gauge_graph": {
        width: "50%",
        alignSelf: "end",
      },
      "& .session_graph": {
        width: "50%",
      },
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(OrganizerProgramDashboard);

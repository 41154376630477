import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { Button, Card, Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import {
  EmojiEvents,
  ErrorOutline,
  ThumbDown,
  ThumbUp,
} from "@material-ui/icons";
import {
  ZerGoldBadge1stWeek,
  ZeroSilverBadge1stWeek,
  ZerBronzeBadge1stWeek,
  CompleteChallenge,
} from "../../../../../images/Badges";
import DialogBox from "../../../../common/DialogBox";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import html2canvas from "html2canvas";
import httpService from "../../../../../apis/httpService";
import {
  day1,
  day2,
  day3,
  day4,
  day5,
  day6,
  day7,
  day14,
  day21,
} from "../../../../../images/YogaChallenge";
import Properties from "../../../../../properties/Properties";

const QuestionCardsCarousel = ({
  challengeType,
  QuestionData,
  classes,
  onClickAndFetchData,
  startDate,
  viewDate,
  ResponseSubmissionEndDate,
  perDayViewData,
  userUploadedImg,
  initialLoad,
  productDetails,
}) => {
  const [responsiveCarousel, setResponsiveCarousel] = useState({
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  });
  const [selectedItem, setSelectedItem] = useState(); // Default to item index 2
  const [weekWiseData, setWeekWiseData] = useState([]);
  const [showMedal, setShowMedal] = useState([]); // Updated to an array
  const carouselRef = useRef(null);
  const API_URL = httpService.API_URL();
  const [showDialog, setShowDialog] = useState(false);
  const [openDialogImage, setOpenDialogImage] = useState();
  const [showPetals, setShowPetals] = useState([]);

  let runnerData = ApplicationUtil.getRunnerDetails();

  const handleClick = (index) => {
    console.log(index);
  };

  useEffect(() => {
    // get index for set default index item
    let getIndex =
      QuestionData &&
      QuestionData?.findIndex(
        (item) =>
          moment(
            item?.programChallengeQuestionDto?.viewDate,
            "DD-MM-YYYY HH:mm:ss"
          ).format("YYYY-MM-DD") === viewDate
      );

    getWeekWiseData();

    if (carouselRef.current && getIndex) {
      setSelectedItem(getIndex);
    }
  }, [perDayViewData?.responseStatus, userUploadedImg, QuestionData]);

  useEffect(() => {
    if (carouselRef.current && selectedItem > 1) {
      carouselRef.current.goToSlide(selectedItem);
    }
  }, [selectedItem]);

  const getWeekWiseData = () => {
    const sortedData = QuestionData?.sort((a, b) => {
      const dateA = moment(
        a.programChallengeQuestionDto?.viewDate,
        "DD-MM-YYYY HH:mm:ss"
      );
      const dateB = moment(
        b.programChallengeQuestionDto?.viewDate,
        "DD-MM-YYYY HH:mm:ss"
      );

      return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
    });

    const weekWise = [];
    for (let i = 0; i < sortedData?.length; i += 7) {
      weekWise.push(sortedData?.slice(i, i + 7));
    }

    setWeekWiseData(weekWise);

    if (sortedData?.some((item) => item.programChallengeType === "YOGA")) {
      getWeeklyProgressOfYoga(sortedData);
    } else {
      getWeeklyCountOfResponse(sortedData);
    }
  };

  const badge = [
    {
      name: "Gold Badge",
      valueIcon: <ZerGoldBadge1stWeek />,
    },
    {
      name: "Silver Badge",
      valueIcon: <ZeroSilverBadge1stWeek />,
    },
    {
      name: "Bronze Badge",
      valueIcon: <ZerBronzeBadge1stWeek />,
    },
    {
      name: "",
      valueIcon: <CompleteChallenge />,
    },
  ];

  const getWeeklyCountOfResponse = (sortedData) => {
    let week1ResponseStatusCount = 0;
    let week1PhotoURLCount = 0;
    let week2ResponseStatusCount = 0;
    let week2PhotoURLCount = 0;
    let week3ResponseStatusCount = 0;
    let week3PhotoURLCount = 0;

    let filterData =
      sortedData &&
      sortedData?.forEach((item, index) => {
        if (index < 7) {
          if (item.responseStatus === "Positive") {
            week1ResponseStatusCount++;
          }
          if (item.photoURL) {
            week1PhotoURLCount++;
          }
        } else if (index < 14) {
          if (item.responseStatus === "Positive") {
            week2ResponseStatusCount++;
          }
          if (item.photoURL) {
            week2PhotoURLCount++;
          }
        } else {
          if (item.responseStatus === "Positive") {
            week3ResponseStatusCount++;
          }
          if (item.photoURL) {
            week3PhotoURLCount++;
          }
        }
      });

    if (sortedData) {
      if (perDayViewData?.responseStatus === "Positive" && !userUploadedImg) {
        const index = sortedData.findIndex(
          (item) =>
            moment(
              item?.programChallengeQuestionDto?.viewDate,
              "DD-MM-YYYY HH:mm:ss"
            ).format("YYYY-MM-DD") ===
            moment(viewDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        );

        let isResponsePresent =
          sortedData?.[index]?.responseStatus === "Positive";

        if (index < 7 && !isResponsePresent) {
          week1ResponseStatusCount += 1;
        } else if (index < 13 && !isResponsePresent) {
          week2ResponseStatusCount += 1;
        } else if (index < 20 && !isResponsePresent) {
          week3ResponseStatusCount += 1;
        }
      }

      if (userUploadedImg) {
        const index = sortedData.findIndex(
          (item) =>
            moment(
              item?.programChallengeQuestionDto?.viewDate,
              "DD-MM-YYYY HH:mm:ss"
            ).format("YYYY-MM-DD") ===
            moment(viewDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        );
        let ImagePresent = sortedData?.[index]?.photoURL;
        if (index < 7 && !ImagePresent) {
          week1PhotoURLCount += 1;
        } else if (index < 13 && !ImagePresent) {
          week2PhotoURLCount += 1;
        } else if (index < 20 && !ImagePresent) {
          week3PhotoURLCount += 1;
        }
      }
    }

    const imageKeys = [
      "ZERO_SUGAR_GOLD",
      "ZERO_SUGAR_SILVER",
      "ZERO_SUGAR_BRONZE",
      "ZERO_SUGAR_SUSTAINED",
    ];

    console.log(
      week1ResponseStatusCount >= 6 && week1PhotoURLCount >= 0
        ? "Hello"
        : week1ResponseStatusCount >= 6 && week1PhotoURLCount >= 3
        ? challengeType?.eventEmailInlineImage?.find((item) =>
            imageKeys?.includes("ZERO_SUGAR_BRONZE")
          )
        : "na bole"
    );
    const week1Medal =
      week1ResponseStatusCount >= 6 && week1PhotoURLCount >= 6
        ? {
            ...challengeType?.eventEmailInlineImage?.find(
              (item) => item.imageKey === "ZERO_SUGAR_GOLD"
            ),
            name: "Gold Badge",
            dayCount: week1ResponseStatusCount,
            week: 1,
          }
        : week1ResponseStatusCount >= 6 && week1PhotoURLCount >= 3
        ? {
            ...challengeType?.eventEmailInlineImage?.find(
              (item) => item.imageKey === "ZERO_SUGAR_SILVER"
            ),
            name: "Silver Badge",
            dayCount: week1ResponseStatusCount,
            week: 1,
          }
        : week1ResponseStatusCount >= 6 && week1PhotoURLCount >= 0
        ? {
            ...challengeType?.eventEmailInlineImage?.find(
              (item) => item.imageKey === "ZERO_SUGAR_BRONZE"
            ),
            name: "Bronze Badge",
            dayCount: week1ResponseStatusCount,
            week: 1,
          }
        : null;

    // week 2
    const week2Medal =
      week1ResponseStatusCount + week2ResponseStatusCount >= 12 &&
      week1PhotoURLCount + week2PhotoURLCount >= 9
        ? {
            ...challengeType?.eventEmailInlineImage?.find(
              (item) => item.imageKey === "ZERO_SUGAR_GOLD"
            ),
            name: "Gold Badge",
            dayCount: week1ResponseStatusCount + week2ResponseStatusCount,
            week: 2,
          }
        : week1ResponseStatusCount + week2ResponseStatusCount >= 12 &&
          week1PhotoURLCount + week2PhotoURLCount >= 6
        ? {
            ...challengeType?.eventEmailInlineImage?.find(
              (item) => item.imageKey === "ZERO_SUGAR_SILVER"
            ),
            name: "Silver Badge",
            dayCount: week1ResponseStatusCount + week2ResponseStatusCount,
            week: 2,
          }
        : week1ResponseStatusCount + week2ResponseStatusCount >= 12 &&
          week1PhotoURLCount + week2PhotoURLCount >= 3
        ? {
            ...challengeType?.eventEmailInlineImage?.find(
              (item) => item.imageKey === "ZERO_SUGAR_BRONZE"
            ),
            name: "Bronze Badge",
            dayCount: week1ResponseStatusCount + week2ResponseStatusCount,
            week: 2,
          }
        : null;

    // week 3
    const week3Medal =
      week1ResponseStatusCount +
        week2ResponseStatusCount +
        week3ResponseStatusCount >=
        19 && week1PhotoURLCount + week2PhotoURLCount + week3PhotoURLCount > 0
        ? {
            ...challengeType?.eventEmailInlineImage?.find(
              (item) => item.imageKey === "ZERO_SUGAR_SUSTAINED"
            ),
            name: "",
            dayCount:
              week1ResponseStatusCount +
              week2ResponseStatusCount +
              week3ResponseStatusCount,
            week: 3,
          }
        : null;

    setShowMedal([week1Medal, week2Medal, week3Medal]);
  };

  const getWeeklyProgressOfYoga = (sortedData) => {
    // Images mapped for each day
    const images = [day1, day2, day3, day4, day5, day6, day7, day14, day21];

    const week1 = [];
    const week2 = [];
    const week3 = [];
    sortedData.forEach((item, index) => {
      const dayIndex = index % 7; // Day of the week (0–6)
      const weekNumber = Math.floor(index / 7); // Week number (0–2)

      let image;
      if (dayIndex < 6) {
        image = images[dayIndex];
      } else if (index === 6) {
        image = images[6]; // Day 7
      } else if (index === 13) {
        image = images[7]; // Day 14
      } else if (index === 20) {
        image = images[8]; // Day 21
      }

      const dayObject = item.isChallengePerformed
        ? { ...item, image, dayIndex }
        : { ...item };

      // Add the day to the corresponding week
      if (weekNumber === 0 && dayObject) week1.push(dayObject);
      if (weekNumber === 1 && dayObject) week2.push(dayObject);
      if (weekNumber === 2 && dayObject) week3.push(dayObject);
    });

    setShowPetals([week1, week2, week3]);
  };

  const getDialogComponent = () => {
    return (
      <div id="image-component-responseScreen" style={{ textAlign: "center" }}>
        <img
          id="banner"
          className={classes.media}
          src={API_URL?.replace(/\/$/, "") + openDialogImage?.imagePath}
          height={200}
          alt={"Banner"}
          style={{ borderRadius: "20px" }}
          loading="lazy"
        />

        {openDialogImage?.week === 3 ? (
          <Typography style={{ color: "#27272a" }}>
            Congratulations,{" "}
            {runnerData?.firstName || runnerData?.runner?.firstName} for
            sustaining{" "}
            {perDayViewData?.programChallengeType &&
            perDayViewData?.programChallengeType === "ZERO_SUGAR"
              ? "Zero Sugar Challenge"
              : "Hydration Challenge"}
            .
          </Typography>
        ) : (
          <Typography style={{ color: "#27272a" }}>
            Congratulations, you have received{" "}
            <span style={{ color: "#18181b", fontWeight: "600" }}>
              {openDialogImage?.name}
            </span>{" "}
            for sustaining{" "}
            {perDayViewData?.programChallengeType &&
            perDayViewData?.programChallengeType === "ZERO_SUGAR"
              ? "Zero Sugar Challenge"
              : "Hydration Challenge"}{" "}
            for{" "}
            <span style={{ color: "#18181b", fontWeight: "600" }}>
              {openDialogImage?.dayCount}
            </span>{" "}
            out of{" "}
            {openDialogImage?.week === 3
              ? 21
              : openDialogImage?.week === 2
              ? 14
              : 7}{" "}
            days this week.
          </Typography>
        )}
      </div>
    );
  };

  const closeWindow = () => {
    setShowDialog(false);
  };

  const shareImage = async () => {
    // Set loading to true before starting the sharing process

    const node = document.getElementById("image-component-responseScreen");

    // making clone for the sharing to social media
    const clonedNode = node.cloneNode(true); // Create a deep clone of the element

    const paragraphElement = clonedNode.querySelector("p");

    // Update the text inside the cloned <p> element
    if (openDialogImage?.week === 3) {
      paragraphElement.textContent = `Congratulations, ${
        runnerData?.firstName || runnerData?.runner?.firstName
      } for sustaining the challenge!`;
    } else {
      paragraphElement.textContent = `Congratulations, ${
        runnerData?.firstName || runnerData?.runner?.firstName
      } you have earned ${openDialogImage?.name}!`;
    }
    clonedNode.style.position = "fixed";
    clonedNode.style.top = "50%";
    clonedNode.style.left = "50%";
    paragraphElement.style.fontSize = "11px";
    paragraphElement.style.fontWeight = "600";
    clonedNode.style.transform = "translate(-50%, -50%)";
    clonedNode.style.zIndex = "1000";
    clonedNode.style.width = "250px"; // adjust the width and height as needed
    clonedNode.style.height = "250px";
    // creating new element in dom
    document.body.appendChild(clonedNode);

    html2canvas(clonedNode, { useCORS: true, scale: 2 })
      .then((canvas) => {
        // deleting the created element in the dom
        document.body.removeChild(clonedNode);
        canvas.toBlob((blob) => {
          const file = new File([blob], "screenshot.png", {
            type: "image/png",
            lastModified: new Date().getTime(),
          });

          const shareData = {
            files: [file],
            title: "Check this out!",
            // url: "https://example.com",
            text: "Join the challenge now!",
          };

          try {
            navigator.share(shareData);
          } catch (error) {
            console.error("Error sharing:", error);
          }
        });
      })
      .catch((error) => {
        console.error("Error generating canvas:", error);
      });
  };

  useEffect(() => {
    if (productDetails?.id) {
      setResponsiveCarousel((prev) => ({
        ...prev,
        desktop: {
          ...prev.desktop,
          items: 3,
          slidesToSlide: 3,
        },
        tablet: {
          ...prev.tablet,
          items: 2,
          slidesToSlide: 2,
        },
      }));
    } else {
      setResponsiveCarousel((prev) => ({
        ...prev,
        desktop: {
          ...prev.desktop,
          items: 4,
          slidesToSlide: 3,
        },
        tablet: {
          ...prev.tablet,
          items: 3,
          slidesToSlide: 2,
        },
      }));
    }
  }, [productDetails]);

  return (
    <>
      <Carousel
        responsive={responsiveCarousel}
        centerMode={true}
        // draggable={true}
        onClickItem={handleClick}
        className={classes.thumbswrapper}
        // showStatus={false}
        initialSlide={selectedItem}
        rewind
        // arrows={true}
        // infinite={true}
        ref={carouselRef}
      >
        {QuestionData?.length > 0 &&
          QuestionData?.sort((a, b) =>
            moment(
              a.programChallengeQuestionDto?.viewDate,
              "DD-MM-YYYY HH:mm:ss"
            ).isBefore(
              moment(
                b.programChallengeQuestionDto?.viewDate,
                "DD-MM-YYYY HH:mm:ss"
              )
            )
              ? -1
              : 1
          )?.map((item, index) => {
            let date = moment(
              item?.programChallengeQuestionDto?.viewDate,
              "DD-MM-YYYY HH:mm:ss"
            ).startOf();

            let isPerformed = item?.isChallengePerformed;

            let isAllowToSubmitResponse = moment().isSameOrBefore(
              ResponseSubmissionEndDate
            );
            let programChallengeType = item?.programChallengeType;
            let PositiveResponse = item.responseStatus === "Positive";
            let isLastDayOfWeek = (index + 1) % 7 === 0;

            return (
              <div
                className={`${classes.cardElement} ${
                  isPerformed && PositiveResponse
                    ? "performedWithResponse"
                    : isPerformed && !PositiveResponse
                    ? "PerformedNegative"
                    : "default-case"
                } `}
                key={item?.id}
                style={{ margin: "5px" }}
              >
                <Card style={{ padding: "10px" }}>
                  <div className={classes.dayAndMedalContainer}>
                    <Typography className={classes.DayHeading}>
                      {" "}
                      Day {date.diff(startDate, "days") + 1}{" "}
                    </Typography>

                    {programChallengeType === "YOGA" ? (
                      <div className={classes.petalContainer}>
                        {showPetals[Math.floor(index / 7)]?.[index % 7]
                          ?.image ? (
                          <img
                            id="petals"
                            className={classes.media}
                            src={
                              showPetals[Math.floor(index / 7)]?.[index % 7]
                                ?.image
                            }
                            height={50}
                            alt="Petal Image"
                            style={{ borderRadius: "20px" }}
                          />
                        ) : null}
                      </div>
                    ) : (
                      isLastDayOfWeek &&
                      showMedal[Math.floor(index / 7)] && (
                        <div className={classes.medalContainer}>
                          <img
                            id="medal"
                            className={classes.media}
                            src={
                              API_URL?.replace(/\/$/, "") +
                              showMedal[Math.floor(index / 7)]?.imagePath
                            }
                            height={50}
                            alt="Medal"
                            style={{ borderRadius: "20px" }}
                            onClick={() => {
                              setOpenDialogImage(
                                showMedal[Math.floor(index / 7)]
                              );
                              setShowDialog(true);
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>

                  {/* <Tooltip title={item.question} enterTouchDelay={0}>
                  <Typography className={classes.DayQuestion}>
                    {item.question}
                  </Typography>
                </Tooltip> */}

                  <div className={classes.flagAndStatusContainer}>
                    {isPerformed && PositiveResponse ? (
                      <ThumbUp style={{ color: "#fbbf24", fontSize: "20px" }} />
                    ) : isPerformed && !PositiveResponse ? (
                      <ThumbDown
                        style={{ color: "#f87171", fontSize: "20px" }}
                      />
                    ) : (
                      <ErrorOutline
                        style={{ color: "#9ca3af", fontSize: "20px" }}
                      />
                    )}

                    <Tooltip
                      enterTouchDelay={0}
                      title={
                        item?.hydrationStatus?.[0]?.toUpperCase() +
                          item?.hydrationStatus?.slice(1) ||
                        (item?.programChallengeType === "YOGA" &&
                          (item?.responseStatus === "Positive"
                            ? "Made it"
                            : item?.responseStatus === "Negative"
                            ? "Tried Hard"
                            : "Not Responded")) ||
                        (item?.responseStatus === "Positive" && "Sustained") ||
                        (item?.responseStatus === "Negative" &&
                          "Not Sustained") ||
                        "Not Responded"
                      }
                    >
                      <Typography
                        className={classes.statusFlag}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.hydrationStatus ||
                          (item?.programChallengeType === "YOGA"
                            ? item?.responseStatus === "Positive"
                              ? Properties.Label_Made_It
                              : item?.responseStatus === "Negative"
                              ? Properties.Label_Tried_Hard
                              : "Not Responded"
                            : item?.responseStatus === "Positive"
                            ? "Sustained"
                            : item?.responseStatus === "Negative"
                            ? "Not Sustained"
                            : "Not Responded")}
                      </Typography>
                    </Tooltip>
                  </div>

                  {/* && item?.hydrationStatus || item?.hydrationStatus :"Not Sustained" */}

                  <SubmitCancelBtn
                    onClick={() => {
                      onClickAndFetchData(item);
                      // setSelectedItem(index);
                    }}
                    label={
                      !isAllowToSubmitResponse
                        ? "View Response"
                        : !isPerformed
                        ? "Submit Response"
                        : "View Response"
                    }
                    type="submit"
                    style={{
                      width: "fit-content",
                      fontSize: "12px",
                      borderRadius: "10px",
                      padding: "6px 0px !important",
                    }}
                  />
                </Card>
              </div>
            );
          })}
      </Carousel>
      <DialogBox
        fullWidth
        open={showDialog}
        // this props for manage header
        badgeAchieved={true}
        header={"Badge Achieved"}
        message={getDialogComponent()}
        share
        onAddPhoto={shareImage}
        onClick={() => closeWindow()}
        closeDisloag={() => closeWindow()}
      />
    </>
  );
};

// {isPerformed ? <FaCircleCheck  color="#43A047" size={20} /> : <IoMdCloseCircle color="#CC3333" size={20} />}

const styles = (theme) => ({
  cardElement: {
    // cursor: "pointer",
  },
  thumbswrapper: {
    height: "100%",

    "& .PerformedNegative": {
      borderRadius: "6px",
      padding: "3px",
      backgroundColor: "#ff7878",
      backgroundImage: "linear-gradient(315deg, #ff7878 0%, #ff0000 74%)",
    },
    "& .performedWithResponse": {
      borderRadius: "6px",
      padding: "3px",
      backgroundImage: "linear-gradient(315deg, #378b29 0%, #74d680 74%)",
    },

    "& .default-case": {
      borderRadius: "6px",
      padding: "3px",
      backgroundColor: "#d7d7d7",
      backgroundImage: "linear-gradient(147deg, #d7d7d7 0%, #353535 74%)",
    },

    "& .carousel  .slide": {
      [theme.breakpoints.down("md")]: {
        minWidth: "50% !important",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "100% !important",
      },
      textAlign: "start",
    },
  },
  highlighted: {
    // border:"1px solid red",
    borderRadius: "6px",
    padding: "1.5px",
    backgroundImage: "linear-gradient(315deg, #378b29 0%, #74d680 74%)",
  },

  statusFlag: {
    fontWeight: "600",
    // padding: "0 0 5px 0",
    color: "gray",
    fontSize: "15px",
    minidth: "100%",
    whiteSpace: "nowrap" /* Prevents text from wrapping to the next line */,
    overflow: "hidden" /* Hides the overflowed text */,
    textOverflow: "ellipsis",
  },

  flagAndStatusContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "0 0 5px 0",
  },

  DayHeading: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  dayAndMedalContainer: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },

  medalContainer: {
    position: "absolute",
    right: "-15px",
    top: "-15px",
    cursor: "pointer",
    "& img": {
      [theme.breakpoints.up("md")]: {
        height: "80px !important",
      },
    },
  },
  petalContainer: {
    position: "absolute",
    right: "-15px",
    top: "-15px",
    cursor: "pointer",
    "& img": {
      [theme.breakpoints.up("md")]: {
        height: "60px !important",
      },
    },
  },
  DayQuestion: {
    color: "gray",
    width: "100%",
    whiteSpace: "nowrap" /* Prevents text from wrapping to the next line */,
    overflow: "hidden" /* Hides the overflowed text */,
    textOverflow: "ellipsis",
    padding: "0 0 3px 0 ",
  },
  BtnView: {},
  arrowStyles: {
    // position: "absolute",
    zIndex: 2,
    top: "calc(45% - 15px)",
    backgroundColor: "white",
    width: "30px !important",
    height: "30px !important",
    fontSize: 40,
    cursor: "pointer",
    borderRadius: "50%",
    border: "2px solid grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      width: 0,
      height: 0,
      //   display: "none",
      //   top: "calc(12% - 15px)",
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(QuestionCardsCarousel);

import React, { Component } from "react";
import AppToolBar from "../../common/AppToolBar";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  TableRow,
  TableCell,
  Badge,
  Paper,
  CardActionArea,
  CardMedia,
} from "@material-ui/core";
import Properties from "./../../../properties/Properties";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import EditIcon from "@material-ui/icons/Edit";
import DialogBox from "../../common/DialogBox";
import getUserDetails from "./../../../apis/userService";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import Cropper from "react-easy-crop";
import { PhotoCamera } from "@material-ui/icons";
import EditProfilePictureContainer from "./EditProfilePictureContainer";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import garminLogo from "./garmin_logo.png";
import stravaLogo from "./strava_logo.png";
import {
  GarminLogoIcon,
  StravaLogoIcon,
  MapMyRun,
  FitBitIcon,
  GoogleFitIcon,
  GoogleFitText,
} from "../../common/SvgIcon";
import Header from "../../common/Header";
import { clearConsent } from "../../../apis/eventService";
import OrganizerCommonHeader from "../support/organizer/OrganizerCommonHeader";
import Snackbar from "../../common/Snackbar";
import { withRouter } from "react-router-dom";
import httpService from "../../../apis/httpService";

const styles = (theme) => ({
  mainPanel: {
    marginTop: "60px",
  },
  avatarSize: {
    // height:"auto",
    // width:"auto"
    width: "77px !important",
    height: "77px !important",
  },
  cropImageDialog: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  cropContainer: {
    position: "absolute",
    top: 10,
    left: 10,
    right: 10,
    bottom: "80px",
  },
  controls: {
    position: "relative",
    bottom: 0,
    left: "50%",
    width: "50%",
    transform: "translateX(-50%)",
    height: "80px",
    display: "flex",
    alignItems: "center",
  },
  slider: {
    padding: "22px 0px",
  },
  bmiContainer:{
    width:'150px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between'
  }
});

class ProfileContainer extends Component {
  state = {
    userDetails: {},
    userService: [],
    eventType: null,
    staticDataList: null,
    selectedStaticData: "",
    eventTypeList: [
      { label: "Run", value: "RUN" },
      { label: "Cycle", value: "CYCLE" },
    ],
    runnerStats: [],
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
    open: false,
    dailogMsg: "",
    profilePicture: null,
    userServiceLogo: null,
    clearConsentDialog: false,
    consentLink: localStorage.getItem("consentLink"),
    // nativeView: false,
  };

  componentDidMount() {
    document.title = Properties.Page_Title_Profile;
    // if (ApplicationUtil.ReactNative) {
    //   this.setState({ nativeView: true });
    // }
    const {history} = this.props


    let supportData = ApplicationUtil.getSupportDetails();
    let supportUserId = supportData?.id;
    let supportOrganizer_token = supportData?.token;

    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerUserId = runnerData?.id;
    let runner_token = runnerData?.token;

    let token =
      this.props.location?.state?.view === "organizer"
        ? supportOrganizer_token
        : runner_token;
    let userId =
      this.props.location?.state?.view === "organizer"
        ? supportUserId
        : runnerUserId;

    if (!ApplicationUtil.isuserLoggedIn(this.props?.location?.state?.view)) {
      this.props.location?.state?.view === "organizer"
        ? this.props.history.push("/organizer")
        : this.props.history.push("/");
    } else {

      //details
      let navigateToCalender = localStorage.getItem('navigateToCalender')
      if(navigateToCalender) {
        localStorage.removeItem("navigateToCalender");
        history.push('/calender')
        return
      }
      getUserDetails(token, userId).then((res) => {
        this.setState({
          userDetails: res.data.user,
          runnerStats: res.data.runnerStats,
        });
        if (
          res.data &&
          res.data.subscribedServices &&
          res.data.subscribedServices.length > 0
        ) {
          this.setState({
            userService: res.data.subscribedServices,
          });
        }

        if (res.data?.profilePictureLink)
          this.setState({ profilePicture: res.data.profilePictureLink.includes('http') ? `${res.data.profilePictureLink}` : `${httpService.API_URL()}${res.data.profilePictureLink}`  });
      });
    }
    window.addEventListener("popstate", this.handleBackButton);
    
  }
  // componentWillUnmount() {
  //   // window.removeEventListener("load", this.handleLoad);
  //   window.removeEventListener("popstate", this.handleBackButton);
  // }

  handleBackButton = (e) => {
    // for the purpose of preventing navigation to strava consent
      this.props.history.push("/");
    
   
  }
  clearConsentData = () => {
    const { userDetails, userService } = this.state;
    let requestParam = {
      userId: userDetails.id,
      serviceId: userService[0].id,
    };

    clearConsent(requestParam).then((res) => {

      this.setState({ clearConsentDialog: true });
    });
  };
  closeWindow = () => {
    this.setState({ clearConsentDialog: false });
    window.location.reload(true);
  };
  multipleUpload = (e) => {
    if (e.target.id == "contained-button-file") {
      let files = e.target.files;
      if (
        Array.from(e.target.files)?.every(
          (file) => file?.type === "image/png" || file?.type === "image/jpeg"
        )
      ) {
        this.setState(
          {
            uploadedFiles: Array.from(files).map((file) => {
              let key = "uploadedId_" + parseInt(Math.random() * 1000000);
              file.key = key;
              file.id = key;
              file.src = URL.createObjectURL(file);
              return file;
            }),
          },
          () => {
            this.getCropImagePallete();

            if (this.state.uploadedFiles[0].src)
              this.props.history.push({
                pathname: "/editProfilePicture",
                imgSrc: this.state.uploadedFiles[0].src,
                state: { view: this.props.location?.state?.view },
              });
          }
        );
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select JPEG or PNG file",
        });
      }
    }
    e.target.value = null;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };
  // onCropComplete = (croppedArea, croppedAreaPixels) => {
  //   console.log(croppedArea, croppedAreaPixels);
  // };
  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  getCropImagePallete = () => {
    return (
      <div className="cropImageDialog">
        <Card>
          <CardMedia>
            <div className="cropContainer">
              <Cropper
                image={this.state.uploadedFiles[0].src}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
              />
            </div>
          </CardMedia>
          <CardContent>
            <div className="controls">
              <Slider
                value={this.state.zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => this.onZoomChange(zoom)}
                classes={{ container: "slider" }}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  changeToEditProfile = () => {
    this.props.history.push({
      pathname: "/editProfile",
      state: {
        view: (this.state.userDetails.role === "RUNNER" || this.state.userDetails.role === "VOLUNTEER") ? "user" : "organizer",
      },
    });
  };

  navigateToDataPermission = () => {
    this.props.history.push({
      pathname: "/consent",
      state: {
        view: (this.state.userDetails.role === "RUNNER" || this.state.userDetails.role === "VOLUNTEER") ? "user" : "organizer",
        // this.props.location?.state?.view,
      },
    });
  };
  render() {
    const { classes } = this.props;

    const {
      userDetails,
      eventType,
      eventTypeList,
      runnerStats,
      selectedStaticData,
      staticDataList,
      consentLink,
      // nativeView,
    } = this.state;

    let contact = "";

    {
      userDetails.contactNumber
        ? (contact =
            (userDetails.countryCode ? "+" + userDetails.countryCode : "") +
            " " +
            userDetails.contactNumber)
        : (contact = "");
    }
    return (
      <React.Fragment>
        {(this.state.userDetails.role === "RUNNER" || this.state.userDetails.role === "VOLUNTEER") ? (
          <AppToolBar>
            <Header label={Properties.Profile} hideProfile={true} />
          </AppToolBar>
        ) : (
          <OrganizerCommonHeader
            title={Properties.Profile}
            hideProfile={true}
            isProfile={true}
            backNavigate={() => {
              this.props.history.push({
                pathname: "/organizer/eventlist",
                state: {
                  view: this.state.role === "RUNNER" ? "user" : "organizer",
                },
              });
            }}
          />
        )}
        <div className={classes.mainPanel} id="rootMobile">
          <Grid
            style={{
              width: "auto",
              height: "auto",
              margin: "0px",
              backgroundColor: "#08456C",
            }}
          >
            <div style={{ padding: "2%", display: "flex" }}>
              <div>
                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <label htmlFor="contained-button-file">
                      {/* //<EditIcon  // */}
                      <PhotoCamera
                        style={{ color: "#F79824", cursor: "pointer" }}
                      />
                    </label>
                  }
                >
                  <Grid item xs style={{ display: "none" }}>
                    <input
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(e) => this.multipleUpload(e)}
                    />
                  </Grid>
                  <Avatar
                    alt={this.state.userDetails.firstName + "profile_pic"} //+"profile_pic"
                    // src={"data:image/png;base64," + this.state.profilePicture}
                    src={this.state.profilePicture}
                    className={classes.avatarSize}
                  >
                    {this.state.userDetails?.firstName?.substring(0,1)}
                    {/* <PhotoCamera /> */}
                  </Avatar>
                </Badge>
              </div>
              <div style={{ padding: "1% 6%" }}>
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  {userDetails.firstName ? userDetails.firstName : " "}

                  {userDetails.lastName != undefined
                    ? " " + userDetails.lastName
                    : " "}
                </div>
                <div style={{ color: "#F79824", fontSize: "14px" }}>
                  {userDetails.gender}
                </div>

                <Typography
                  style={{ color: "white", fontSize: "16px", padding: "5% 0%" }}
                >
                  <div>{userDetails.email}</div>
                  <Grid style={{ display: "flex" }} item>
                    <Grid style={{ width: "85%" }} item>
                      <div>{contact}</div>
                      <div style={{ color: "#F79824" }}>
                        {userDetails.city}{" "}
                      </div>
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      item
                    >
                      {this.state.userDetails?.disableUserUpdate !== true && <EditIcon
                        style={{ color: "#F79824", cursor: "pointer" }}
                        onClick={this.changeToEditProfile}
                      ></EditIcon> }
                     
                    </Grid>
                  </Grid>
                </Typography>
              </div>
            </div>
          </Grid>

          {(userDetails.height ||
            userDetails.weight ||
            (runnerStats && runnerStats.runPbDistance) ||
            (runnerStats && runnerStats.runPbTime)) && (
            <Grid
              style={{
                width: "auto",
                height: "auto",
                margin: "4%",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  //marginLeft: "4%"
                }}
              >
                {Properties.PersonalData_label}
              </Typography>

              {runnerStats && (
                <Card>
                  <CardContent>
                    {(runnerStats.runPbDistance ||
                      runnerStats.runPbTime ||
                      runnerStats.cyclePbDistance ||
                      runnerStats.cyclePbTime) && (
                      <Typography>
                        {Properties.personal_best_title_label}
                      </Typography>
                    )}
                    <Grid container>
                      {(runnerStats && runnerStats.runPbDistance) ||
                      (runnerStats && runnerStats.runPbTime) ? (
                        <Grid xs={6}>
                          <Typography>{Properties.run_label}</Typography>

                          <Typography>
                            <Grid
                              style={{ display: "flex", width: "100%" }}
                              item
                            >
                              <Typography
                                style={{ width: "10%" }}
                                variant="body2"
                                gutterBottom
                              >
                                {runnerStats.runPbDistance && (
                                  <div>
                                    <span style={{ marginRight: "30%" }}>
                                      {Properties.Distance_label}
                                    </span>

                                    <span style={{ textAlign: "end" }}>
                                      {runnerStats.runPbDistance}
                                    </span>
                                  </div>
                                )}
                                {runnerStats.runPbTime && (
                                  <div>
                                    <span style={{ marginRight: "28%" }}>
                                      {Properties.Time_label}
                                    </span>

                                    <span style={{ textAlign: "end" }}>
                                      {runnerStats.runPbTime}
                                    </span>
                                  </div>
                                )}
                              </Typography>
                            </Grid>
                          </Typography>
                        </Grid>
                      ) : (
                        <span></span>
                      )}
                      {(runnerStats && runnerStats.cyclePbDistance) ||
                      (runnerStats && runnerStats.cyclePbTime) ? (
                        <Grid xs={6}>
                          <Typography>{Properties.cycle_label}</Typography>

                          <Typography>
                            <Grid
                              style={{ display: "flex", width: "100%" }}
                              item
                            >
                              <Typography
                                style={{ width: "10%" }}
                                variant="body2"
                                gutterBottom
                              >
                                {runnerStats.cyclePbDistance && (
                                  <div>
                                    <span style={{ marginRight: "30%" }}>
                                      {Properties.Distance_label}
                                    </span>

                                    <span style={{ textAlign: "end" }}>
                                      {runnerStats.cyclePbDistance}
                                    </span>
                                  </div>
                                )}
                                {runnerStats.cyclePbTime && (
                                  <div>
                                    <span style={{ marginRight: "28%" }}>
                                      {Properties.Time_label}
                                    </span>

                                    <span style={{ textAlign: "end" }}>
                                      {runnerStats.cyclePbTime}
                                    </span>
                                  </div>
                                )}
                              </Typography>
                            </Grid>
                          </Typography>
                        </Grid>
                      ) : (
                        <span></span>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              )}
              <Grid>
                {userDetails.height || userDetails.weight || userDetails.bloodGroup ? (
                  <Card style={{ margin: "10px 0px 0px 0px" }}>
                    <CardContent>
                      <Typography>
                        <Grid style={{ display: "flex", width: "100%" }} item>
                          <Typography
                            style={{ width: "10%" }}
                            variant="body2"
                            gutterBottom
                          >
                            {userDetails.height && (
                              <div className={classes.bmiContainer}>
                                <span>
                                  Height
                                </span>

                                <span>
                                  {userDetails.height}
                                </span>
                              </div>
                            )}
                            {userDetails.weight && (
                              <div className={classes.bmiContainer}>
                                <span>
                                  Weight
                                </span>

                                <span>
                                  {userDetails.weight}
                                </span>
                              </div>
                            )}
                             {userDetails.bmi && (
                              <div className={classes.bmiContainer}>
                                <span>
                                  BMI
                                </span>

                                <span>
                                  {userDetails.bmi}
                                </span>
                              </div>
                            )}
                             {userDetails.bloodGroup && (
                              <div className={classes.bmiContainer}>
                                <span>
                                {Properties.Blood_Group_Label}
                                </span>

                                <span>
                                  {userDetails.bloodGroup}
                                </span>
                              </div>
                            )}
                          </Typography>
                        </Grid>
                      </Typography>
                    </CardContent>
                  </Card>
                ) : (
                  <span></span>
                )}
              </Grid>
            </Grid>
          )}

          <Grid
            style={{
              width: "auto",
              height: "auto",
              margin: "4%",
              // backgroundColor: "pink",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                //marginLeft: "4%"
              }}
            >
              {Properties.ActivitySync_label}
            </Typography>
            <Typography>{Properties.ActivitySync_Instruction}</Typography>
            <Card>
              <CardContent style={{ padding: "10px" }}>
                {this.state.userService.length > 0 ? (
                  <>
                    {this.state.userService.map((userService, index) => (
                      <Typography>
                        {userService?.name && (
                          <Grid
                            key={index}
                            style={{
                              width: "100%",
                              margin: "0px",
                            }}
                            container
                            spacing={1}
                          >
                            <Grid item xs={6} style={{ padding: "0px" }}>
                              {/* <div className={classes.imageCont}> */}
                              {/* <img
                                  src={this.state.userServiceLogo} 
                                  alt="logo"
                                  style={{
                                    height: "40px",
                                    maxHeight: "40px",
                                    minHeight: "40px",
                                    width: "220px",
                                    maxWidth: "220px",
                                    minWidth: "220px",
                                    position: "relative",
                                  }}
                                /> */}
                              {userService.name == "STRAVA" ? (
                                <StravaLogoIcon style={{ width: "100px" }} />
                              ) : userService.name == "GARMIN" ? (
                                <GarminLogoIcon style={{ width: "100px" }} />
                              ) : userService.name == "MAPMYRUN" ? (
                                <MapMyRun
                                  style={{ width: "180px", marginTop: "10px" }}
                                />
                              ) : userService.name == "FITBIT" ? (
                                <FitBitIcon style={{ width: "100px" }} />
                              ) : userService.name == "GOOGLEFIT" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <GoogleFitIcon />
                                  <GoogleFitText
                                    style={{ width: "80px", marginLeft: "5px" }}
                                  />
                                  <span>FIT</span>
                                </div>
                              ) : (
                                <></>
                              )}

                              {/* </div> */}
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                padding: "0px",
                                display: "flex",
                                margin: "auto",
                                justifyContent: "center",
                              }}
                            >
                              <SubmitCancelBtn
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: "/consent",
                                    // selectedType: userService.type,
                                    state: {
                                      view: this.props.location?.state?.view,
                                    },
                                  });
                                }}
                                label={Properties.Label_Change}
                                type="submit"
                                style={{ width: "auto" }}
                              />
                              <SubmitCancelBtn
                                onClick={this.clearConsentData}
                                label={"Clear"}
                                type="submit"
                                style={{ width: "auto", marginLeft: "10px" }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Typography>
                    ))}
                  </>
                ) : (
                  <Grid
                    style={{
                      width: "100%",
                      height: "35px",
                      marginTop: "2%",
                    }}
                    container
                    spacing={1}
                    alignItems="center"
                    justify="center"
                  >
                    <SubmitCancelBtn
                      onClick={this.navigateToDataPermission}
                      label={Properties.Label_Profile_AddAppOrDevice}
                      type="submit"
                      style={{ width: "auto" }}
                    />
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </div>
        <DialogBox
          fullScreen={true}
          open={this.state.open}
          message={this.state.dailogMsg}
          header={this.state.dialogHeader}
          onClick={() => {
            this.state.signupCondition ? this.navigate() : this.navigate2();
          }}
          onClickTwo={() => {
            this.showInvite();
          }}
          labelTwo={"Invite People"}
          closeDisloag={null} //{() => this.closeWindow()}
        />
        <DialogBox
          open={this.state.clearConsentDialog}
          message={"Consent Removed."}
          onClick={() => this.closeWindow()}
          closeDisloag={() => this.closeWindow()}
        />

        {/* {this.state.uploadedFiles[0] && (
          <EditProfilePictureContainer img={this.state.uploadedFiles[0].src} />
        )} */}
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ProfileContainer);

import React, { Component, createRef } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Paper, Switch } from "@material-ui/core";
import banner from "./banner.jpg";
import Searchbar from "./Searchbar";
import Leaderboard from "./Leaderboard/Leaderboard";
import { withRouter } from "react-router-dom";
import Properties from "./../../../../../properties/Properties";
import {
  getAllSlotData,
  getEventDetailsWithDistkey,
  getMapData,
  getMapRoute,
  getViewResults,
} from "./../../../../../apis/eventService";
import Results from "./Results";
import Header from "../../../../common/Header";
import AppToolBar from "../../../../common/AppToolBar";
import moment from "moment";
import RelayLeaderboard from "./Relay/RelayLeaderboard";
import BannerTemp from "../../../../../images/4.png";
import RouteMap from "./RouteMap/RouteMap";
import RunnerMap from "./RouteMap/RunnerMap";
import EventMap from "./RouteMap/EventMap";
import Spinner from "../../../../common/Spinner";
import { HomeIcon } from "../../../../common/SvgIcon";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { connect } from "react-redux";
import {
  updateUserSelectedEvent,
  updateUserPreferences,
} from "../../../../../redux/user";
import {
  getAllParticipantsDetails,
  getIndividualDetails,
} from "../../../../../apis/mapService";
import ListView from "./RouteMap/ListView";
import TripleToggleSwitch from "../../../../common/TripleToggleButton";
import RelayTable from "./Relay/RelayTable";
import httpService from "../../../../../apis/httpService";
import RunnerSideBreadcrumbs from "../../../../common/RunnerSideBreadcrumbs";
const API_URL = httpService.API_URL();
const styles = (theme) => ({
  root: {
    marginTop: "68px",
  },
  viewresults: {
    // overflow: "hidden",
    height: "100vh",
  },
  eventName_heading: {
    textAlign: "center",
    color: "rgb(40, 131, 181)",
    fontWeight: 600,
    fontSize: "150%",
  },
  event_banner: {
    marginBottom: "10px",
    padding: "0px 4px",
    // marginLeft: "4px",
    // marginRight: "4px",
    // width: "100%",
    // height: window.innerWidth > 600 ? "75%" : "auto",
    overflow: "hidden",
    "& .banner_img": {
      width: "100%",
      // height: "100%",
      // maxHeight: "100%",
      // display: "block",
      margin: "auto",

      // marginTop: 0,
      // objectFit: "cover",
      // top: 0,
      // zIndex: -1,
      // maskImage: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
    },
  },
  mapContainer: {
    width: "95%",
    margin: "auto",
    paddingBottom: "10px",
    position: "relative",
  },

  viewMapLabel: {
    color: "rgb(40, 131, 181)",
    textAlign: "center",
    marginTop: "20px",
  },
  noMap: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
  },
  loadingContainer: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
  },
  publicHeaderLayout: {
    display: "flex",
  },
  homeIcon: {
    margin: "10px",
    padding: "6px 6px 6px 8px",
    height: "30px",
    width: "30px",
    backgroundColor: "#08456c",
    cursor: "pointer",
  },
  eventName: {
    margin: "0 auto",
  },
  trackerMap_LabelLayout: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px",
  },
  trackerMap_Label: {
    fontSize: "24px",
    color: "#727272",
    fontWeight: 600,
  },
  trackerMap_Toggle: {
    fontSize: "18px",
    color: "#727272",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  trackerMap_Individual: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: "20px",
    width: "100%",
  },
  trackerMap_RunnerName: {
    marginRight: "20px",
    marginLeft: "20px",
    fontSize: "20px",
    color: "#727272",
    fontWeight: 600,
  },
  trackerMap_SeeAllRunners: {
    cursor: "pointer",
    marginRight: "20px",
    marginLeft: "20px",
    fontSize: "20px",
    color: "blue",
    fontWeight: 600,
  },
  relayMapActionLayout: {
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    padding: "10px 20px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
      // marginBottom:'40px',

      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  relayMapLabel: {
    fontSize: "24px",
    color: "#727272",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "50px",
    },
  },
  relayParticipantsMap: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
    },
  },
});
const initialState = {
  eventId: null,
  eventName: "",
  eventResData: null,
  defaultCenter: null,
  counter: 0,
  firstLoad: true,
  listView: false,
  isAllParticipants: true,
  relayMapToggleValue: "map",
  scrollToMap: false,
  features: [],
};
class ViewResults extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    // this.scrollToMap
  }

  componentDidMount() {
    this.initialLoad();
  }

  initialLoad = () => {
    const { history, organizerEventID } = this.props;
    if (organizerEventID) {
      let eventName = localStorage.getItem("eventName");
      this.setState({ eventId: organizerEventID, eventName: eventName });
    } else {
      let search = window.location.href;
      let distKey = search.split("distKey=")[1];

      let eventData = ApplicationUtil.getRunnersEventsDetails();
      if (distKey === undefined) {
        distKey = eventData?.distKey;
      }
      let resultData = null;
      let runnerData = ApplicationUtil.getRunnerDetails();
      let runnerId = runnerData?.runner?.id;
      let eventId = eventData?.id;
      let eventName = eventData?.name;
      let hideSearchBar = localStorage.getItem("navigatedFromEventDetails");

      if (eventId || distKey) {
        this.setState({
          eventId: eventId,
          eventResData: eventData,
          eventName: eventName,
          hideSearchBar: hideSearchBar,
        });
      } else {
        history.push("/completeEventList");
      }

      let requestParams = {
        eventId: eventId,
        runnerId,
      };

      if (requestParams.runnerId) {
        getViewResults(requestParams).then((response) => {
          if (response.data) {
            resultData = response.data;
            this.setState({
              results: resultData,
            });
          }
        });
      }
      localStorage.removeItem("scrollToMap");
      getEventDetailsWithDistkey(distKey)
        .then((res) => {
          if (res.status === 200) {
            // document.title = `Results - ${res?.data?.name}`;
            document.title = res?.data?.name;
            let eventData = res.data;
            
            eventData.distKey = distKey;
            this.props.updateUserSelectedEvent(eventData);
            this.setState({
              runnerId: runnerId,
              eventId: eventData.id,
              eventName: eventData.name,
              runnerName: `${runnerData?.firstName} ${
                runnerData?.lastName ? runnerData?.lastName : ""
              }`,
              eventResData: eventData,
              distKey: distKey,
              eventDate: moment(
                eventData?.eventLocalStartDate,
                "YYYY-MM-DD"
              ).format("DD-MM-YYYY"),
              eventEnd: moment(
                eventData?.eventLocalEndDate,
                "YYYY-MM-DD"
              ).format("DD-MM-YYYY"),
              mapType: hideSearchBar ? "RUNNER" : "CATEGORY",
              showRunnerMap: hideSearchBar === "true" ? true : false,
              isAllParticipants: ApplicationUtil.isuserLoggedIn()
                ? false
                : true,
              relayMapToggleValue: search?.includes("#participants")
                ? "participants"
                : "map",
            });

            if (res.data?.mapGenerated) {
              if (res.data?.mapToShow === "TRACKER") {
                this.handleAllParticipants(res.data.id);
              }
              if (res.data?.mapToShow === "DEFAULT") {
                this.getSearchedMapResults();
              }
              if (res.data?.mapToShow === "RELAY") {
                this.getSearchedMapResults();
                let isLive = moment().isAfter(eventData?.eventLocalEndDate);
                let eventDateTime = moment(
                  eventData?.eventDate + " " + eventData?.eventTime
                );
                let current = moment();
                let timeRemained = eventDateTime.diff(current);
                // if(!search?.includes('#participants'))
                this.setState({ relayLoading: true });
                getMapData(eventData?.id)
                  .then((relayRes) => {
                    this.setState({
                      relayMapData: {
                        live: isLive,
                        flightPlanCoordinates: relayRes?.data
                          ?.coOrdinatePointsJson
                          ? JSON.parse(relayRes?.data?.coOrdinatePointsJson)
                          : null,
                        center: relayRes?.data?.centerPoint?.coOrdinatePoint
                          ? relayRes?.data?.centerPoint?.coOrdinatePoint
                          : null,
                        defaultCenter:
                          relayRes?.data?.centerPoint?.coOrdinatePoint,
                        features: relayRes?.data?.markerPointDTOs
                          ? relayRes?.data?.markerPointDTOs
                          : null,
                        timeRemained: timeRemained,
                      },
                      relayLoading: false,
                    });
                  })
                  .catch(() => {
                    this.setState({ relayLoading: false });
                  });
                getAllSlotData(eventData?.id).then((slotRes) => {
                  this.setState({ slotData: slotRes?.data });
                });
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            history.push("/completeEventList");
          }
        });
    }
  };
  getEventLevelMap = (type, loadMap) => {
    const {
      eventResData,
      firstLoad,
      hideSearchBar,
      runnerId,
      eventId,
      isAllParticipants,
    } = this.state;

    if (eventResData?.mapGenerated && (firstLoad || loadMap)) {
      if (eventResData?.mapToShow === "TRACKER") {
        this.setState({ firstLoad: false });
        this.handleAllParticipants();
      } else {
        let requestParams = {
          eventId: this.state.eventId,
        };

        // if (hideSearchBar === "true") {
        //   requestParams.runnerId = runnerId;
        // }

        getMapRoute(requestParams).then((res) => {
          if (res?.data?.runnerRoutePositionDetails) {
            this.setState({
              flightPlanCoordinates: res?.data?.runnerRoutePositionDetails
                ? res?.data?.runnerRoutePositionDetails
                : null,

              features: res?.data?.runnerRoutePositionDetails,
              categoryDTOs: res?.data?.categoryDTOs
                ? res?.data?.categoryDTOs
                : null,

              //for runner/category level map
              categoryDTO: res?.data?.categoryDTO,
              mapType: type,
              zoomLevel: res.data?.zoomLevel >= 0 ? res.data?.zoomLevel : null,
              defaultCenter: res.data?.startPoint ? res.data?.startPoint : null,
              counter:
                // this.state.counter === 0 ? 0 :
                this.state.counter + 1,
              firstLoad: false,
              loading: false,
            });
          }
        });
      }
    }
  };
  getSearchedMapResults = (type, id) => {
    const {
      eventResData,
      showRunnerMap,
      counter,
      hideSearchBar,
      firstLoad,
      eventId,
      runnerId,
      isAllParticipants,
    } = this.state;

    if (eventResData?.mapGenerated && !firstLoad) {
      if (eventResData?.mapToShow === "TRACKER") {
        // this.handleAllParticipants()

        return;
      }
      this.setState({ loading: true });
      let requestParams = {
        eventId: this.state.eventId,
      };
      let isTrue = counter > 0 ? true : showRunnerMap ? false : true;

      if (isTrue && type === "CATEGORY") {
        requestParams.categoryId = id;
      } else if (type === "RUNNER" && id) {
        requestParams.runnerId = id;
      } else if (type === "BIB" && id) {
        requestParams.bibNumber = id;
        this.setState({ relayMapToggleValue: "participants" });
      }
      if (
        requestParams.runnerId ||
        requestParams.bibNumber ||
        requestParams.categoryId ||
        type === "EVENT"
      ) {
        getMapRoute(requestParams).then((res) => {
          if (res?.data?.runnerRoutePositionDetails) {
            this.setState({
              flightPlanCoordinates: res?.data?.runnerRoutePositionDetails
                ? res?.data?.runnerRoutePositionDetails
                : null,

              features: res?.data?.runnerRoutePositionDetails,
              // for event level map //categoryDTOs
              categoryDTOs: res?.data?.categoryDTOs
                ? res?.data?.categoryDTOs
                : null,

              //for runner/category level map
              categoryDTO: res?.data?.categoryDTO,
              mapType: type,
              zoomLevel: res.data?.zoomLevel >= 0 ? res.data?.zoomLevel : null,
              defaultCenter: res.data?.startPoint ? res.data?.startPoint : null,
              counter:
                // this.state.counter === 0 ? 0 :
                this.state.counter + 1,
              // showRunnerMap: false,
              loading: false,
            });
          } else {
            this.setState({
              defaultCenter: null,
              loading: false,
            });
          }
        });
      }
    } else if (eventResData?.mapGenerated) {
      this.getEventLevelMap("EVENT");
    }
  };

  updateLeaderboardCategory = (activityType, catId) => {
    this.setState({
      updatedCat: {
        activityType: activityType,
        catId: catId,
      },
    });
  };
  handleAllParticipants = (paramsEventId) => {
    const { eventId, firstLoad } = this.state;
    let token = ApplicationUtil.getRunnerToken();
    let requestParam = {
      eventId: eventId || paramsEventId,
    };
    getMapRoute(requestParam).then((res) => {
      getAllParticipantsDetails(token, requestParam.eventId).then(
        (response) => {
          let object = {};
          // for (let i = 0; i < response.data?.length; i++) {
          //   let item = response.data[i];
          //   object[item.scanPointId] = object[item.scanPointId]
          //     ? {
          //         nameList: object[item.scanPointId].nameList.concat(
          //           `${item.name},`
          //         ),
          //         currentPosition: object[item.scanPointId].currentPosition,
          //         scanPointName: object[item.scanPointId].scanPointName,
          //       }
          //     : {
          //         nameList: item.name + ",",
          //         currentPosition: item.currentPosition,
          //         scanPointName: item.scanPoint,
          //       };
          // }

          this.setState({
            features: response.data.eventTimingMats.concat(
              response.data.locationTrackRecords
            ), // Object.values(object),
            featuresList: response.data?.locationTrackRecords,
            inidividualFlightPointCoordinates: null,
            listView: !firstLoad ? true : false,
            isAllParticipants: true,
            categoryDTO: res?.data?.categoryDTO,
            loading: false,
            firstLoad: false,
            zoomLevel: res.data?.zoomLevel >= 0 ? res.data?.zoomLevel : null,
            defaultCenter: res.data?.startPoint ? res.data?.startPoint : null,
          });
        }
      );
    });
  };
  handleIndividualParticipant = (runnerId) => {
    const { eventId } = this.state;
    let token = ApplicationUtil.getRunnerToken();
    let requestParam = {
      eventId,
      runnerId,
    };
    getMapRoute(requestParam).then((res) => {
      getIndividualDetails(eventId, runnerId)
        .then((response) => {
          let polyLineData = [];
          // if(response.data?.mapDataDTO?.markerPointDTOs?.length > 0){

          //   response.data.mapDataDTO.markerPointDTOs.map((x)=>{
          //     if(x?.coOrdinatePoint){

          //       polyLineData.push(x.coOrdinatePoint)
          //     }
          //   })
          // }
          this.setState({
            runnerName: response.data?.participantLocationTrackData[0]?.name,
             listView:true,
            isAllParticipants: false,
            features: response.data?.participantLocationTrackData,
            featuresList: response.data?.participantLocationTrackData,
            inidividualFlightPointCoordinates:
              response.data?.mapDataDTO?.coOrdinatePointsJson,
            // inidividualFlightPointCoordinates:polyLineData.length > 0 ? polyLineData : null,
            categoryDTO: res?.data?.categoryDTO,
            loading: false,
            zoomLevel: res.data?.zoomLevel >= 0 ? res.data?.zoomLevel : null,
            defaultCenter: res.data?.startPoint ? res.data?.startPoint : null,
          });
        })
        .catch(() => {
          this.setState({ loading: false, features: [], featuresList: [] });
        });
    });
  };
  getSearchedParticipants = (runnerId, runnerName) => {
    if (runnerId) {
      this.setState({ runnerName, loading: true, isAllParticipants: false })
      this.handleIndividualParticipant(runnerId)
    }
  };

  componentWillUnmount() {
    localStorage.removeItem("scrollToMap");
  }
  render() {
    const { classes } = this.props;
    const {
      eventName,
      eventId,
      eventResData,
      hideSearchBar,
      results,
      eventDate,
      eventEnd,
      desc,
      distKey,
      flightPlanCoordinates,
      features,
      categoryDTOs,
      categoryDTO,
      zoomLevel,
      defaultCenter,
      counter,
      mapType,
      loading,
      listView,
      isAllParticipants,
      runnerName,
      relayMapToggleValue,
      relayMapData,
      slotData,
      scrollToMap,
      firstLoad,
      relayLoading,
      featuresList,
      inidividualFlightPointCoordinates,
    } = this.state;
    return (
      <>
        <div className={classes.viewresults}>
          {/* {hideSearchBar === "true" && ( */}
         
          <AppToolBar
            backNavigate={() => this.props.history.push("/eventDetails")} //goBack()} //
            showBack={true}
          >
            <Header
              {...this.props}
              label={`${eventName ? eventName : ""} ${
                Properties.Label_Results
              }`}
              fromDate={eventDate}
              toDate={eventDate === eventEnd ? false : eventEnd}
            />
          </AppToolBar>
          {/* )} */}
          <div className={classes.root}>
            <RunnerSideBreadcrumbs />
            {/* {hideSearchBar !== "true" && eventName && (
              <div className={classes.publicHeaderLayout}>
                <div
                  className={classes.homeIcon}
                  onClick={() => {
                    this.props.history.push("/");
                  }}
                >
                  <HomeIcon width="200px" height="200px" color="#F79824" />
                </div>
                <div className={classes.eventName}>
                  <h1 className={classes.eventName_heading}>
                    {`${eventName} ${Properties.Label_Results}`}
                  </h1>
                </div>
              </div>
            )} */}

            {eventResData && (
              <div className={classes.event_banner}>
                <img
                  src={
                    eventResData?.resultBannerImage?.url
                      ? API_URL + eventResData?.resultBannerImage?.url
                      : eventResData?.image?.url
                      ? API_URL + eventResData?.image?.url
                      : banner
                  }
                  alt="marathon_banner"
                  className="banner_img"
                />
              </div>
            )}

            {eventResData?.showSearchBarOnResultsView && eventId && (
              <Searchbar
                eventId={eventId}
                eventType={eventResData?.type}
                getSearchedMapResults={this.getSearchedMapResults}
                getEventLevelMap={this.getEventLevelMap}
                updateLeaderboardCategory={this.updateLeaderboardCategory}
                getSearchedParticipants={
                  eventResData?.mapToShow === "TRACKER"
                    ? this.getSearchedParticipants
                    : false
                } //only for TRACKER map
                showResults={eventResData?.showSearchResultsOnResultsView}
                clearResult={() => this.setState({ results: null })}
              />
            )}
            {eventResData?.showSearchResultsOnResultsView && results  && (
              <Results data={results} type={eventResData?.type} />
            )}

            {eventResData?.showLeaderboard && (
              <Leaderboard
                challengeType={eventResData}
                mapType={mapType}
                getSearchedMapResults={this.getSearchedMapResults}
                categoryDTO={this.state.updatedCat}
              />
            )}

            {eventResData?.mapGenerated && (
              <>
                {eventResData?.mapToShow === "DEFAULT" && (
                  <Paper elevation={3} className={classes.mapContainer}>
                    {(mapType === "RUNNER" || mapType === "BIB") && (
                      <h1 className={classes.viewMapLabel}>
                        {Properties.MapRouteLabel}
                      </h1>
                    )}
                    {(mapType === "CATEGORY" || mapType === "EVENT") && (
                      <h1 className={classes.viewMapLabel}>
                        {Properties.ParticipantLocationLabel}
                      </h1>
                    )}

                    {loading ? (
                      <div className={classes.loadingContainer}>
                        {/* loading... */}
                        <CircularProgress />
                      </div>
                    ) : (
                      <>
                        {(mapType === "RUNNER" || mapType === "BIB") && (
                          <div key={counter}>
                            {(flightPlanCoordinates !== undefined ||
                              features !== undefined) &&
                            defaultCenter !== null ? (
                              <div className={classes.map}>
                                <RunnerMap
                                  flightPlanCoordinates={
                                    flightPlanCoordinates &&
                                    flightPlanCoordinates?.length > 0 &&
                                    flightPlanCoordinates[0]?.routeJSON
                                      ? JSON.parse(
                                          flightPlanCoordinates[0].routeJSON
                                        )
                                      : null
                                  }
                                  categoryDTO={categoryDTO}
                                  features={features}
                                  defaultCenter={
                                    defaultCenter && JSON.parse(defaultCenter)
                                  }
                                  zoomLevel={zoomLevel}
                                  mapToShow={eventResData?.mapToShow}
                                  activity={
                                    flightPlanCoordinates &&
                                    flightPlanCoordinates[0].runnerActivityDTO
                                  }
                                />
                              </div>
                            ) : (
                              <div className={classes.noMap}>
                                {Properties.MapNotAvailable}
                              </div>
                            )}
                          </div>
                        )}

                        {mapType === "CATEGORY" && (
                          <div key={counter}>
                            {
                              // (flightPlanCoordinates !== undefined ||
                              // features !== undefined) &&
                              defaultCenter !== null ? (
                                <div className={classes.map}>
                                  <RouteMap
                                    flightPlanCoordinates={
                                      categoryDTOs?.routeCoordinates
                                        ? JSON.parse(
                                            categoryDTOs.routeCoordinates
                                          )
                                        : null
                                    }
                                    categoryDTO={categoryDTO}
                                    features={features}
                                    defaultCenter={
                                      defaultCenter
                                        ? JSON.parse(defaultCenter)
                                        : null
                                    }
                                    zoomLevel={zoomLevel}
                                    eventType={eventResData.type}
                                    mapToShow={eventResData?.mapToShow}
                                  />
                                </div>
                              ) : (
                                <div className={classes.noMap}>
                                  {Properties.MapNotAvailable}
                                </div>
                              )
                            }
                          </div>
                        )}

                        {mapType === "EVENT" && (
                          <div key={counter}>
                            {
                              // (flightPlanCoordinates !== undefined ||
                              // features !== undefined) &&
                              defaultCenter !== null ? (
                                <div className={classes.map}>
                                  <EventMap
                                    flightPlanCoordinates={
                                      categoryDTOs?.routeCoordinates
                                        ? JSON.parse(
                                            categoryDTOs.routeCoordinates
                                          )
                                        : null
                                    }
                                    categoryDTOs={categoryDTOs}
                                    features={features}
                                    defaultCenter={
                                      defaultCenter
                                        ? JSON.parse(defaultCenter)
                                        : null
                                    }
                                    zoomLevel={zoomLevel}
                                    eventType={eventResData.type}
                                  />
                                </div>
                              ) : (
                                <div className={classes.noMap}>
                                  {Properties.MapNotAvailable}
                                </div>
                              )
                            }
                          </div>
                        )}
                      </>
                    )}
                  </Paper>
                )}
                {eventResData?.mapToShow === "TRACKER" && (
                  <>
                    <div className={classes.trackerMap_LabelLayout}>
                      <span className={classes.trackerMap_Label}>
                        {isAllParticipants
                          ? "Runner Locations"
                          : "Track Runner"}
                      </span>
                      <span className={classes.trackerMap_Toggle}>
                        {listView ? "Map View" : "List View "}{" "}
                        <Switch
                          checked={listView}
                          onChange={() => {
                            this.setState({ listView: !listView });
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      </span>
                    </div>
                    {!isAllParticipants && (
                      <div className={classes.trackerMap_Individual}>
                        <span className={classes.trackerMap_RunnerName}>
                          {runnerName}
                        </span>
                        <span
                          className={classes.trackerMap_SeeAllRunners}
                          onClick={() => {
                            this.setState({ loading: true });
                            this.handleAllParticipants();
                          }}
                        >
                          See All Runners
                        </span>
                      </div>
                    )}
                    <div>
                      {listView ? (
                        <ListView
                          data={featuresList}
                          getSelectedParticipant={(runnerId) =>
                            this.handleIndividualParticipant(runnerId)
                          }
                          resultsView
                          isAllParticipants={isAllParticipants}
                        />
                      ) : (
                        <div>
                          {loading ? (
                            <div className={classes.loadingContainer}>
                              <CircularProgress />
                            </div>
                          ) : (
                            <>
                              {defaultCenter !== null &&
                              features?.length > 0 ? (
                                <div className={classes.map}>
                                  <RouteMap
                                    flightPlanCoordinates={
                                      inidividualFlightPointCoordinates
                                        ? JSON.parse(
                                            inidividualFlightPointCoordinates
                                          )
                                        : {}
                                    }
                                    categoryDTO={categoryDTO}
                                    features={features}
                                    defaultCenter={
                                      defaultCenter
                                        ? JSON.parse(defaultCenter)
                                        : null
                                    }
                                    zoomLevel={12}
                                    isAllParticipants={isAllParticipants}
                                    eventType={eventResData.type}
                                    mapToShow={eventResData?.mapToShow}
                                  />
                                </div>
                              ) : (
                                <div className={classes.noMap}>
                                  {Properties.MapNotAvailable}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {eventResData?.mapToShow === "RELAY" && (
                  <div id="map">
                    <Paper elevation={3} className={classes.mapContainer}>
                      <h1 className={classes.viewMapLabel}>
                        {relayMapToggleValue === "list"
                          ? "Challenge Progress"
                          : relayMapToggleValue === "participants"
                          ? `Participants ${
                              mapType === "RUNNER" || mapType === "BIB"
                                ? "Map Route"
                                : "Location"
                            }`
                          : "Map"}
                      </h1>
                      <div className={classes.relayMapActionLayout}>
                        {relayMapToggleValue === "map" && (
                          <span className={classes.relayMapLabel}>
                            {relayMapData?.live
                              ? "Progress Tracker"
                              : eventResData?.id === 507
                              ? "Ashish Kasodekar's Live Tracker"
                              : "Live Tracker"}
                          </span>
                        )}
                        <TripleToggleSwitch
                          labels={{
                            left: {
                              title: "Map",
                              value: "map",
                            },
                            center: {
                              title: "Participants",
                              value: "participants",
                            },
                            right: {
                              title: "List",
                              value: "list",
                            },
                          }}
                          onChange={(val) => {
                            this.setState({ relayMapToggleValue: val });
                            if (val === "participants") {
                              this.getEventLevelMap("EVENT");
                            }
                          }}
                          changePosition={
                            relayMapToggleValue === "participants"
                              ? "center"
                              : null
                          }
                        />
                      </div>
                      {loading || relayLoading ? (
                        <div className={classes.loadingContainer}>
                          {/* loading... */}
                          <CircularProgress />
                          <p>Loading map...</p>
                        </div>
                      ) : (
                        <>
                          {relayMapToggleValue === "map" && (
                            <RelayLeaderboard data={relayMapData} />
                          )}

                          {relayMapToggleValue === "participants" && (
                            <div>
                              {mapType === "EVENT" && (
                                <div
                                  key={counter}
                                  className={classes.relayParticipantsMap}
                                >
                                  {
                                    // (flightPlanCoordinates !== undefined ||
                                    // features !== undefined) &&
                                    defaultCenter !== null ? (
                                      <div className={classes.map}>
                                        <EventMap
                                          flightPlanCoordinates={
                                            categoryDTOs?.routeCoordinates
                                              ? JSON.parse(
                                                  categoryDTOs.routeCoordinates
                                                )
                                              : null
                                          }
                                          categoryDTOs={categoryDTOs}
                                          features={features}
                                          defaultCenter={
                                            defaultCenter
                                              ? JSON.parse(defaultCenter)
                                              : null
                                          }
                                          zoomLevel={zoomLevel}
                                          eventType={eventResData.type}
                                        />
                                      </div>
                                    ) : (
                                      <div className={classes.noMap}>
                                        {Properties.MapNotAvailable}
                                      </div>
                                    )
                                  }
                                </div>
                              )}
                              {(mapType === "RUNNER" ||
                                mapType === "BIB" ||
                                mapType === "CATEGORY") && (
                                <div key={counter}>
                                  {(flightPlanCoordinates !== undefined ||
                                    features !== undefined) &&
                                  defaultCenter !== null ? (
                                    <div className={classes.map}>
                                      <RunnerMap
                                        flightPlanCoordinates={
                                          flightPlanCoordinates &&
                                          flightPlanCoordinates?.length > 0 &&
                                          flightPlanCoordinates[0]?.routeJSON
                                            ? JSON.parse(
                                                flightPlanCoordinates[0]
                                                  .routeJSON
                                              )
                                            : null
                                        }
                                        categoryDTO={categoryDTO}
                                        features={features}
                                        defaultCenter={
                                          defaultCenter
                                            ? JSON.parse(defaultCenter)
                                            : null
                                        }
                                        zoomLevel={zoomLevel}
                                        mapToShow={eventResData?.mapToShow}
                                        activity={
                                          flightPlanCoordinates &&
                                          flightPlanCoordinates.length > 0
                                            ? flightPlanCoordinates[0]
                                                .runnerActivityDTO
                                            : null
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div className={classes.noMap}>
                                      {Properties.MapNotAvailable}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          {relayMapToggleValue === "list" && (
                            <div className={classes.relayParticipantsMap}>
                              <RelayTable data={slotData} />
                            </div>
                          )}
                        </>
                      )}
                    </Paper>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    userSelectedEvent: state.user.userSelectedEvent,
    updateUserPreferences: state.user.updateUserPreferences,
  };
}
export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    updateUserSelectedEvent,
    updateUserPreferences,
  })
)(ViewResults);

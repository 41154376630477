import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Chip,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { isBrowser } from "react-device-detect";
import DialogBox from "../../../../../../common/DialogBox";

import MultiSelect from "../../../../../../common/MultiSelect";
import { CustomTextField } from "../../../../../../common/CustomTextField";
import SubmitCancelBtn from "../../../../../../common/SubmitCancelBtn";
import ApplicationConstants from "../../../../../../../constants/ApplicationConstants";
import Properties from "../../../../../../../properties/Properties";

import {
  createTipsUnderChallenge,
  getAllQuestions,
  getAllTipsUnderChallenge,
  getChallengeQuestionAccordingViewDate,
  getQuestionCategoryType,
  submitViewDateTipsTable,
  linkOptionToQuestionCall,
  getLinkedQuestionToOptions,
  getAllAdditionsSettingsFlags,
  updateProgramChallengeFlags,
  clearParentAndChildQsAndTips,
} from "../../../../../../../apis/eventService";
import { MdAddLink } from "react-icons/md";

import { compose } from "recompose";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { Link } from "@material-ui/icons";
import Snackbar from "../../../../../../common/Snackbar";
import ApplicationUtil from "../../../../../../../utils/ApplicationUtil";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import { getEmailReminderContents } from "../../../../../../../apis/programService";

const SettingsView = ({ classes }) => {
  const currentChallenge = ApplicationUtil?.getProgramUnderCurrentChallenge();
  const [isPastChallenge, setIsPastChallenge] = useState(false);
  const [settingState, setSettingState] = useState({
    expectedNumberFieldError: false,
    maxNumberFieldError: false,
    AllQuestions: [],
    tipsData: [],
    categoryTypeError: false,
    showLinkedDialog: false,
    suggestions: [],
    value: "",
    selectedQuestion: {},
    durationsDates: [],
    showCancelDialog: false,
    AllLinkedQuestionToDate: [],
    selectedChildQuestion: {},
    selectedParentOption: "",
    alreadyLinked: {},

    eventSettings: [],

    isAllChecked: false,
    checkboxArr: [],
 
    emailContentsList: [],
  });

  const {
    alreadyLinked,
    AllQuestions,
    tipsData,
 
    showLinkedDialog,
    AllLinkedQuestionToDate,
    selectedQuestion,
    durationsDates,
    showCancelDialog,
    selectedChildQuestion,
    selectedParentOption,
    eventSettings,
    emailContentsList,
    isAllChecked,
    checkboxArr,
  } = settingState;

  // -------------------------------
  //  Creation Question by Text type
  // -------------------------------

  useEffect(() => {
    let setPermissions = JSON.parse(localStorage.getItem("supportPermissions"));
    setIsPastChallenge(!setPermissions?.canModifyProgram);
    getQuestionCategoryType().then((res) => {
      if (res?.status === 200) {
        if (res?.data && res?.data?.length) {
          let formattedData = res?.data?.map((item) => {
            return {
              label: item?.categoryType,
              value: item?.categoryType,
              id: item?.id,
            };
          });
          setSettingState((prev) => ({
            ...prev,
            categoryTypeList: formattedData,
          }));
        }
      }
    });

    getAllTipsUnderChallenge().then((res) => {
      if (res?.data && res?.data?.length) {
        setSettingState((prev) => ({
          ...prev,
          tipsData: res?.data?.map((item) => {
            // extract plain html into the plain  text
            const label =
              ApplicationUtil._extractPlainHtmlToPlainString(item?.tip) || "-";
            return { id: item.id, label: label, tip: label };
          }),
        }));
      }
    });
  }, []);

  useEffect(() => {
    if (currentChallenge) {
      let params = {
        challengeType: currentChallenge?.programChallengeType,
        ProgramChallenegeId: currentChallenge?.id,
      };

      getAllQuestions(params).then((res) => {
        if (res.status === 200) {
          setSettingState((prev) => ({
            ...prev,
            AllQuestions: res?.data,
          }));
        }
      });

      const startDate = moment(
        currentChallenge?.challengeStartDate,
        "DD-MM-YYYY HH:mm:ss"
      ); // start date
      const endDate = moment(
        currentChallenge?.challengeEndDate,
        "DD-MM-YYYY HH:mm:ss"
      ); // end date

      const datesBetween = [];
      let currentDate = startDate;

      while (currentDate.isSameOrBefore(endDate)) {
        datesBetween.push({
          date: currentDate.format("DD-MM-YYYY"),
          selectedValue: "",
          tip: "",
        });

        currentDate = currentDate.add(1, "day");
      }

      setSettingState((prev) => ({
        ...prev,
        durationsDates: datesBetween,
      }));

      getChallengeQuestionAccordingViewDate(currentChallenge?.id).then(
        (res) => {
          const resultData = datesBetween?.map((date) => {
            const existingArra2 =
              res.data &&
              res.data?.find((innerDate) => {
                return (
                  moment(innerDate.viewDate, "DD-MM-YYYY hh:mm:ss").format(
                    "DD-MM-YYYY"
                  ) === date?.date
                );
              });

            return existingArra2
              ? {
                  ...date,
                  parentQsId: existingArra2?.id,
                  childQuestionnaireDto:
                    existingArra2?.childQuestionnaireDto || [],
                  selectedValue: existingArra2?.questionnaire || null,
                  tip:
                    { ...existingArra2?.tip, value: existingArra2?.tip?.tip } ||
                    null,
                  emailContent: existingArra2?.emailContent || null,
                  videoURL:existingArra2?.videoURL || null,
                  existingRecord: true,
                }
              : date;
          });
          setSettingState((prev) => ({
            ...prev,
            durationsDates: resultData,
            AllLinkedQuestionToDate: resultData,
          }));
        }
      );

      additionalFlagLoad();

      getEmailReminderContents(currentChallenge?.programChallengeType).then(
        (res) => {
          setSettingState((prev) => ({
            ...prev,
            emailContentsList: res?.data?.map((item) => {
              // extract plain html into the plain  text

              return {
                id: item.id,
                label: item.subject,
                subject: item.subject,
                programChallengeType: item.programChallengeType,
                content: item.content,
              };
            }),
          }));
        }
      );
    }
  }, [showLinkedDialog]);

  const additionalFlagLoad = () => {
    getAllAdditionsSettingsFlags(currentChallenge?.id).then((res) => {
      let eventSettingsKeys = Object.keys(
        res?.data?.programChallengeSettings || {}
      );

      let eventSettingsData = [];

      for (let i = 0; i < eventSettingsKeys.length; i++) {
        let obj = {
          id: i + 1,
          label: res?.data?.programChallengeSettingsLabel[eventSettingsKeys[i]],
          value: JSON.parse(
            res?.data?.programChallengeSettings[eventSettingsKeys[i]]
          ),
          name: eventSettingsKeys[i],
        };
        eventSettingsData.push(obj);
      }

      setSettingState((prev) => ({
        ...prev,
        eventSettings: eventSettingsData,
        showCancelDialog: false,
      }));
    });
  };

  const handleExtendedRow = (index) => {
    const newList = [...AllLinkedQuestionToDate];
    if (newList[index].extend === true) {
      newList[index].extend = false;
    } else {
      newList[index].extend = true;
    }
    setSettingState((prev) => ({ ...prev, AllLinkedQuestionToDate: newList }));
  };

  const handleOpenLinkOption = (selectedItem, optionValue) => {
    setSettingState((prev) => ({
      ...prev,
      showLinkedDialog: true,
      selectedQuestion: selectedItem,
      selectedParentOption: optionValue,
    }));
  };

  const handleOpenAlreadyLinkedOption = (selectedItem, optionValue) => {
    let linkedItem = selectedItem?.childQuestionnaireDto?.find(
      (item) => item.questionOption === optionValue
    );
    setSettingState((prev) => ({
      ...prev,
      showLinkedDialog: true,
      selectedQuestion: selectedItem,
      selectedParentOption: optionValue,
      alreadyLinked: linkedItem,
    }));
  };

  /** Submitting Additional flags */
  const handleSubmitFlags = () => {
    let additionalSettingsObject = {};
    for (let i = 0; i < eventSettings.length; i++) {
      additionalSettingsObject[
        eventSettings[i].name
      ] = `${eventSettings[i].value}`;
    }

    updateProgramChallengeFlags({
      data: additionalSettingsObject,
      id: currentChallenge?.id,
    }).then((res) => {
      Snackbar.show({
        variant: "success",
        isAutoHide: true,
        preventDuplicate: true,
        message: res.data.verbose,
      });
    });
  };

  const handleChecked = (val, item) => {
    let findIndex = eventSettings.findIndex((x) => {
      return x.id === item.id;
    });
    let array = eventSettings;
    array[findIndex].value = val.target.checked;
    setSettingState((prev) => ({ ...prev, eventSettings: array }));
  };

  /**
   * Select Single Question row checkbox
   */
  const handleSelect = (e, date) => {
    let arr = [...checkboxArr];
    let i = arr.findIndex((x) => x.date === date);
    if (i != -1) {
      arr[i].date = date;
      arr[i].check = e.target.checked;
    } else {
      arr = [
        ...checkboxArr,
        {
          check: e.target.checked,
          date: date,
        },
      ];
    }
    setSettingState((prev) => ({
      ...prev,
      checkboxArr: arr?.filter((obj) => obj?.check),
      isAllChecked: false,
    }));
  };
  /**
   * Select All Qs CheckBox
   */
  const handleSelectAll = (isCheckedAll) => {
    let _arr = durationsDates
      ?.map((obj) => {
        return {
          check: isCheckedAll,
          date: obj?.date,
        };
      })
      ?.filter((obj) => obj?.check);

    setSettingState((prev) => ({
      ...prev,
      checkboxArr: _arr,
      isAllChecked: isCheckedAll,
    }));
  };
  /**
   * Check is selected or not for mapping
   */
  const isSelected = (date) => {
    let arr = checkboxArr;
    let i = arr.findIndex((x) => x.date === date);
    if (i !== -1) {
      if (arr[i].check) return true;
    } else {
      return false;
    }
  };
  const handleLinkQueSubmit = () => {
    let params = {
      parentQuestionId: selectedQuestion?.selectedValue?.id,
      childQuestionId: selectedChildQuestion?.id,
      viewDate: `${selectedQuestion?.date} 00:00:00`,
      programChallengeId: currentChallenge?.id,
      questionOption: selectedParentOption,
    };

    linkOptionToQuestionCall(params).then((res) => {
      setSettingState((prev) => ({
        ...prev,
        showLinkedDialog: false,
        selectedQuestion: {},
        selectedChildQuestion: {},
        selectedParentOption: "",
        alreadyLinked: {},
      }));
      Snackbar.show({
        variant: "success",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Linked option to question successfully",
      });
    });
  };
  const getLinkedOptionToQuestion = () => {
    let ExceptSelectedQuestionData;
    if (selectedQuestion) {
      ExceptSelectedQuestionData = AllQuestions?.filter((item) => {
        return item?.id !== selectedQuestion?.selectedValue?.id;
      });
    }

    return (
      <div className={classes.selectQuestionDialogWrapper}>
        <div className={classes.fieldWrapper}>
          <Typography style={{ padding: "10px" }}>
            <span className={classes.labelCss}>
              {" "}
              Select question:<span className={classes.mandatoryCss}>*</span> :
              &nbsp;
            </span>
          </Typography>
          <div style={{ width: "85%" }}>
            <Autocomplete
              id="combo-box-demo"
              value={
                alreadyLinked?.id
                  ? alreadyLinked?.childQuestionnaire
                  : selectedChildQuestion
              }
              disabled={alreadyLinked?.id ? true : false}
              onChange={(event, value) =>
                setSettingState((prev) => ({
                  ...prev,
                  selectedChildQuestion: value,
                }))
              }
              options={ExceptSelectedQuestionData}
              getOptionLabel={(option) => option.question}
              className={classes.autocompletefield}
              // style={{ width: "600px", border: "1px solid red" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "1px solid #ccc",
                      borderRadius: 1,
                      overflow: "hidden",
                      // other styles you want to apply
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    placeholder: "Select Question..",
                  }}
                  // label="Select..."
                />
              )}
            />
          </div>
        </div>

        {!alreadyLinked?.childQuestionnaire && !isPastChallenge && (
          <div className={classes.btnGroup}>
            <SubmitCancelBtn
              onClick={handleLinkQueSubmit}
              label={"Link Question"}
              type="submit"
              style={{ width: "200px" }}
              isDisabled={!Object.keys(selectedChildQuestion || {})?.length}
            />

            <SubmitCancelBtn
              label={"Cancel"}
              onClick={() => {
                setSettingState((prev) => ({
                  ...prev,
                  showLinkedDialog: false,
                  selectedQuestion: {},
                  selectedChildQuestion: {},
                  selectedParentOption: "",
                  alreadyLinked: {},
                }));
              }}
              type="submit"
              style={{ width: "200px" }}
            />
          </div>
        )}
      </div>
    );
  };

  const viewDaySelectQuestionHandler = (value, index) => {
    setSettingState((prev) => ({
      ...prev,
      durationsDates: prev.durationsDates.map((date, i) =>
        i === index
          ? { ...date, selectedValue: value, existingRecord: false }
          : date
      ),
    }));
  };

  const viewDaySelectTipsHandler = (value, index) => {
    setSettingState((prev) => ({
      ...prev,
      durationsDates: prev.durationsDates.map((date, i) =>
        i === index ? { ...date, tip: value, existingRecord: false } : date
      ),
    }));
  };

  const viewDaySelectEmailContentHandler = (value, index) => {
    setSettingState((prev) => ({
      ...prev,
      durationsDates: prev.durationsDates.map((date, i) =>
        i === index
          ? { ...date, emailContent: value, existingRecord: false }
          : date
      ),
    }));
  };

  const viewDaySetVideoURL =(value , index)=>{
// durationsDates[index].videoURL :value
setSettingState((prev) => {
  const updatedDurationsDates = [...prev.durationsDates];
  updatedDurationsDates[index] = { 
    ...updatedDurationsDates[index], 
    videoURL: value, 
    existingRecord: false 
  };

  return {
    ...prev,
    durationsDates: updatedDurationsDates,
  };
});
  }

  const submitViewDateTable = () => {
    let changesData = durationsDates?.filter(
      (item) =>
        (item?.selectedValue?.id || item?.tip?.id || item?.emailContent?.id || item?.videoURL) &&
        !item?.existingRecord
    );

    let formatData =
      changesData &&
      changesData?.map((item) => {
        return {
          programChallengeId: currentChallenge?.id || null,
          tipId: item?.tip?.id || null,
          questionnairId: item.selectedValue?.id || null,
          viewDate: `${item.date} 00:00:00` || null,
          emailContent: item?.emailContent,
          videoURL:item.videoURL
        };
      });

    submitViewDateTipsTable(formatData).then((res) => {
      if (res.status === 200) {
        const startDate = moment(
          currentChallenge?.challengeStartDate,
          "DD-MM-YYYY HH:mm:ss"
        ); // start date
        const endDate = moment(
          currentChallenge?.challengeEndDate,
          "DD-MM-YYYY HH:mm:ss"
        ); // end date

        const datesBetween = [];
        let currentDate = startDate;

        while (currentDate.isSameOrBefore(endDate)) {
          datesBetween.push({
            date: currentDate.format("DD-MM-YYYY"),
            selectedValue: "",
            tip: "",
          });

          currentDate = currentDate.add(1, "day");
        }

        setSettingState((prev) => ({
          ...prev,
          durationsDates: datesBetween,
        }));

        getChallengeQuestionAccordingViewDate(currentChallenge?.id).then(
          (res) => {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Response is submitted successfully ",
            });
            const resultData = datesBetween?.map((date) => {
              const existingArra2 =
                res.data &&
                res.data?.find((innerDate) => {
                  return (
                    moment(innerDate.viewDate, "DD-MM-YYYY hh:mm:ss").format(
                      "DD-MM-YYYY"
                    ) === date?.date
                  );
                });

              return existingArra2
                ? {
                    ...date,
                    childQuestionnaireDto:
                      existingArra2?.childQuestionnaireDto || [],
                    selectedValue: existingArra2?.questionnaire || null,
                    tip:
                      {
                        ...existingArra2?.tip,
                        value: existingArra2?.tip?.tip,
                      } || null,
                    emailContent: existingArra2?.emailContent || null,
                      videoURL:existingArra2?.videoURL || null,
                    existingRecord: true,
                  }
                : date;
            });
            setSettingState((prev) => ({
              ...prev,
              durationsDates: resultData,
              AllLinkedQuestionToDate: resultData,
            }));
          }
        );
      } else {
      }
    });
  };

  // console.log(durationsDates);
  

  /**
   * For clear the qs and tip from DB for user activity
   */
  const clearCheckedQuestionsAndTip = (checkboxArr) => {
    if (checkboxArr?.length > 0) {
      let _arrDtoForDelete = [];
      let _durationsDates = durationsDates?.map((obj, ind) => {
        if (isSelected(obj?.date)) {
          if (obj?.parentQsId) {
            let _childQuestionnaireDto =
              obj?.childQuestionnaireDto?.length > 0
                ? obj?.childQuestionnaireDto?.map((obj) => obj?.id)
                : null;
            _arrDtoForDelete.push({
              programChallengeQuestionId: obj?.parentQsId,
              parentChildQuestionId: _childQuestionnaireDto,
            });
          }
          return {
            ...obj,
            selectedValue: null,
            tip: null,
          };
        } else {
          return obj;
        }
      });

      if (_arrDtoForDelete?.length > 0) {
        clearParentAndChildQsAndTips(_arrDtoForDelete).then((res) => {
          if (res?.data?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.verbose
                ? res?.data?.verbose
                : Properties.Something_went_wrong,
            });
          }
        });
      }
      setSettingState((prev) => ({
        ...prev,
        durationsDates: _durationsDates,
        AllLinkedQuestionToDate: _durationsDates,
        checkboxArr: [],
        isAllChecked: false,
      }));
    }
  };

  const clearViewDateTable = () => {
    setSettingState((prev) => ({
      ...prev,
      showCancelDialog: true,
      flag: true,
    }));
    // setSettingState((prev) => ({
    //   ...prev,

    //   durationsDates: prev.durationsDates.map((date, i) => {
    //     return { ...date, selectedValue: {}, tip: {} };
    //   }),
    // }));
  };

  const cancelFlagsChanges = () => {
    setSettingState((prev) => ({
      ...prev,
      showCancelDialog: true,
      flag: true,
    }));
  };

  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.CreateEvent_CancelClickWarning}</span>
        </Typography>
      </div>
    );
  };

  const closeDialogBox = () => {
    if (!settingState?.flag) {
      setSettingState((prev) => ({
        ...prev,
        durationsDates: prev.durationsDates?.map((date, i) => {
          return { ...date, selectedValue: {}, tip: {} };
        }),
        showCancelDialog: false,
      }));
    } else {
      additionalFlagLoad();
    }
  };

  return (
    <div>
      {/* question table */}

      <div>
        <div style={{ padding: "15px 0" }}>
          <h3 style={{ padding: "10px 0 5px 0", margin: "0" }}>
            Configure question according to view day
          </h3>
          <Typography style={{ padding: "0" }}>
            Challenge Duration &nbsp;: &nbsp;
            {`${moment(
              currentChallenge?.challengeStartDate,
              "DD-MM-YYYY HH:mm:ss"
            ).format("DD/MM/YYYY")} - ${moment(
              currentChallenge?.challengeEndDate,
              "DD-MM-YYYY HH:mm:ss"
            ).format("DD/MM/YYYY")}`}
            {/* {moment(currentChallenge?.challengeEndDate, "DD-MM-YYYY HH:mm:ss")}-
            {moment(
              currentChallenge?.challengeStartDate,
              "DD-MM-YYYY HH:mm:ss"
            )} */}
          </Typography>
        </div>
        <div
          style={{
            maxHeight: "300px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Table stickyHeader style={{ minHeight: "300px" }}>
            <TableHead>
              <TableRow className={classes.tableHead}>
                {!isPastChallenge && (
                  <TableCell component="th">
                    <Checkbox
                      checked={isAllChecked}
                      onChange={(e) => handleSelectAll(e.target.checked)}
                      color="primary"
                      // indeterminate = {this.state.indeterminateCheck}
                    />
                  </TableCell>
                )}
                <TableCell component="th" textAlign="center">
                  View Day
                </TableCell>
                <TableCell component="th" style={{ width: "180px" }}>
                  Question{" "}
                </TableCell>
                <TableCell component="th" style={{ width: "180px" }}>
                  Tip{" "}
                </TableCell>
                <TableCell component="th" style={{ width: "180px" }}>
                  Email Content
                </TableCell>
                <TableCell component="th" style={{ width: "150px" }}>
                  Video URL
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                position: "relative",
              }}
            >
              {durationsDates?.map((date, index) => {
                const isItemSelected = isSelected(date?.date);
                return (
                  <TableRow item={index + 1}>
                    {!isPastChallenge && (
                      <TableCell component="td" align="center">
                        <Checkbox
                          checked={isAllChecked ? isAllChecked : isItemSelected}
                          onChange={(e) => handleSelect(e, date?.date)}
                          color="primary"
                        />
                      </TableCell>
                    )}
                    <TableCell
                      textAlign="center"
                      align="center"
                      // style={{ verticalAlign: "baseline" }}
                    >
                      {date.date}
                    </TableCell>
                    <TableCell
                      align="center"
                      // style={{ verticalAlign: "baseline" }}
                    >
                      <Autocomplete
                        id="combo-box-demo"
                        value={date?.selectedValue ? date?.selectedValue : null}
                        onChange={(event, value) =>
                          viewDaySelectQuestionHandler(value, index)
                        }
                        getOptionLabel={(option) => option.question}
                        options={AllQuestions}
                        disabled={isPastChallenge}
                        className={classes.autocompletefield}
                        // style={{ width: "600px", border: "1px solid red" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              "& .MuiInputBase-root": {
                                border: "1px solid #ccc",
                                borderRadius: 1,
                                overflow: "hidden",
                                // other styles you want to apply
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              placeholder: "Select Question..",
                            }}
                            // label="Select..."
                          />
                        )}
                      />
                    </TableCell>
                    {/* {console.log(date?.tip  , tipsData)
                    } */}
                    <TableCell
                      component="td"
                      align="center"
                      // style={{ verticalAlign: "baseline" }}
                    >
                      <div
                        className={classes.tdropdownCss}
                        style={{ width: "100%" }}
                      >
                        <Autocomplete
                          id="combo-box-demo"
                          placeholder="Select Tip..."
                          value={date?.tip || null}
                          onChange={(event, value) =>
                            viewDaySelectTipsHandler(value, index)
                          }
                          disabled={isPastChallenge}
                          options={tipsData}
                          getOptionLabel={(option) => {
                            // extract plain html into the plain  text
                            const label =
                              ApplicationUtil._extractPlainHtmlToPlainString(
                                option?.tip
                              ) ;
                              // console.log(label);
                              
                            return label;
                          }}
                          className={classes.autocompletefield}
                          // style={{ width: "600px", border: "1px solid red" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                "& .MuiInputBase-root": {
                                  border: "1px solid #ccc",
                                  borderRadius: 1,
                                  overflow: "hidden",
                                  // other styles you want to apply
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                placeholder: "Select Tip...",
                              }}
                              // label="Select..."
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    {/*  ---------------------------------- email content column ---------------------- */}
                    <TableCell
                      component="td"
                      align="center"
                      // style={{ verticalAlign: "baseline" }}
                    >
                      <div
                        className={classes.tdropdownCss}
                        style={{ width: "100%" }}
                      >
                        <Autocomplete
                          id="combo-box-demo"
                          placeholder="Select Tip..."
                          value={date?.emailContent || null}
                          onChange={(event, value) => {
                            viewDaySelectEmailContentHandler(value, index);
                          }}
                          disabled={isPastChallenge}
                          options={emailContentsList}
                          getOptionLabel={(option) => {
                            return option.subject;
                          }}
                          className={classes.autocompletefield}
                          // style={{ width: "600px", border: "1px solid red" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                "& .MuiInputBase-root": {
                                  border: "1px solid #ccc",
                                  borderRadius: 1,
                                  overflow: "hidden",
                                  // other styles you want to apply
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                placeholder: "Select subject...",
                              }}
                              // label="Select..."
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell width={150}>
                      <CustomTextField
                        className={classes.fieldCss}
                        type="text"
                        placeholder="Enter URL "
                        value={date?.videoURL}
                        handleChange={(prop) => (e) => {
                          viewDaySetVideoURL(e.target.value , index)
                        }}
                        readOnly={isPastChallenge}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>

        {!isPastChallenge && (
          <Grid
            container
            style={{
              padding: "30px 0 20px 0",
            }}
            spacing={2}
          >
            {" "}
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <SubmitCancelBtn
                  onClick={(e) => submitViewDateTable()}
                  label={"Submit"}
                  type="submit"
                  style={{ width: "150px" }}
                />

                <SubmitCancelBtn
                  onClick={(e) => clearViewDateTable()}
                  label={"Clear"}
                  type="submit"
                  style={{ width: "150px" }}
                />
              </div>
            </Grid>
          </Grid>
        )}
      </div>

      <div style={{ paddingBottom: "10px" }}>
        {durationsDates?.filter((item) => item?.selectedValue) && (
          <div
            style={{
              borderBottom: "1px solid rgb(8, 69, 108)",
              paddingBottom: "25px",
            }}
          >
            <h3> Question Table</h3>

            <div
              style={{
                height: "400px",

                overflowY: "auto",
                position: "relative",
              }}
            >
              <Table stickyHeader style={{ minHeight: "400px" }}>
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    <TableCell component="th">Que. No.</TableCell>
                    <TableCell component="th" style={{ width: "180px" }}>
                      Question{" "}
                    </TableCell>
                    <TableCell component="th">Expected Answer</TableCell>
                    <TableCell component="th">Options</TableCell>

                    <TableCell component="th">Action</TableCell>
                    {/* <TableCell component="th"></TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody
                  style={{
                    height: "400px",
                    overflowY: "auto",
                    position: "relative",
                  }}
                >
                  {AllLinkedQuestionToDate?.filter(
                    (obj) => obj?.selectedValue
                  )?.map((questionItem, index) => {
                    // if (questionItem?.selectedValue) {
                    let {
                      id,
                      question,
                      programChallengeType,
                      options,
                      expectedOption,
                      questionResponseType,
                      range,
                      expectedRange,
                    } = questionItem;

                    let isAlreadyLinked =
                      questionItem?.childQuestionnaireDto?.map(
                        (item) => item.questionOption
                      );

                    return (
                      <TableRow key={id}>
                        <TableCell
                          component="td"
                          textAlign="center"
                          vertical-align="top"
                          width={10}
                          style={{ verticalAlign: "baseline" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {index + 1}
                          </div>
                        </TableCell>
                        <TableCell
                          component="td"
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "baseline",
                          }}
                        >
                          <Tooltip
                            title={questionItem?.selectedValue?.question}
                          >
                            <div className={classes?.childQuestionDivCss}>
                              {questionItem?.selectedValue?.question}
                            </div>
                          </Tooltip>
                        </TableCell>

                        <TableCell
                          component="td"
                          align="center"
                          style={{ verticalAlign: "baseline" }}
                        >
                          {questionItem?.selectedValue?.questionResponseType
                            ?.categoryType === "Text"
                            ? questionItem?.selectedValue?.expectedOption
                                ?.length === 1
                              ? questionItem?.selectedValue?.expectedOption?.map(
                                  (item) => `${item}`
                                )
                              : questionItem?.selectedValue?.expectedOption?.map(
                                  (item) => `${item},`
                                )
                            : questionItem?.selectedValue?.questionResponseType
                                ?.categoryType === "Range"
                            ? questionItem?.selectedValue?.expectedRange
                            : ""}
                        </TableCell>
                        <TableCell
                          component="th"
                          align="start"
                          style={{ verticalAlign: "baseline" }}
                        >
                          {questionItem?.selectedValue?.questionResponseType
                            ?.categoryType === "Text" ? (
                            <div>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "flex",
                                  fontWeight: "bold",
                                  justifyContent: "space-between",
                                }}
                              >
                                {questionItem?.selectedValue?.options?.length}{" "}
                                Options
                                {questionItem?.selectedValue?.options?.length >
                                  2 && (
                                  <Tooltip
                                    title={
                                      !questionItem?.extend
                                        ? "See Options"
                                        : "Close"
                                    }
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        background: questionItem?.extend
                                          ? "red"
                                          : "#052336",
                                        color: "white",
                                        width: "20px",
                                        height: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "50%",
                                      }}
                                      onClick={() => handleExtendedRow(index)}
                                    >
                                      {questionItem?.extend ? "-" : "+"}
                                    </span>
                                  </Tooltip>
                                )}
                              </Typography>
                              <ul style={{ listStyle: "none", padding: "0" }}>
                                {questionItem?.selectedValue?.options
                                  .slice(
                                    0,
                                    questionItem?.extend
                                      ? questionItem?.selectedValue?.options
                                          ?.length
                                      : 2
                                  )
                                  .map((item) => {
                                    let isLinked =
                                      isAlreadyLinked?.includes(item);
                                    return (
                                      <li
                                        key={item}
                                        style={{
                                          paddingBottom: "3px",
                                          display: "flex",
                                        }}
                                      >
                                        <Typography>{item}</Typography>{" "}
                                        {!isLinked ? (
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              width: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "end",
                                            }}
                                            onClick={() =>
                                              handleOpenLinkOption(
                                                questionItem,
                                                item
                                              )
                                            }
                                          >
                                            <MdAddLink
                                              style={{
                                                color: "#759ac0",
                                                fontSize: "28px",
                                              }}
                                            />
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              width: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "end",
                                            }}
                                            onClick={() => {
                                              handleOpenAlreadyLinkedOption(
                                                questionItem,
                                                item
                                              );
                                            }}
                                          >
                                            <Link
                                              style={{ color: "#759ac0" }}
                                            />
                                          </span>
                                        )}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          ) : questionItem?.selectedValue?.questionResponseType
                              ?.categoryType === "Range" ? (
                            <Typography>{range}</Typography>
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </TableCell>
                        <TableCell
                          component="td"
                          align="center"
                          style={{ verticalAlign: "baseline" }}
                        >
                          <Typography>Edit</Typography>
                        </TableCell>
                      </TableRow>
                    );
                    // }
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </div>

      {/* flags updation */}
      <div className={classes.root}>
        <h3 className={classes.title}>Additional Settings</h3>

        <Grid container>
          {eventSettings?.map((item, key) => {
            return (
              <>
                {" "}
                <Grid item xs={6}>
                  <span className={classes.labelCss}>{item.label}</span>
                  <Switch
                    color="primary"
                    checked={item.value}
                    onChange={(value) => handleChecked(value, item)}
                    disabled={isPastChallenge}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
      </div>

      {!isPastChallenge && (
        <Grid container>
          <Grid
            container
            style={{
              padding: "30px 0 20px 0",
            }}
            spacing={2}
          >
            {" "}
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <SubmitCancelBtn
                  onClick={(e) => cancelFlagsChanges()}
                  label={"Cancel"}
                  type="submit"
                  style={{ width: "150px" }}
                />

                <SubmitCancelBtn
                  onClick={(e) => handleSubmitFlags()}
                  label={"Update"}
                  type="submit"
                  style={{ width: "150px" }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* cancel dialog */}
      <DialogBox
        signup={true}
        open={showCancelDialog}
        message={getDialogMsg()}
        header={""}
        label={"No"}
        onClick={() => {
          setSettingState((prev) => ({ ...prev, showCancelDialog: false }));
        }}
        labelTwo={"Yes"}
        onClickTwo={() => {
          clearCheckedQuestionsAndTip(checkboxArr);
          closeDialogBox();
        }}
        closeDisloag={() =>
          setSettingState((prev) => ({ ...prev, showCancelDialog: false }))
        }
      />

      {/* link option to question dialog */}
      <DialogBox
        fullWidth
        maxWidth={"md"}
        open={showLinkedDialog}
        message={getLinkedOptionToQuestion()}
        header={""}
        label={"Link"}
        onClick={() => {
          setSettingState((prev) => ({
            ...prev,
            showLinkedDialog: false,
            selectedQuestion: {},
            selectedChildQuestion: {},
            selectedParentOption: "",
            alreadyLinked: {},
          }));
        }}
        labelTwo={"cancel"}
      />
    </div>
  );
};

const styles = (theme) => ({
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  autocompletefield: {
    border: "1px solid #08456C",
    width: "80% !important",
    padding: "0 4px",
    borderRadius: "3px",
  },

  selectQuestionDialogWrapper: {
    width: "100%",
    padding: "0",
  },

  fieldWrapper: {
    display: "flex",
    width: "100%",
    "& p": {
      width: "30%",
      display: "flex",
      justifyContent: "end",
    },
    // "& div": {
    //   width: "85%",
    // },
  },
  btnGroup: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    padding: "20px 0",
  },

  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  optionsCss: {
    display: "flex",
    // width: "100%",
    flex: "flex-wrap",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },

  expectedAnwerLabel: {
    color: "#727272",
    width: "fit-Content",
  },
  labelCss: {
    color: "#727272",
  },
  groupLabelCss: {
    fontSize: "20px",
    color: "rgb(8, 69, 108)",
  },
  mandatoryCss: { color: "red" },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreafieldCss: {
    border: "1px solid #08456C",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreaErrorCss: {
    border: "1px solid red",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  tdropdownCss: { width: "180px" },
  dropdownCss: {
    width: isBrowser ? "360px" : "160px",
  },
  // custom fields
  root: {
    // marginBottom: "20px",
    paddingBottom: "20px",
    borderBottom: "1px solid rgb(8, 69, 108)",
  },
  title: {
    color: "rgb(8, 69, 108)",
  },
  tableFieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
  },
  chipCss: {
    border: "1px solid #08456C",
    borderRadius: "4px",
    // height: "40px", //"45%",
    padding: "2px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  tableHead: {
    backgroundColor: "#ced4da !important",
    "& th:first-child": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "30px",
    },
    "& th": {
      backgroundColor: "#ced4da",
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "100px",
    },
  },
  tableBody: {
    height: "300px",
    overflowY: "auto",
    "& tr td": {
      width: "100px",
      display: "flex",
      alignItems: "center",

      overflow: "hidden",
      textOverflow: "ellipsis",
      // textAlign: "center",
    },
  },
  fieldsContainer: {
    marginTop: "20px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "20px",
  },
  addIcon: {
    fontSize: "30px",
    cursor: "pointer",
    color: "rgb(8, 69, 108)",
  },
  textLink: {
    backgroundColor: "#ade8f4",
    fontWeight: "bold",
    color: "#023e8a",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60px",
    margin: "auto",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      color: "#03045e",
    },
  },
  moreFieldOptions: {
    padding: "8px 24px",
  },
  addButton: {
    // width: "120px",
    textDecoration: "none",
    backgroundColor: "#09456c",
    color: "white",
    borderRadius: "20px",
    // marginLeft: "auto",
  },
  addBtnText: {
    backgroundColor: "#09456c",
    color: "white",
  },
  saveButton: {
    width: "120px",
  },
  option: {
    border: "1px solid #727272",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "10px",
    paddingBottom: "10px",
    // paddingRight: "",
  },
  optionIcons: {
    display: "flex",
    alignItems: "center",
  },
  optionListContainer: {
    // maxHeight: "300px",
    // overflowY: "auto",
    // overflowX: "hidden",
    marginBottom: "10px",
    paddingLeft: "10px",
  },
  topFieldAdd: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "20px",
  },
  linkText: {
    color: "rgb(8, 69, 108)",
    cursor: "pointer",
    textDecoration: "underline",
  },
  parentDivCss: {
    height: "50px",
  },
  childQuestionDivCss: {
    width: "180px",
    maxWidth: "180px",
    minWidth: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  childDivCss: {
    position: "absolute",
    width: "180px",
    maxWidth: "180px",
    minWidth: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // zIndex: "1",
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(SettingsView);

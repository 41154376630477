import { styled, withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import AppToolBar from "../../../common/AppToolBar";
import Header from "../../../common/Header";
import { MdOutlineOndemandVideo } from "react-icons/md";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import QuestionComponent from "./QuestionComponent";
import TipComponent from "./TipComponent";
import ProductBlock from "./ProductBlock";
import CancelIcon from "@material-ui/icons/Cancel";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import NoSugarChallengeTable from "../../registration/program/NoSugarChallengeTable";
import { getProgramChallengeQueOfTheDay } from "../../../../apis/programService";
import RunnerSideBreadcrumbs from "../../../common/RunnerSideBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getProgramDetailsWithDistkey,
  getProgramParticipantDashboardData,
} from "../../../../apis/eventService";
import {
  updateCurrentRunningProgramChallenge,
  updateUserSelectedProgram,
} from "../../../../redux/user";
import QuestionCardsCarousel from "./CardsCarousel/QuestionCardsCarousel";
import ImageUploadComponent from "./ImageUploadComponent";
import ReactPlayer from "react-player";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import httpService from "../../../../apis/httpService";
const API_URL_FILE = httpService.API_URL();

function RecordResponse({ classes, history, updateUserSelectedProgram }) {
  const [queArray, setQueArray] = useState([]);
  const [tip, setTip] = useState([]);

  //  video state
  const [isVideoPopup, setIsVideoPopup] = useState(false);
  const [yogaVideoLink, setYogaVideoLink] = useState(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const [skippedfirstDay, setSkippedfirstDay] = useState({});
  const [QuestionArrayCalculateDay, setQuestionArrayCalculateDay] = useState(
    []
  );

  const [hydrationStatus, setHydrationStatus] = useState(null);
  const [suggestedQuantity, setSuggestedQuantity] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [currentQueIndex, setCurrentQueIndex] = useState(0);
  const [viewDate, setViewDate] = useState(null);
  const [challengeId, setChallengeId] = useState(null);
  const [runnerDetail, setRunnerDetail] = useState(null);
  const [userUploadedImg, setUserUploadedImg] = useState(null);
  let programDetails = ApplicationUtil.getRunnersProgramDetails();
  const selectedQueBlock = useSelector((state) => state.user.selectedQueBlock);
  const urlParams = new URLSearchParams(window.location.search);
  let search = window.location.href;
  let distKey = search.split("distkey=")[1]?.split("&")[0];
  let isQueBlock = Boolean(urlParams.get("queBlock"));
  const dispatch = useDispatch();
  let paramChallengeId = urlParams.get("id");
  const currentRunningProgramChallenge = useSelector(
    (state) => state.user.currentRunningProgramChallenge
  );
  const [programChallenges, setProgramChallenges] = useState([]);
  const [perDayViewData, setPerDayViewData] = useState([]);
  const getDistKey = () =>
    search
      .split(search.includes("distkey") ? "distkey=" : "distKey=")[1]
      ?.split("&")[0];

  useEffect(() => {
    if (!ApplicationUtil.isuserLoggedIn("runner")) {
      const distKey = getDistKey();
      const paramChallengeId = urlParams.get("id");
      const paramDate = urlParams.get("viewDate");

      localStorage.setItem("paramChallengeId", paramChallengeId);
      localStorage.setItem("paramDate", paramDate);

      const loginRoute = `/login?distKey=${distKey}&isprogram=true&recordRes=true`;
      history.push(loginRoute);
    } else {
      const distKey = getDistKey();

      if (programDetails?.distKey && distKey) {
        isDistkeySame(programDetails?.distKey, distKey).then((programId) => {
          updatedDistKey(programId, distKey);
        });
      } else {
        let programDetails = ApplicationUtil.getRunnersProgramDetails();
        updatedDistKey(programDetails?.id, distKey);
      }
    }
  }, []);

  const updatedDistKey = (programId, distKey) => {
    let paramChallengeId, paramDate;
    if (distKey) {
      paramChallengeId = urlParams.get("id");
      paramDate = urlParams.get("viewDate");
    } else if (!isQueBlock) {
      paramChallengeId = localStorage.getItem("paramChallengeId");
      paramDate = localStorage.getItem("paramDate");
    }
    if (paramChallengeId && paramDate && paramChallengeId !== "null") {
      initialLoad(paramChallengeId, paramDate, programId);
      if (!Object.keys(currentRunningProgramChallenge || {})?.length) {
        const currentRunningChallenge = programDetails?.programChallenges?.find(
          (challenge) => challenge?.id == paramChallengeId
        );
        dispatch(updateCurrentRunningProgramChallenge(currentRunningChallenge));
      }
    } else {
      initialLoad(null, null, programId);
    }
  };

  const isDistkeySame = async (selectedDistKey, currentDistKey) => {
    if (selectedDistKey === currentDistKey) {
      const programDetails = ApplicationUtil.getRunnersProgramDetails();
      return Promise.resolve(programDetails?.id);
    } else {
      try {
        const { data } = await getProgramDetailsWithDistkey(currentDistKey);
        updateUserSelectedProgram(data);
        return data?.id;
      } catch (error) {
        console.error("Error fetching program details with distKey:", error);
        return Promise.reject(error);
      }
    }
  };

  const initialLoad = (paramChallengeId, paramDate, programId) => {
    let programChallengeId;
    let date;
    if (paramChallengeId && paramChallengeId !== "null") {
      programChallengeId = paramChallengeId;
      date = paramDate;
    } else {
      if (isQueBlock) {
        programChallengeId = selectedQueBlock?.challengeDetails?.id;
        date =
          selectedQueBlock?.responseStatusOfTheDay?.details?.programChallengeQuestionDto?.viewDate?.split(
            " "
          )[0];

        date = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
      } else {
        programChallengeId = currentRunningProgramChallenge?.id;

        if (currentRunningProgramChallenge?.programChallengeType !== "YOGA") {
          date = moment().subtract(1, "day").format("YYYY-MM-DD");
        } else {
          date = moment().format("YYYY-MM-DD");
        }
      }
    }
    setViewDate(date);
    setChallengeId(programChallengeId);

    let runnerDetails = ApplicationUtil.getRunnerDetails();
    setRunnerDetail(runnerDetails);

    getProgramChallengeQueOfTheDay(programChallengeId, date).then(
      ({ data }) => {
        setUserUploadedImg(data?.photoURL ? data?.photoURL : null);
        setPerDayViewData(data);
        if (data?.response || !data?.videoURL) {
          setIsVideoPopup(false);
        }
        setYogaVideoLink(`${API_URL_FILE}${data?.videoURL}`);
        setCurrentQueIndex(0);
        setTip(data?.tip?.tip || "");
        setHydrationStatus(data?.hydrationStatus);
        setSuggestedQuantity(data?.finalSuggestedQuantity);
        setProductDetails(data?.tip?.productDetails || []);
        if (data?.childQuestionnaireDto) {
          setQueArray([
            { ...data, viewDate: date },
            { ...data?.childQuestionnaireDto, viewDate: date },
          ]);
        } else {
          setQueArray([{ ...data, viewDate: date }]);
        }
      }
    );
    getGraphData(programId, date, programChallengeId);
  };
  const getGraphData = (programId, date, programChallengeId) => {
    let programDetails = ApplicationUtil.getRunnersProgramDetails();
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    let updatedProgramId = programId ? programId : programDetails?.id;

    getProgramParticipantDashboardData(runnerId, updatedProgramId)
      .then((res) => {
        if (res?.data) {
          let challengeData = res?.data?.programChallenges?.find(
            (item) =>
              item.id ===
              parseInt(
                urlParams.get("id") ||
                  currentRunningProgramChallenge?.id ||
                  programChallengeId
              )
          );

          setQuestionArrayCalculateDay(
            ApplicationUtil.QuestionArraySorting(challengeData?.questionnaires)
          );

          let dayData = ApplicationUtil.QuestionArraySorting(
            challengeData?.questionnaires
          )?.find((item) => item?.isChallengePerformed === false);

          const viewDateFromData = moment(
            dayData?.programChallengeQuestionDto?.viewDate,
            "DD-MM-YYYY HH:mm:ss"
          );
          if (viewDateFromData.isSameOrAfter(moment(date, "YYYY-MM-DD"))) {
            setSkippedfirstDay({});
          } else {
            setSkippedfirstDay(dayData);
          }

          setProgramChallenges(res?.data?.programChallenges);
        }
      })
      .catch(() => {
        console.log("error");
      });
  };

  const getDayNumber = () => {
    // If it is a question block, return the day number from the selected question block
    if (isQueBlock) {
      return selectedQueBlock?.responseStatusOfTheDay?.dayNumber;
    }

    // Function to calculate the day number based on the start date and the view date
    const calculateDayNumber = (startDate) => {
      startDate = moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      return moment(viewDate, "YYYY-MM-DD").diff(startDate, "days") + 1;
    };

    // If there is a parameter challenge ID
    if (paramChallengeId) {
      // If the current running challenge matches the parameter challenge ID
      if (currentRunningProgramChallenge?.id == paramChallengeId) {
        return calculateDayNumber(
          currentRunningProgramChallenge?.challengeStartDate?.split(" ")[0]
        );
      }

      // If the current running challenge does not match the parameter challenge ID
      const challenge = ApplicationUtil.getChallengeFromId(
        programDetails?.programChallenges,
        paramChallengeId
      );
      if (challenge) {
        return calculateDayNumber(challenge?.challengeStartDate?.split(" ")[0]);
      }
    }

    // If there is a current running challenge, return the day number based on its start date
    if (currentRunningProgramChallenge) {
      return calculateDayNumber(
        currentRunningProgramChallenge?.challengeStartDate?.split(" ")[0]
      );
    }
  };
  const viewDay = getDayNumber();

  const getHeaderLabel = () => {
    // If it is a question block, return the name from the selected question block
    if (isQueBlock) {
      return selectedQueBlock?.challengeDetails?.name;
    }

    // Function to retrieve the challenge name based on its ID
    const getChallengeNameById = (challengeId) => {
      const challenge = ApplicationUtil.getChallengeFromId(
        programDetails?.programChallenges,
        challengeId
      );
      return challenge?.name;
    };

    // If there is a parameter challenge ID
    if (paramChallengeId) {
      // If the current running challenge matches the parameter challenge ID
      if (currentRunningProgramChallenge?.id === paramChallengeId) {
        return currentRunningProgramChallenge?.name;
      }

      // If the current running challenge does not match the parameter challenge ID
      return getChallengeNameById(paramChallengeId);
    }

    // If there is a current running challenge, return its name
    if (currentRunningProgramChallenge) {
      return currentRunningProgramChallenge?.name;
    }

    // Default label if no other conditions are met
    return "Program Challenge";
  };

  const getFromDate = () => {
    // Function to split and return the date part from the challenge start date
    const extractDate = (dateString) => dateString?.split(" ")[0];

    // If it is a question block, return the start date from the selected question block
    if (isQueBlock) {
      return extractDate(
        selectedQueBlock?.challengeDetails?.challengeStartDate
      );
    }

    // Function to retrieve the challenge start date based on its ID
    const getChallengeStartDateById = (challengeId) => {
      const challenge = ApplicationUtil.getChallengeFromId(
        programDetails?.programChallenges,
        challengeId
      );
      return extractDate(challenge?.challengeStartDate);
    };

    // If there is a parameter challenge ID
    if (paramChallengeId) {
      // If the current running challenge matches the parameter challenge ID
      if (currentRunningProgramChallenge?.id === paramChallengeId) {
        return extractDate(currentRunningProgramChallenge?.challengeStartDate);
      }

      // If the current running challenge does not match the parameter challenge ID
      return getChallengeStartDateById(paramChallengeId);
    }

    // If there is a current running challenge, return its start date
    if (currentRunningProgramChallenge) {
      return extractDate(currentRunningProgramChallenge?.challengeStartDate);
    }

    // Default return value if no other conditions are met
    return "";
  };

  const getToDate = () => {
    // Function to split and return the date part from the challenge end date
    const extractDate = (dateString) => dateString?.split(" ")[0];

    // If it is a question block, return the end date from the selected question block
    if (isQueBlock) {
      return extractDate(selectedQueBlock?.challengeDetails?.challengeEndDate);
    }

    // Function to retrieve the challenge end date based on its ID
    const getChallengeEndDateById = (challengeId) => {
      const challenge = ApplicationUtil.getChallengeFromId(
        programDetails?.programChallenges,
        challengeId
      );
      return extractDate(challenge?.challengeEndDate);
    };

    // If there is a parameter challenge ID
    if (paramChallengeId) {
      // If the current running challenge matches the parameter challenge ID
      if (currentRunningProgramChallenge?.id === paramChallengeId) {
        return extractDate(currentRunningProgramChallenge?.challengeEndDate);
      }

      // If the current running challenge does not match the parameter challenge ID
      return getChallengeEndDateById(paramChallengeId);
    }

    // If there is a current running challenge, return its end date
    if (currentRunningProgramChallenge) {
      return extractDate(currentRunningProgramChallenge?.challengeEndDate);
    }

    // Default return value if no other conditions are met
    return "";
  };

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onRedirectSkippedDay = () => {
    setIsVideoPopup(false);
    setViewDate(
      moment(
        skippedfirstDay?.programChallengeQuestionDto?.viewDate,
        "DD-MM-YYYY HH:mm:ss"
      ).format("YYYY-MM-DD")
    );
  };

  // skipday warn and show video
  useEffect(() => {
    if (viewDate) {
  
      const viewDateFromData = moment(
        skippedfirstDay?.programChallengeQuestionDto?.viewDate,
        "DD-MM-YYYY HH:mm:ss"
      );

     // Do not ask for permission to return and submit your response for same-day submissions.
     
      let programChallengeId = urlParams.get("id");
      getProgramChallengeQueOfTheDay(
        programChallengeId || currentRunningProgramChallenge?.id,
        viewDate
      ).then(({ data }) => {
        let dayData = ApplicationUtil.QuestionArraySorting(
          QuestionArrayCalculateDay
        )?.find((item) => item?.isChallengePerformed === false);
       
        if (data?.response) {

          if (!data?.videoURL) {
            setIsVideoPopup(false);
          } else {
            setIsVideoPopup(false);
          }
        } else {
          if (dayData?.id) {
            if (!data?.response) {
              if (viewDateFromData.isSame(moment(viewDate, "YYYY-MM-DD"))) {
                setSkippedfirstDay({});
                if (data?.videoURL) {
                  setIsVideoPopup(true);
                } else {
                  setIsVideoPopup(false);
                }
              } else {
                if (!data?.videoURL) {
                  setIsVideoPopup(false);
                }else{
                  setIsVideoPopup(true);
                }
              }
            } else {
              setIsVideoPopup(false);
            }
          } else {
            setIsVideoPopup(true);
          }
        }
        setUserUploadedImg(data?.photoURL ? data?.photoURL : null);
        setPerDayViewData(data);
        setCurrentQueIndex(0);
        setTip(data?.tip?.tip || "");
        setHydrationStatus(data?.hydrationStatus);
        setSuggestedQuantity(data?.finalSuggestedQuantity);
        setProductDetails(data?.tip?.productDetails || []);
        if (data?.childQuestionnaireDto) {
          setQueArray([
            { ...data, viewDate: viewDate },
            { ...data?.childQuestionnaireDto, viewDate: viewDate },
          ]);
        } else {
          setQueArray([{ ...data, viewDate: viewDate }]);
        }
      });
    }
  }, [viewDate]);

  const onWatchVideoHandler = () => {
    setSkippedfirstDay(null);
    setIsVideoPopup(true);
  };

  return (
    <div>
      <AppToolBar>
        <Header
          label={getHeaderLabel()}
          showDrawer
          fromDate={getFromDate()}
          toDate={getToDate()}
        />
      </AppToolBar>

      <div className={classes.mainContainer}>
        <RunnerSideBreadcrumbs />

        {/* video bloack added jere */}
        {perDayViewData?.programChallengeType === "YOGA" && (
          <div>
            <Dialog
              open={isVideoPopup}
              fullWidth={"100%"}
              // maxWidth={"100%"}
              // onClose={() => this.closeSuccessDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                className: skippedfirstDay?.id
                  ? classes.dialogPaper_msg
                  : classes.dialogPaper_video, // Use the class defined in your CSS

                style: {
                  padding: "10px",
                  // height:"60vh",
                  overflow: "unset", // or 'visible', 'auto', etc.
                  // Add any other styles you want to override here
                },
              }}
            >
              {!skippedfirstDay?.id && (
                <Tooltip enterTouchDelay={0} title={"Close"}>
                  <span
                    style={{
                      position: "absolute",
                      right: "1px",
                      top: "-4px",
                      zIndex: "10",
                    }}
                  >
                    <IconButton
                      disabled={
                        perDayViewData?.response ? videoEnded : !videoEnded
                      }
                      onClick={() => {
                        setIsVideoPopup(false);
                        setVideoEnded(false);
                      }}
                    >
                      <CancelIcon
                        style={{
                          margin: 0,
                          cursor: "pointer",
                          color: (
                            perDayViewData?.response ? videoEnded : !videoEnded
                          )
                            ? "rgba(0, 0, 0, 0.54)"
                            : "rgb(8, 69, 108)",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {skippedfirstDay?.id ? (
                <div>
                  <DialogContent
                  // style={{
                  //   // overflowY: "hidden",
                  //   padding: typeof message === "string" ? "8px 24px" : "0px",
                  //   // margin: 0,
                  // }}
                  >
                    <DialogContentText
                      style={{ color: "#000000" }}
                      id="alert-dialog-description"
                    >
                      {ApplicationUtil?.skippedMsg}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <SubmitCancelBtn
                      // style={{ width: isSmall ? "auto" : "100%" }}
                      //onClick={() => this.closeSuccessDialog()}
                      onClick={(e) => onRedirectSkippedDay()}
                      label={"OK"}
                      type="submit"
                    />
                  </DialogActions>
                </div>
              ) : (
                <ReactPlayer
                  url={`${API_URL_FILE}${perDayViewData?.videoURL}`}
                  width={"100%"}
                  height={"100%"}
                  playing={true}
                  controls={true}
                  muted={true}
                  onProgress={(progressProp) => {
                    if (progressProp?.played) {
                    }
                  }}
                  onEnded={() => {
                    if (!perDayViewData?.response) {
                      setVideoEnded(true);
                    }
                  }}
                />
              )}
            </Dialog>
          </div>
        )}

        {/* end video bloack */}
        <Grid container spacing={2}>
          {viewDate && (
            <Grid item xs={12} md={8} className={classes.que_tip_mainContainer}>
              <Typography style={{ fontWeight: "800", fontSize: "22px" }}>
                {moment(viewDate, "YYYY-MM-DD").format("LL")}
              </Typography>
              <Typography style={{ fontWeight: "300" }}>
                {!isNaN(viewDay) && `Day ${viewDay}`}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={productDetails && productDetails.length !== 0 ? 8 : 12}
            className={classes.que_tip_mainContainer}
          >
            <div className="que_tip_container">
              <QuestionComponent
                setQueArray={setQueArray}
                queArray={queArray}
                currentQueIndex={currentQueIndex}
                setCurrentQueIndex={setCurrentQueIndex}
                initialLoad={initialLoad}
                viewDate={viewDate}
                challengeId={challengeId}
                getGraphData={getGraphData}
              />

              {tip && (
                <TipComponent
                  tip={tip}
                  style={{
                    background: "linear-gradient(to right, #fdf5ee, #f2b888)",
                  }}
                />
              )}

              {perDayViewData?.programChallengeType === "YOGA" &&
                perDayViewData?.videoURL && (
                  <Box
                    //  tip={tip}
                    className={classes?.container_watch_video}
                    style={{
                      borderRadius: "10px",
                      background: "linear-gradient(to right, #fdf5ee, #f2b888)",
                    }}
                  >
                    <div className={"title"}>
                      Video of the day: &nbsp;{" "}
                      <SubmitCancelBtn
                        label={"Watch video"}
                        type={"submit"}
                        style={{ width: "auto", borderRadius: "10px" }}
                        onClick={(e) => onWatchVideoHandler()}
                      />
                    </div>
                  </Box>
                )}

              {hydrationStatus && (
                <TipComponent
                  hydrationStatus={hydrationStatus}
                  suggestedQuantity={suggestedQuantity}
                  style={{
                    background: "linear-gradient(to right, #ebf1f6, #759ac0)",
                  }}
                />
              )}

              <ImageUploadComponent
                // key={moment()}
                ResponseSubmissionEndDate={moment(
                  perDayViewData?.responseSubmissionEndDate,
                  "DD-MM-YYYY HH:mm:ss"
                )}
                viewDate={viewDate}
                challengeId={challengeId}
                runnerDetails={runnerDetail}
                initialLoad={initialLoad}
                setUserUploadedImg={setUserUploadedImg}
                userUploadedImg={userUploadedImg}
                perDayViewData={perDayViewData}
              />

              {/* {programChallenges &&
                programChallenges?.length !== 0 &&
                programChallenges
                  ?.filter((item) => item.id == challengeId)
                  .map((challenge) => {
                    return (
                      <div key={challenge?.id}>
                        <NoSugarChallengeTable
                          challenge={challenge}
                          hideTitle={true}
                          hideLegends={true}
                          viewDate={viewDate}
                        />
                      </div>
                    );
                  })} */}
            </div>

            <div className={classes.carousel_container}>
              {programChallenges &&
                programChallenges?.length !== 0 &&
                programChallenges
                  ?.filter((item) => item.id == challengeId)
                  .map((challenge) => {
                    return (
                      <div key={challenge?.id}>
                        <QuestionCardsCarousel
                          key={userUploadedImg}
                          userUploadedImg={userUploadedImg}
                          perDayViewData={perDayViewData}
                          challengeType={challenge}
                          QuestionData={challenge.questionnaires}
                          onClickAndFetchData={(data) => {
                            initialLoad(
                              data?.programChallengeQuestionDto
                                ?.programChallengeId,
                              moment(
                                data?.programChallengeQuestionDto?.viewDate,
                                "DD-MM-YYYY HH:mm:ss"
                              ).format("YYYY-MM-DD")
                            );
                            goToTop();
                          }}
                          startDate={moment(
                            challenge?.challengeStartDate,
                            "DD-MM-YYYY HH:mm:ss"
                          )
                            .startOf()
                            .format("YYYY-MM-DD")}
                          viewDate={viewDate}
                          ResponseSubmissionEndDate={moment(
                            challenge?.responseSubmissionEndDate,
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                          productDetails={productDetails}
                        />
                      </div>
                    );
                  })}
            </div>
          </Grid>

          {productDetails && productDetails.length !== 0 && (
            <Grid item xs={12} md={4} className={classes.products_container}>
              <ProductBlock
                productDetails={productDetails}
                programChallengeId={selectedQueBlock?.challengeDetails?.id}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

const styles = (theme) => ({
  container_watch_video: {
    borderRadius: "10px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    // background: "linear-gradient(to right, #fdf5ee, #f2b888)",
    padding: "10px",
    "& .title": {
      fontWeight: 600,
      // paddingBottom:"10px",
      fontSize: "22px",
      minWidth: "fit-content",
      [theme.breakpoints.down("sm")]: {
        fontSize: "17px",
      },
    },
  },
  dialogPaper_video: {
    height: "60vh",
    [theme.breakpoints.down("sm")]: {
      height: "38vh",
    },
  },
  carousel_container: {
    padding: "20px 0",
  },
  chartTitle: {
    letterSpacing: "-1px",
    color: "#202260",
    fontWeight: "bold",
    marginTop: 0,
    fontSize: "35px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    },
  },
  mainContainer: {
    marginTop: "40px",
    padding: "20px",
  },
  que_tip_mainContainer: {
    "& .que_tip_container": {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    "& .que_container": {
      // margin: "20px",
      padding: "10px",
      "& .questionBox": {
        color: "black",
        fontWeight: "600",
        fontSize: "22px",
        "& .formControlLabel": {},
      },
    },

    "& .sliderContainer": {
      marginBottom: "10px",
      marginTop: "10px",
      width: "50%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  products_container: {},
});

function mapStateToProps(state) {
  return {
    userSelectedProgram: state.user.userSelectedProgram,
  };
}
export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    updateUserSelectedProgram,
  })
)(RecordResponse);

import React from "react";

import {
  Participants,
  Setup,
  Execution,
  SummaryDashboard,
  LeaderboardIcon,
  Analytics,
  Reports,
  Trainer,
  TrainingPlan,
  JobSync,
} from "./../../../common/SvgIcon";
import "./../../../../styles/organizer.css";

import Breadcrumbs from "./../../../common/Breadcrumbs";
import OrganizerCommonHeader from "./OrganizerCommonHeader";
import ParticipantExecutationDetails from "./ParticipantExecutationDetails";
import ParticipantRegistrationDetails from "./ParticipantDetails";
import GroupIcon from "@material-ui/icons/Group";
import OrganizerLeaderBoard from "./OrganizerLeaderBoard";
import OrganizerAnalytics from "./OrganizerAnalytics/OrganizerAnalytics";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import ReportDetails from "./OrganizerReports/ReportDetails";
import OrganizerChallengeParticipantDetails from "./Program/OrganizerChallengeParticipantDetails";
//header and sidebar integration

function OrganizerParticipantsList(props) {
  const { history } = props; //useHistory();

  const selected = localStorage.getItem("sidebarSelected");
  const [selectedMenu, setSelectedMenu] = React.useState(
    selected ? selected : "Results"
  );
  let userRole = localStorage.getItem("role");
  let challengeType = localStorage.getItem("challengeType");
  let isProgramChallenge = localStorage.getItem("isProgramChallenge");
  let isChallenge = localStorage.getItem("isChallenge");
  let IsOpenSubscriptionProgram = localStorage.getItem(
    "IsOpenSubscriptionProgram"
  );
  let isChallengeEvent = localStorage.getItem("isChallengeEvent");
  let isCreateOrDraft = localStorage.getItem("isCreateOrDraft");
  let isTraining = localStorage.getItem("isTraining");
  const selectedChallengeBasedResponse =
    ApplicationUtil.getProgramUnderCurrentChallenge();
  const [menuList, setMenuList] = React.useState(
    userRole === "SUPPORT" && IsOpenSubscriptionProgram === "true"
      ? ["Participants", "Activities"]
      : userRole !== "ORGANIZER_ADMIN" &&
        (challengeType === "TEAM" ||
          challengeType === "BOTH" ||
          challengeType === "TEAM_RELAY")
      ? [
          "Summary",
          "Participants",
          isChallengeEvent ? "Activities" : "Results",
          "Setup",
          "Leaderboard",
          "Teams",
          "JobSync",
          "Analytics",
          "Reports",
        ]
      : userRole === "ORGANIZER_ADMIN" &&
        (challengeType === "TEAM" ||
          challengeType === "BOTH" ||
          challengeType === "TEAM_RELAY")
      ? [
          "Summary",
          "Participants",
          isChallengeEvent ? "Activities" : "Results",
          "Setup",
          "Leaderboard",
          "Teams",
          // "Analytics",
          // "Reports",
        ]
      : userRole === "ORGANIZER_ADMIN" &&
        (challengeType === "HYDRATION" ||
          challengeType === "NO_UNHEALTHY_EATING" ||
          challengeType === "ZERO_SUGAR" ||
          challengeType === "COOPER_TEST" ||
          challengeType === "STAIR_STEPPER")
      ? [
          "Summary",
          "Participants",
          isChallengeEvent || isProgramChallenge ? "Activities" : "Results",
          "Setup",
          "Leaderboard",
        ]
      : userRole !== "ORGANIZER_ADMIN"
      ? [
          "Summary",
          "Participants",
          isChallengeEvent || isProgramChallenge ? "Activities" : "Results",
          "Setup",
          "Leaderboard",
          "JobSync",
          "Analytics",
          "Reports",
        ]
      : isChallenge
      ? ["Participants", "Results"]
      : isCreateOrDraft
      ? ["Setup"]
      : isTraining
      ? [
          "Summary",
          "Participants",
          "Results",
          "Setup",
          "Leaderboard",
          "Analytics",
          "Reports",
          "Trainer",
          "Training Plan",
        ]
      : [
          "Summary",
          "Participants",
          isChallengeEvent ? "Activities" : "Results",
          "Setup",
          "Leaderboard",
          "Analytics",
          "Reports",
        ]
  );

  const organizerEventID = ApplicationUtil.getSupportsEventID();

  return (
    <div className="organizer">
      <OrganizerCommonHeader />
      <aside className="organizer__sidebar">
        <div className="sidebar__top">
          {/* <KeyboardBackspaceIcon onClick={() => history.goBack()} /> */}
        </div>
        <hr />
        <div className="sidebar__menu">
          {menuList.map((row, index) => (
            <div
              className="menu"
              onClick={() => {
                localStorage.setItem("sidebarSelected", row);
                setSelectedMenu(row);
                history.push("/organizer/list");
              }}
            >
              {row === "Summary" && (
                <SummaryDashboard
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}
              {row === "Participants" && (
                <Participants
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}
              {(row === "Results" || row === "Activities") && (
                <Execution
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}

              {row === "Setup" && (
                <Setup
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}
              {row === "Leaderboard" && (
                <LeaderboardIcon
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}
              {row === "Analytics" && (
                <Analytics
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}
              {row === "Reports" && (
                <Reports
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}
              {row === "Trainer" && (
                <Trainer
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}
              {row === "Training Plan" && (
                <TrainingPlan
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}
              {row === "Teams" && (
                <GroupIcon
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}
              {row === `JobSync` && (
                <JobSync
                  className={`icon ${selectedMenu === row && "selectedIcon"}`}
                />
              )}
              <p>{row}</p>
            </div>
          ))}
        </div>
      </aside>
      <div className="organizer__mainBody">
        <Breadcrumbs />
        {selectedMenu === "Summary" && <h1> Details Summary</h1>}
        {selectedMenu === "Participants" && <ParticipantRegistrationDetails />}

    
        {(selectedMenu === "Results" || selectedMenu === "Activities") &&
          !selectedChallengeBasedResponse && <ParticipantExecutationDetails />}

          
        {(selectedMenu === "Results" || selectedMenu === "Activities") && selectedChallengeBasedResponse && (
          <OrganizerChallengeParticipantDetails />
        )}
        {selectedMenu === "Leaderboard" && <OrganizerLeaderBoard />}
        {selectedMenu === "Analytics" && <OrganizerAnalytics />}
        {selectedMenu === "Reports" && <ReportDetails />}
      </div>
    </div>
  );
}

export default compose(withRouter)(OrganizerParticipantsList);

import React, { useEffect, useState } from "react";
import {
  getActivityListOfProgramChallengeParticipants,
  getProgramChallengeParticipants,
} from "../../../../apis/challengeService";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import {
  FormControl,
  Grid,
  Menu,
  MenuItem,
  NativeSelect,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IconCalendarBlue } from "../../../common/SvgIcon";
import ListPageLoader from "../../../common/ListPageLoader";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import OrganizerProgramChallengeParticipantList from "./Program/OrganizerProgramChallengeParticipantList";
import ChallengeActivityList from "./Program/ChallengeActivityList";
import DrawerFilter from "../../../common/DrawerFilter";
import FilterIcon from "../../../common/FilterIcon";
import ApplicationConstants from "../../../../constants/ApplicationConstants";

function OrganizerProgramChallenge({ classes }) {
  const [activityList, setActivityList] = useState(null);
  const [programChallengeType, setProgramChallengeType] = useState(null);
  const [activityListTotalRecord, setActivityListTotalRecord] = useState(null);
  const [participantList, setParticipantsList] = useState(null);
  const [participantListTotalRecord, setParticipantListTotalRecord] =
    useState(null);
  const [isProgramChallenge, setIsProgramChallenge] = useState(false);
  const [searchSelectValue, setSearchSelectValue] = useState("Name");
  const [searchValue, setSearchValue] = useState();
  const [timeFormat, setTimeFormat] = useState();
  const [dateRangeOptions, setDateRangeOptions] = useState();
  const [dateRange, setDateRange] = useState();
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "day").format("YYYY/MM/DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY/MM/DD"));
  const [pageNumberLoad, setPageNumberLoad] = useState(null);
  const [resetPagination, setResetPagination] = useState(false);
  const [layoutAnchorEl, setLayoutAnchorEl] = useState(null);
  const [selectedList, setSelectedList] = useState("ACTIVITY");
  const [isPastChallenge, setIsPastChallenge] = useState(false);

  const [isFiltered, setIsFiltered] = useState(false);
  const [isDrawerOpen, setisDrawerOpen] = useState(false);

  const getPrevSelectedFilterState = (filterType) => {
    return ApplicationConstants?.programChallengeAtheleteListFilters?.find(
      (ob) => ob?.filterType === filterType
    )?.selectedValue;
  };

  const [applyFilterData, setApplyFilterData] = useState({
    selectedResponseRecievedFilter:
      getPrevSelectedFilterState("Recieve") || "responseRecieve",
  });

  useEffect(() => {
    let setPermissions = JSON.parse(localStorage.getItem("supportPermissions"));
    setIsPastChallenge(!setPermissions?.canModifyProgram);
    initialDataLoad();
    setIsProgramChallenge(false);
  }, [isProgramChallenge]);

  useEffect(() => {
    const storedView = localStorage.getItem("selectedList");
    if (storedView) {
      setSelectedList(storedView);
    }
  }, []);

  const getFilteredList = () => {
    setIsFiltered(!isFiltered);
  };

  /**
   * Vishal - After click on apply btn of filter we will get an array of selected filters
   * based on that we will call the API
   * @param {*} data
   */
  const onApplyFilter = (data) => {
    if (data && data?.length > 0) {
      let _selectedResponseRecievedFilter = null;
      let _tsy = data?.map((dd) => {
        if (dd?.filterType === "Recieve") {
          _selectedResponseRecievedFilter = dd?.selectedValue;
        }
      });
      setApplyFilterData({
        selectedResponseRecievedFilter: _selectedResponseRecievedFilter,
      });
      initialDataLoad(_selectedResponseRecievedFilter);
    }
  };

  const handleViewChange = (view) => {
    setSelectedList(view);
    localStorage.setItem("selectedList", view);
  };

  const initialDataLoad = (_selectedResponseRecievedFilter) => {
    let dateRange = [];
    //Last 24 hours --
    let last24Hrs = {
      label: "Last 24 hours",
      value: `${moment().subtract(1, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(1, "day")
        .format("HH:mm:ss")}`,
    };
    //Last 3 days --
    let last3days = {
      label: "Last 3 days",
      value: `${moment().subtract(3, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(3, "day")
        .format("HH:mm:ss")}`,
    };
    //Last 7 days --
    let last7days = {
      label: "Last 7 days",
      value: `${moment().subtract(7, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(7, "day")
        .format("HH:mm:ss")}`,
    };
    //Last month --
    let lastMonth = {
      label: "Last month",
      value: `${moment().subtract(1, "months").format("YYYY-MM-DD")} ${moment()
        .subtract(1, "months")
        .format("HH:mm:ss")}`,
    };
    let customDate = {
      label: "Custom Date",
      value: "Custom Date",
    };
    dateRange.push(last24Hrs, last3days, last7days, lastMonth, customDate);
    if (dateRange.length > 0) {
      setDateRangeOptions(dateRange);
    }

    let getSupportedEventDetails = ApplicationUtil.getSupportsEventsDetails();
    setProgramChallengeType(getSupportedEventDetails?.programChallengeType);
    let isProgramChallenge =
      getSupportedEventDetails?.programChallengeType === "HYDRATION" ||
      getSupportedEventDetails?.programChallengeType === "ZERO_SUGAR" ||
      getSupportedEventDetails?.programChallengeType === "NO_UNHEALTHY_EATING" || getSupportedEventDetails?.programChallengeType === "YOGA";
    if (isProgramChallenge) {
      let requestActivityParams = {
        pageNumber: 0,
        pageCount: 20,
        programChallengeId: getSupportedEventDetails?.id,
      };
      getActivityListOfProgramChallengeParticipants(requestActivityParams).then(
        ({ data }) => {
          let list = data?.challengeParticipantResponseDTOs || [];
          let activityTotalRecords = data?.totalRecords || 0;
          setActivityList(list);
          setActivityListTotalRecord(activityTotalRecords);
        }
      );

      let requestAtheleteParams = {
        pageNumber: 0,
        pageCount: 20,
        programChallengeId: getSupportedEventDetails?.id,
        // activityReceived: true,
      };
      let _check = _selectedResponseRecievedFilter
        ? _selectedResponseRecievedFilter
        : applyFilterData?.selectedResponseRecievedFilter;
      if (_check === "responseRecieve") {
        requestAtheleteParams.activityReceived = true;
      } else if (_check === "responseNotRecieve") {
        requestAtheleteParams.activityReceived = false;
      }

      getProgramChallengeParticipants(requestAtheleteParams).then(
        ({ data }) => {
          let list = data?.programChallengeParticipantDTOs || [];
          let participantTotalRecords = data?.totalChallengeParticipant || 0;
          setParticipantsList(list);
          setParticipantListTotalRecord(participantTotalRecords);
        }
      );
    }
  };
  const selectHandleValue = (event) => {
    setSearchSelectValue(event.target.value);
  };

  const searchHandleChange = (event) => {
    setSearchValue(event.target.value);
    setResetPagination(false);
    if (!event.target.value) {
      initialDataLoad();
    }
  };

  const getChallengeActivityList = () => {
    setPageNumberLoad(0);
    setResetPagination(false);
    let name;
    let bib;
    let email;
    let contact;

    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let getSupportedEventDetails = ApplicationUtil.getSupportsEventsDetails();
    let requestActivityParams = {
      pageNumber: 0,
      pageCount: 20,
      programChallengeId: getSupportedEventDetails?.id,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
    };
    getActivityListOfProgramChallengeParticipants(requestActivityParams).then(
      ({ data }) => {
        let list = data?.challengeParticipantResponseDTOs || [];
        let activityTotalRecords = data?.totalRecords || 0;
        setActivityList(list);
        setActivityListTotalRecord(activityTotalRecords);
      }
    );
  };

  const getChallengeAtheleteList = () => {
    setPageNumberLoad(0);
    setResetPagination(false);
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let getSupportedEventDetails = ApplicationUtil.getSupportsEventsDetails();
    let requestParams = {
      pageNumber: 0,
      pageCount: 20,
      programChallengeId: getSupportedEventDetails?.id,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      // activityReceived: true,
    };
    if (applyFilterData?.selectedResponseRecievedFilter === "responseRecieve") {
      requestParams.activityReceived = true;
    } else if (
      applyFilterData?.selectedResponseRecievedFilter === "responseNotRecieve"
    ) {
      requestParams.activityReceived = false;
    }
    getProgramChallengeParticipants(requestParams).then(({ data }) => {
      let list = data?.programChallengeParticipantDTOs || [];
      let participantTotalRecords = data?.totalChallengeParticipant || 0;
      setParticipantsList(list);
      setParticipantListTotalRecord(participantTotalRecords);
    });
  };
  const searchHandleClick = () => {
    if (selectedList === "ACTIVITY") {
      getChallengeActivityList();
    } else {
      getChallengeAtheleteList();
    }
  };

  const customStartDate = (date) => {
    setStartDate(date);
    setPageNumberLoad(0);
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      startDate: `${moment(date).format("YYYY-MM-DD")} ${moment().format(
        "HH:mm:ss"
      )}`,
      endDate: `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
        "HH:mm:ss"
      )}`,

      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: 0,
      pageCount: 20,
    };
    getActivityListOfProgramChallengeParticipants(requestParams).then(
      ({ data }) => {
        let list = data?.challengeParticipantResponseDTOs || [];
        let activityTotalRecords = data?.totalRecords || 0;
        setActivityList(list);
        setActivityListTotalRecord(activityTotalRecords);
      }
    );
  };

  const customEndDate = (date) => {
    setEndDate(date);
    setPageNumberLoad(0);
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      startDate: `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
        "HH:mm:ss"
      )}`,
      endDate: `${moment(date).format("YYYY-MM-DD")} ${moment().format(
        "HH:mm:ss"
      )}`,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: 0,
      pageCount: 20,
    };
    getActivityListOfProgramChallengeParticipants(requestParams).then(
      ({ data }) => {
        let list = data?.challengeParticipantResponseDTOs || [];
        let activityTotalRecords = data?.totalRecords || 0;
        setActivityList(list);
        setActivityListTotalRecord(activityTotalRecords);
      }
    );
  };

  const dataTimeBased = (event) => {
    setDateRange(event.target.value);
    setPageNumberLoad(0);
    let name;
    let bib;
    let email;
    let contact;

    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let getSupportedEventDetails = ApplicationUtil.getSupportsEventsDetails();
    let requestedParams = {
      startDate:
        dateRangeOptions === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : event.target.value,
      endDate:
        event.target.value === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
      pageNumber: 0,
      pageCount: 20,
      programChallengeId: getSupportedEventDetails?.id,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
    };

    getActivityListOfProgramChallengeParticipants(requestedParams).then(
      ({ data }) => {
        let list = data?.challengeParticipantResponseDTOs || [];
        let activityTotalRecords = data?.totalRecords || 0;
        setActivityList(list);
        setActivityListTotalRecord(activityTotalRecords);
        setResetPagination(false);
      }
    );
  };
  const getActivityList = (isFirstPage, page) => {
    let name;
    let bib;
    let email;
    let contact;
    let getSupportedEventDetails = ApplicationUtil.getSupportsEventsDetails();
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestedParams = {
      startDate:
        dateRangeOptions === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
      pageNumber: page,
      pageCount: 20,
      programChallengeId: getSupportedEventDetails?.id,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
    };
    getActivityListOfProgramChallengeParticipants(requestedParams).then(
      ({ data }) => {
        let list = data?.challengeParticipantResponseDTOs || [];
        let activityTotalRecords = data?.totalRecords || 0;
        setActivityList(activityList.concat(list));
        //   setActivityListTotalRecord(activityTotalRecords);
        setPageNumberLoad(null);
      }
    );
  };
  const getAtheleteList = (isFirstPage, page) => {
    let name;
    let bib;
    let email;
    let contact;
    let getSupportedEventDetails = ApplicationUtil.getSupportsEventsDetails();
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestedParams = {
      pageNumber: page,
      pageCount: 20,
      programChallengeId: getSupportedEventDetails?.id,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      // activityReceived: true,
    };
    if (applyFilterData?.selectedResponseRecievedFilter === "responseRecieve") {
      requestedParams.activityReceived = true;
    } else if (
      applyFilterData?.selectedResponseRecievedFilter === "responseNotRecieve"
    ) {
      requestedParams.activityReceived = false;
    }
    getProgramChallengeParticipants(requestedParams).then(({ data }) => {
      let list = data?.programChallengeParticipantDTOs || [];
      let participantTotalRecords = data?.totalChallengeParticipant || 0;
      setParticipantsList(participantList.concat(list));
      setPageNumberLoad(null);
      // setParticipantListTotalRecord(participantTotalRecords);
      // console.log(data)
    });
  };

  const handleClose = () => {
    setLayoutAnchorEl(null);
  };
  return (
    <div>
      <div className={classes.top}>
        {activityListTotalRecord !== null && (
          <div className={classes.totalRec}>
            <span>
              {selectedList === "ATHELETE" ? "Participant" : "Activities"}
              <span style={{ marginLeft: "10px" }}>
                {selectedList === "ATHELETE"
                  ? participantListTotalRecord
                  : activityListTotalRecord}
              </span>
            </span>
          </div>
        )}
        <Tooltip title="Change Layout">
          <ViewQuiltIcon
            className={classes.editIcon}
            onClick={(event) => {
              setLayoutAnchorEl(event.currentTarget);
            }}
          />
        </Tooltip>
      </div>

      <div className={classes.searchFilter__row}>
        <div className="search__select">
          <FormControl className={classes.select}>
            <NativeSelect
              className={classes.selectActivity}
              value={searchSelectValue}
              onChange={selectHandleValue}
              style={{ width: 100 }}
            >
              {["Name", "BIB", "Email", "Contact"]?.map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <form
            className="search"
            onSubmit={(e) => {
              e.preventDefault();
              searchHandleClick();
            }}
          >
            <input
              type="search"
              placeholder="Search..."
              value={searchValue}
              onChange={searchHandleChange}
            />
            <SearchIcon className="search__icon" onClick={searchHandleClick} />
          </form>
        </div>

        <div className="filters">
        {selectedList === "ACTIVITY" && (
            <>
            <FormControl className={classes.formControl}>
              <NativeSelect
                className={classes.selectActivity}
                value={dateRange}
                onChange={dataTimeBased}
              >
                <option value="">All</option>
                {dateRangeOptions?.map((value, index) => (
                  <option value={value.value} key={index}>
                    {value.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>

              {dateRange === "Custom Date" && (
                <FormControl className={classes.customDate}>
                  <Typography variant="body2" gutterBottom>
                    <span>Start Dates</span>
                  </Typography>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    // disableFuture={true}
                    value={startDate}
                    //error={dobError}
                    style={{
                      width: "150px",
                    }}
                    maxDate={endDate}
                    InputProps={{
                      classes: {
                        root: classes.datePicker,
                      },
                    }}
                    onChange={(date) => {
                      customStartDate(date);
                    }}
                    keyboardIcon={
                      <IconCalendarBlue className={classes.calendarIconCss} />
                    }
                  />
                </FormControl>
              )}
              {dateRange === "Custom Date" && (
                <FormControl className={classes.customDate}>
                  <Typography variant="body2" gutterBottom>
                    <span>End Date</span>
                  </Typography>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    // disableFuture={true}
                    value={endDate}
                    //error={dobError}
                    style={{
                      width: "150px",
                    }}
                    InputProps={{
                      classes: {
                        root: classes.datePicker,
                      },
                    }}
                    minDate={startDate}
                    onChange={(date) => {
                      customEndDate(date);
                    }}
                    keyboardIcon={
                      <IconCalendarBlue className={classes.calendarIconCss} />
                    }
                  />
                </FormControl>
              )}
            </>
          )}{" "}
          {selectedList === "ATHELETE" && (
            <>
              <FilterIcon
                pageParams={{
                  page: 0,
                  start: 0,
                  limit: 8,
                  TotalRecords: 5,
                  pageNumberLoad: pageNumberLoad,
                }}
                filter={getFilteredList}
                isFiltered={isFiltered}
                open={() => setisDrawerOpen(true)}
              />
              <DrawerFilter
                open={isDrawerOpen}
                pageParams={{
                  page: 0,
                  start: 0,
                  limit: 8,
                  TotalRecords: 8,
                  pageNumberLoad: pageNumberLoad,
                }}
                onClose={() => setisDrawerOpen(false)}
                onApplyFilter={onApplyFilter}
                filterData={
                  ApplicationConstants?.programChallengeAtheleteListFilters
                }
                // onChange={onChangeFilter}
              />
            </>
        )}
      </div>
      </div>

      {selectedList === "ATHELETE" && (
        <ListPageLoader
          pageParams={{
            page: 0,
            start: 0,
            limit: 8,
            TotalRecords: participantListTotalRecord,
            pageNumberLoad: pageNumberLoad,
          }}
          resetLoader={resetPagination}
          totalLoadedLength={participantList ? participantList.length : 20}
          loadNextData={(params) => {
            if (!participantList.includes(undefined)) {
              getAtheleteList(false, params.page);
            }
          }}
        >
          <Grid>
            {participantList && participantList.length > 0 ? (
              <OrganizerProgramChallengeParticipantList
                data={participantList}
                setIsProgramChallenge={setIsProgramChallenge}
                isPastChallenge={isPastChallenge}
              />
            ) : (
              <p className={classes.noResult}>No Participants found</p>
            )}
          </Grid>
        </ListPageLoader>
      )}
      {selectedList === "ACTIVITY" && (
        <ListPageLoader
          pageParams={{
            page: 0,
            start: 0,
            limit: 8,
            TotalRecords: activityListTotalRecord,
            pageNumberLoad: pageNumberLoad,
          }}
          resetLoader={resetPagination}
          totalLoadedLength={activityList ? activityList.length : 20}
          loadNextData={(params) => {
            if (!activityList.includes(undefined)) {
              getActivityList(false, params.page);
            }
          }}
        >
          <Grid>
            {activityList && activityList.length > 0 ? (
              <ChallengeActivityList
                programChallengeType={programChallengeType}
                data={activityList}
                setIsProgramChallenge={setIsProgramChallenge}
                isPastChallenge={isPastChallenge}
              />
            ) : (
              <p className={classes.noResult}>No activities found</p>
            )}
          </Grid>
        </ListPageLoader>
      )}
      <Menu
        anchorEl={layoutAnchorEl}
        keepMounted
        open={Boolean(layoutAnchorEl)}
        onClose={handleClose}
        style={{ marginTop: "40px" }}
      >
        <MenuItem
          onClick={() => {
            handleViewChange("ATHELETE");
            getChallengeAtheleteList();
            handleClose();
          }}
        >
          Athelete List
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleViewChange("ACTIVITY");
            getChallengeActivityList();
            handleClose();
          }}
        >
          Activity List
        </MenuItem>
      </Menu>
    </div>
  );
}
const styles = (theme) => ({
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",

    position: "relative",
    bottom: 20,
    marginRight: theme.spacing(8),
  },
  totalRec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: 600,
    width: "15%",
    marginRight: "10px",
    background: "rgba( 149, 213, 178, 0.50 )",
    boxShadow: "0 4px 3px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
    fontSize: "40px",
  },
  noResult: {
    color: "gray",
    margin: "0 auto",
    fontSize: "25px",
    textAlign: "center",
  },
  searchFilter__row: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    position: "relative",
    "& .search__select": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .search ": {
      display: "flex",
      alignItems: "center",
      border: "1px solid #bfc0c0",
      borderRadius: "5px",
      height: "35px",
      "& > input": {
        height: "30px",
        outline: "none",
        border: "none",
        fontSize: "16px",
        paddingLeft: "10px",
        borderRight: "2px solid #bfc0c0",
      },
      "& .search__icon": {
        padding: "5px",
        cursor: "pointer",
      },
    },
    "& .filters": {
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "Rubik,sans-serif",
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(OrganizerProgramChallenge);

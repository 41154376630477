import { FormControl, NativeSelect } from "@material-ui/core";
import { Pie } from "@reactchartjs/react-chart.js";
import moment from "moment";

import React, { useEffect, useState } from "react";

const PieChartGraph = ({
  graphData,
  responseBasedChallenges,
}) => {
  /**
   * storing graph data in state
   * */

  const [graphState, setGraphState] = useState({
    data: {
      labels: ["Sustain", "Not Sustain", "Challenge Not Taken"],
      datasets: [
        {
          label: "Challenge",
          data: [
            graphData?.participantsResponse?.[0]?.positiveCount || 0,
            graphData?.participantsResponse?.[0]?.negetiveCount || 0,
            graphData?.participantsResponse?.[0]?.notResponded || 0,
          ],
          backgroundColor: ["#77B64B", "#8487db", "#FE7235"],
          borderWidth: 1,
        },
      ],
    },
  });

  const options = {
    plugins: {
      datalabels: {
        display: true,
        formatter: (value, context) => {
          return value;
        },
        color: "white",
        font: {
          size: 12,
        },
      },
    },
    hover: {
      enabled: false,
    },

    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return currentValue + " (" + percentage + "%)";
        },
      },
    },

    legend: {
      enabled: true,
      position: "top",
      align: "start",
      onClick: function (event, legendItem) {},

      labels: {
        fontColor: "black",
        fontSize: 15,
        // boxWidth: 20,
        generateLabels: function (chart) {
          var data = chart.data.datasets[0].data;
          var labels = chart.data.labels;
          var total = data.reduce((a, b) => a + b, 0);
          var legendItems = [];
          for (var i = 0; i < data.length; i++) {
            legendItems.push({
              text:
                labels[i] +
                ": " +
                ` (${((data[i] / total) * 100).toFixed(
                  1
                )}%)                                      `,
              fillStyle: chart.data.datasets[0].backgroundColor[i],
            });
          }
          return legendItems;
        },
        callbacks: function (label, datasetIndex, index) {
          return (
            graphState.data.labels[index] +
            ": " +
            graphState.data.labels[index] +
            " (" +
            graphState.data.datasets[datasetIndex].data[index] +
            ")"
          );
        },
      },
      title: {
        display: true, // Display the legend title
        text: "My Custom Legend Title", // Set the legend title
        color: "#fff", // Change the color of the legend title
        font: {
          size: 16, // Change the font size of the legend title
        },
        padding: 10, // Add padding to the legend title
      },
    },
  };

  /**
   *   onChange handler dropdown item based on that set new data into graph state
   */
  const handleChangeValue = (_ind) => {
    let data = graphData?.participantsResponse?.find((item, index) => {
      return index === parseInt(_ind);
    });
    if (data) {
      let { negetiveCount, notResponded, positiveCount } = data;
      setGraphState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          datasets: [
            {
              label: "Challenge",
              data: [positiveCount || 0, negetiveCount || 0, notResponded || 0],
              backgroundColor: ["#77B64B", "#8487db", "#FE7235"],
              borderWidth: 2,
            },
          ],
        },
      }));
    }
  };

  /**
   * getDefaultSelection -if challenge is current/ongoing then get current record by default
   * @returns
   */
  const getDefaultSelection = () => {
    let isYogaChallenge = graphData?.programChallengeType === "YOGA";
    let _data = graphData?.participantsResponse || [];
    let _index;
    if (responseBasedChallenges) {
      _index = _data?.findIndex((element) =>
        isYogaChallenge
          ? moment().isSame(element?.detailsOf, "date")
          : moment().subtract(1, "day").isSame(element?.detailsOf, "date")
      );
    } else {
      _index = _data?.findIndex(
        (element) =>
          element?.detailsOf && moment().isSame(element?.detailsOf, "date")
      );
    }

    return _index === -1 ? 0 : _index;
  };


  useEffect(() => {
    const _getDefaultSelection = getDefaultSelection();
    if (_getDefaultSelection > 0) {
      handleChangeValue(_getDefaultSelection);
    }
  }, [graphData]);

  return (
    <div style={{ width: "100%" }}>
      {graphData?.participantsResponse?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            paddingRight: "10px",
          }}
        >
          <FormControl>
            <NativeSelect
              id="myDropdown"
              onChange={(e) => handleChangeValue(e.target.value)}
              // MenuProps={MenuProps}

              defaultValue={getDefaultSelection()}
            >
              {graphData?.participantsResponse?.map((value, index) => {
                return (
                  <option key={index} value={index}>
                    {value?.detailsOf}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </div>
      )}
      <div style={{ width: "80%", margin: "auto", padding: "15px" }}>
        <Pie data={graphState?.data} options={options} />
      </div>
    </div>
  );
};

export default PieChartGraph;
